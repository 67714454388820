import { Role } from "../../Domain/Interfaces";
import { INITIAL_VALUE_Role } from "../../Domain/Types";
import { Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ToastContainer } from 'react-toastify';
import { RoleCreateService } from '../../Domain/Services/RoleApplicationServices';
import { RoleFieldsForm } from "./RoleFieldsForm";
import { RoleValidationSchema } from "../Validation/RoleValidationSchema";

interface Props{
    service: any
}

export const RoleCreate = ({...row}:Props) => {
    const navigate = useNavigate();

    const onSubmitEventElement = (Role: Role) => {
        Role.id = uuidv4();

        RoleCreateService(Role).then((resp) => {
            if (toastAlert(resp)) {

                row.service()

            }
        });
    }

    return (
        <>
            <ModalExtructure className="modal" id="createRole" aria-labelledby="create-Label-Role" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="create-Label-Role">Crear Rolea</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
            
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_Role}
                    onSubmit={(values) => { onSubmitEventElement(values) }}
                    validationSchema={RoleValidationSchema}
                    >
                    {
                        (formmik) => (
                            <RoleFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
        </>
    )
}
