
    
    import { Platform } from '../../Domain/Interfaces'
    import { INITIAL_VALUE_PLATFORM } from "../../Domain/Types";


    import { Formik } from "formik";
    import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
    import { useNavigate } from 'react-router-dom';
    import { v4 as uuidv4 } from 'uuid'
    import { toastAlert } from '../../../../Shared/Toast/Alert';
    import { toast, ToastContainer } from 'react-toastify';
import { PlatformFieldsForm } from './PlatformFieldsForm';
import { PlatformCreateService } from '../../Domain/Services/PlatformApplicationServices';

interface Props{
    service: any
}
    
    export const PlatformCreate = ({...row}:Props) => {
       
        const onSubmitEventElement = (Platform:Platform) => {
            Platform.id = uuidv4();
           
            PlatformCreateService(Platform).then((resp) =>{
                if (JSON.stringify(resp.success) === 'false')  {
                    toast.error('Hay un programa que coincide con el acrónimo que se puede crear para este',
                        {
                        position: 'bottom-center',
                        closeButton: false,
                        theme: 'colored', hideProgressBar: true, autoClose: 2500
                        })
                    }
                else if (toastAlert(resp)) {
                    row.service();  
                    }
                }
            );
        }
        
      
        return (
            <>
            <ModalExtructure className="modal" id="createPlatform" aria-labelledby="create-Label-Platform" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="create-Label-Platform">Crear Platforma</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
            
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_PLATFORM}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    //validationSchema={PlatformValidationSchema}
                    >
                    {
                        (formmik) => (
                            <PlatformFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
            </>
        )
    }
    