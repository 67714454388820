import { Routes, Route } from "react-router-dom"
import {  PlatformList,  } from "./Components/PlatformList"

export const PlatformRouter = () => 
{
    return (
        <Routes>
            <Route path="/" element={<PlatformList/>}/>           
        </Routes>
    )
}