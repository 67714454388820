import { CoreCoursesReviewComments, CoreCoursesReviewCommentsProps } from '../../Domain/Interfaces'
import { INITIAL_VALUE_CORECOURSESREVIEWCOMMENTS } from "../../Domain/Types";
import { CoreCoursesReviewCommentValidationSchema, CoreCoursesReviewValidationSchema } from '../Validation/CoreCoursesReviewValidationSchema';

import { Formik } from "formik";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ToastContainer } from 'react-toastify';
import { useContext } from 'react';

import { CoreCoursesReviewCommentsCreateService } from '../../Domain/Services/CoreCoursesReviewApplicationServices';
import { CoreCoursesReviewCommentsFieldsForm } from './CoreCoursesReviewCommentsFieldsForm';
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
    
    
    export const CoreCoursesReviewCommentsCreate = () => {

            
    const { authState, isTeacher, getRoles } = useContext( AuthContext );
    
            const navigate = useNavigate();

            const {reviewId}=useParams();   

            const onSubmitEventElement = (coursereviewcomments:CoreCoursesReviewComments) => {

                coursereviewcomments.id = uuidv4();
                coursereviewcomments.review_id = String(reviewId);                        
                //@ts-ignore
                coursereviewcomments.user_id = authState.user?.id;                


                CoreCoursesReviewCommentsCreateService(coursereviewcomments).then((resp) =>{
                      if (toastAlert(resp)) {
                        setTimeout(function(){
                                navigate(-1);
                            },2500);    
                        }
                    });
                }
      
        return (
            <>
            <ToastContainer/>
            {
                  isTeacher() ? <> 
            <WrapperTitle>
                Crear Comentario
            </WrapperTitle>
            </>:''
                }
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_CORECOURSESREVIEWCOMMENTS}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={CoreCoursesReviewCommentValidationSchema}
                    >
                    {
                        (formmik) => (
                            <CoreCoursesReviewCommentsFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </>
        )
    }