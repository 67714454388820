
    
    import { Program } from '../../Domain/Interfaces'
    import { INITIAL_VALUE_PROGRAM } from "../../Domain/Types";
    import { ProgramFieldsForm } from './ProgramFieldsForm';
    import { ProgramValidationSchema } from '../Validation/ProgramValidationSchema';
    import { ProgramCreateService } from '../../Domain/Services/ProgramApplicationServices';

    import { Formik } from "formik";
    import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
    import { useNavigate, useParams } from 'react-router-dom';
    import { v4 as uuidv4 } from 'uuid'
    import { toastAlert } from '../../../../Shared/Toast/Alert';
    import { toast, ToastContainer } from 'react-toastify';
    
interface Props{
    service: any,
}

    export const ProgramCreate = ({...row}:Props) => {
    
        const navigate = useNavigate();

        const {id}=useParams();
       
        const onSubmitEventElement = (Program:Program) => {
            Program.id = uuidv4();

            Program.faculty_id = String(id); 
           
           
            ProgramCreateService(Program).then((resp) =>{
                if (JSON.stringify(resp.success) === 'false')  {
                    toast.error('Hay un programa que coincide con el acrónimo que se puede crear para este',
                        {
                        position: 'bottom-center',
                        closeButton: false,
                        theme: 'colored', hideProgressBar: true, autoClose: 2500
                        })
                    }
                else if (toastAlert(resp)) {
                    row.service()   
                    }
                }
            );
        }
        
      
        return (
            <>
            <ModalExtructure className="modal"  id="createProgram" aria-labelledby="create-Label-Program" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="create-Label-Program">Crear Programa</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_PROGRAM}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={ProgramValidationSchema}>
                    {
                        (formmik) => (
                            <ProgramFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
            </>
        )
    }
    