import { createContext } from "react";
import { AuthState } from "../../Domain/Interfaces";
import { object } from 'yup';
import { KeyValue } from '../../../CORS/CorsCourses/Domain/Interfaces';


interface initialContext {
    Login: (username:string, password:string) => void,
    Logout: () => void,
    authState: AuthState,
    isAdmin: () => boolean,
    isTeacher: () => boolean,
    isManager: () => boolean,
    isMonitor: () => boolean,
    getRoles: () => KeyValue[],
    isMenuAcepted: (roles:string[]) => boolean
}


export const AuthContext = createContext<initialContext>({
    Login: () => {},
    Logout: () => {},
    isAdmin: () => false,
    isTeacher: () => false,
    isManager: () => false,
    isMonitor: () => false,
    getRoles: () => [],
    isMenuAcepted: () => false,
    authState: { logged: false}
})