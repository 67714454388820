import { Routes, Route } from "react-router-dom"
import {  ProviderList,  } from "./Components/ProviderList"

export const ProviderRouter = () => 
{
    return (
        <Routes>
            <Route path="/" element={<ProviderList/>}/>       
        </Routes>
    )
}