import { AnnotationIcon } from "@heroicons/react/outline";
import { CoreCoursesReviewAnswerProps } from "../../Domain/Interfaces";
import { LinkPrimary } from "../../../../Shared/React/components/styles/Buttons";


export const CorsCoursesReviewAnswerItem = ({coursereviewanswer, fatherEvent} : CoreCoursesReviewAnswerProps) => {

    const {
        id,
        user_name,
        comment_id,
        created_at,
        answer,
    } = coursereviewanswer;


      return (
            <div className="w-full pb-4 pl-12">
                <div className="rounded-t-md bg-gray-300 p-2 w-fit"><b>Fecha de respuesta:</b> {created_at}</div>
                    <div className="rounded-tr-lg rounded-b-lg bg-gray-300 w-full p-2">
                        <div className="bg-white p-2 rounded-lg border-gray-300 border"> 
                            {answer}
                        </div>
                        <div className="flex w-full pt-2 pl-2 bg-gray-300 rounded-b-md">
                            <div className="text-left w-full pt-2">{user_name}</div>
                        </div>
                </div>
            </div>        
    )

}