import { Route, Routes } from "react-router-dom"
import { UserFacultyAssign } from "./Components/UserFacultyAssign"

export const UserFacultyRouter = () => 
{
    return (
        <Routes>
                  
        </Routes>
    )
}