import { ExclamationIcon } from '@heroicons/react/outline';
import { ButtonPrimary }  from '../styles/Buttons';       
import { useContext } from 'react';
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { ToastContainer } from 'react-toastify';


export const ExpiredSessionBox = () =>
{
    const { Logout, authState } = useContext( AuthContext );

    return ( 
        <>
        <ToastContainer/>
        <span className='bg-black'>
<div onClick={Logout} className="items-center my-auto p-5 flex w-full" style={{height: "90vh", width: "100%"}}>
        <div className="text-center items-center w-full">
            <div className="text-7xl font-black rounded-xl w-full pb-5">
            <span className="text-7xl font-black bg-primary-500 rounded-xl w-fit px-7 text-white">
            La sesion se a expirado
            </span>
            </div>
            <div className="text-4xl font-black content-center self-center text-center justify-center relative min-w-full flex center">
            <div className="text-4xl w-fit font-black p-5 bg-secondary-500 text-primary-500 rounded-2xl relative z-10">Haga click para volver ingresar</div>
            </div>
        </div>
    </div>
    </span>
    </>
    )
}