import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { RememberValidationSchema } from "../Validation/AuthValidationSchema";
import { FormPassRememberFields } from "./FormPassRememberFields";
import { UserRememberPasswordService } from "../../../CORS/User/Domain/Services/UserApplicationServices";
import { toastLeftAlert } from "../../../Shared/Toast/Alert";
import { ToastContainer } from "react-toastify";

export const FormPassRemember = () => {

    const navigate = useNavigate();

    const onPassRestore = async(values:any) => {
        UserRememberPasswordService(values).then((resp) =>{
            if (toastLeftAlert(resp)) {
              setTimeout(function(){
                      navigate('../');
                  },2500);    
              }
          });
      }
    

    return(
        <>
        <ToastContainer/>
            <Formik
                initialValues={{
                    email: '',
                }}
                onSubmit={(values) => { onPassRestore(values) }}
                validationSchema={RememberValidationSchema}
                >
                     {
                        (formmik) => (
                            <FormPassRememberFields />
                        )
                    }
            </Formik>
        </>
        
    );

}