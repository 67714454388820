import { Form } from "formik"
import { InputField } from "../../../../Shared/React/components/field"
import {ButtonPrimary} from "../../../../Shared/React/components/styles/Buttons";


export const ProgramFieldsForm = () => {


    return (
            
            <Form>
                
            <InputField
                label='Nombre del Programa'
                name='name'
                type='text'
            />

                <div className="pt-5">
                <ButtonPrimary data-bs-dismiss="modal" type="reset">Cerrar</ButtonPrimary>
                <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                </div>
               
            </Form>
    )
}