import { ErrorMessage, Field, Form } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams} from "react-router-dom";
import { InputField, SelectField } from "../../../../Shared/React/components/field"
import {ButtonPrimary} from "../../../../Shared/React/components/styles/Buttons";

import { DependencyListService } from "../../../Dependency/Domain/Services/DependencyApplicationServices";
import { PlatformListService } from "../../../Platform/Domain/Services/PlatformApplicationServices";
import { ProviderListService } from "../../../Provider/Domain/Services/ProviderApplicationServices";
import { StatusListService } from "../../../Status/Domain/Services/StatusApplicationServices";
import { FacultyListProgramsService } from "../../../Faculty/Domain/Services/FacultyApplicationServices";

import { Dependency } from "../../../Dependency/Domain/Interfaces";
import { Platform } from "../../../Platform/Domain/Interfaces";
import { Provider } from "../../../Provider/Domain/Interfaces";
import { Faculty } from "../../../Faculty/Domain/Interfaces";
import { Status } from "../../../Status/Domain/Interfaces";

import { WrapperAcorddion, WrapperAcorddionItem, WrapperAcorddionTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import { UserTeacherListService } from "../../../User/Domain/Services/UserApplicationServices";
import { User } from "../../../User/Domain/Interfaces";
import { HalfInputField } from "../../../../Shared/React/components/field/HalfInputField";
import { HalfSelectField } from "../../../../Shared/React/components/field/HalfSelectField";

export const CorsCoursesFieldsForm = () => {

    const navigate = useNavigate();
    const handleReturn = () => {
        navigate( -1 );
    }

    const { id } = useParams();

    const { isManager } = useContext( AuthContext );
    
    const [usersteacher, setTeachers] = useState<User[]>([]);
    const [dependencies, setDependencies] = useState<Dependency[]>([]);
    const [platforms, setPlatforms] = useState<Platform[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [multistatus, setMultipleStatus] = useState<Status[]>([]);
    const [facultyprograms, setFacultyPrograms] = useState<Faculty[]>([]);

    const isAdmin = "1";

    useEffect(() => {
        UserTeacherListService().then((resultado) => setTeachers(resultado));
        DependencyListService().then((resultado) => setDependencies(resultado));
        PlatformListService().then((resultado) => setPlatforms(resultado));
        ProviderListService().then((resultado) => setProviders(resultado));
        StatusListService(isAdmin).then((resultado) => setMultipleStatus(resultado));
        FacultyListProgramsService().then((resultado) => setFacultyPrograms(resultado));
    }, [])

    return (
        
        <Form>
            <div className="grid sm:grid-cols-2 gap-2 place-content-stretch">
            <div>
            <div className="grid grid-cols-2 gap-2 place-content-stretch">
            <HalfInputField
                label='Código Institucional'
                name='code'
                type='text'
                disabled = { isManager() ? true : false}
            />

            <HalfInputField
                label='Nombre Curso'
                name='name'
                type='text'
                disabled = { isManager() ? true : false}

            />

            <HalfSelectField
                name="dependency_id"
                label='Dependencia/Area'
                as="select"
                disabled = { isManager() ? true : false}
            >
                <option value="" >SELECCIONE UNA OPCIÓN</option>

                    {dependencies.map((dependency) => {
                        return (
                        <option key={dependency.id} value={`${dependency.id}`}>
                            {dependency.name}
                        </option>
                        )
                    })}
            </HalfSelectField>
  
            <HalfSelectField
                name="platform_id"
                label='Plataforma'
                as="select"
                disabled = { isManager() ? true : false}

            >
                    <option value="">SELECCIONE UNA OPCIÓN</option>
                    {platforms.map((platform) => {
                        return (
                        <option key={platform.id} value={`${platform.id}`}>
                            {platform.name}
                        </option>
                        )
                    })}
            </HalfSelectField>
            
            <HalfSelectField
                name="user_id"
                label='Docente'
                as="select"
            >
                    <option value="">SELECCIONE UNA OPCIÓN</option>
                    {usersteacher.map((user) => {
                        return (
                        <option key={user.uuid} value={`${user.uuid}`}>
                            {user.name}
                        </option>
                        )
                    })}       
            </HalfSelectField>

            <HalfSelectField
                name="provider_id"
                label='Proveedor'
                as="select"
                disabled = { isManager() ? true : false} 

            >
                    <option value="">SELECCIONE UNA OPCIÓN</option>
                    {providers.map((provider) => {
                        return (
                        <option key={provider.id} value={`${provider.id}`}>
                            {provider.name}
                        </option>
                        )
                    })}
            </HalfSelectField>

            
            <HalfInputField
                label='URL'
                name='url'
                type='text'
                disabled = { isManager() ? true : false}

            />
 
            <HalfInputField
            label='Fecha de creación'
            name='creation_date'
            type='date'
            min='2018-01-01'
            max={new Date().toISOString().slice(0, 10)}
            disabled = { isManager() ? true : false}
            />
            </div>
            </div>
            <div>

            <div className={`w-max mt-4 p-2 rounded-t-lg font-bold  text-white bg-gray-400`}><label>Programas</label></div>
            <WrapperAcorddion className="accordion accordion-flush bg-white" id="AccordionPrograms">
            {facultyprograms[0] === void[]
             ? 

              (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
            <>
            
                
                    {facultyprograms.map(function(faculty) {
                        return (
                            <WrapperAcorddionItem className="accordion-item">
                                <h2 className="accordion-header mb-0" id={`AccordionHead-${faculty.id}`}>
                                    <WrapperAcorddionTitle className="accordion-button" type="button" data-bs-toggle="collapse" aria-expanded="true"  data-bs-target={`#AccordionBody-${faculty.id}`} aria-controls={`AccordionBody-${faculty.id}`}>
                                        {faculty.name}
                                    </WrapperAcorddionTitle>
                                </h2>
                                <div className="accordion-collapse border-0 collapse show" aria-labelledby={`AccordionHead-${faculty.id}`}  id={`AccordionBody-${faculty.id}`} data-bs-parent="#AccordionPrograms">
                                    <div className="accordion-body py-4 px-5">
                                        {faculty.programs.map((program) => {
                                            return(
                                                <div className="form-check">
                                                    <label htmlFor={ program.id || program.name } className="form-check-label inline-block text-gray-800"> 
                                                        <Field disabled = { isManager() ? true : false} className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" name="program_id" key={program.id} value={`${program.id}`}/>
                                                        {program.name}

                                                    </label>
                                                </div>)
                                            })
                                        }
                                    </div>
                                </div>
                            </WrapperAcorddionItem>    
                        )
                    })}
                
                </>
                }
                </WrapperAcorddion>
                <ErrorMessage name='program_id' component='div' className='text-left text-red-600 mb-2'/> 
                </div></div>
       
            <div className='text-right mt-2'>
                    <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                    <ButtonPrimary type='button' onClick={handleReturn}>Atrás</ButtonPrimary>
            </div>
        </Form>
    )
}
