import { toast } from 'react-toastify';
import { HTTPErrorCode } from './HTTPErrorCode';

const authUser = JSON.parse(localStorage.getItem('user') || '{}');

const headers = {
  'Content-Type': 'application/json',
  'Authorization': authUser.jwt !== undefined ? 'Bearer ' + authUser.jwt : ""
} 

const headerFormData = {
  'Authorization': authUser.jwt !== undefined ? 'Bearer ' + authUser.jwt : ""
}

const setHeaderAuthorization = function(jwt: String){
  headers.Authorization = 'Bearer ' + jwt;
  headerFormData.Authorization = 'Bearer ' + jwt;
}

export let turnoff:any = false;


export const resetTurnoff:any = turnoff=false;


const get = async <T>(url: string) => {
  
  const response = await fetch(process.env.REACT_APP_URL_API + url, {
    method: 'GET',
    headers:headers
  });
    if (!response.ok) {
      if (response.status) {
        toast.warn('======== ERROR '+response.status+' ========\n'+HTTPErrorCode[response.status],
                  {
                  position: 'bottom-center',
                  closeButton: false, pauseOnHover: false,
                  theme: 'dark', hideProgressBar: true, autoClose: 3500,
                  }    
        );
        response.status === 401 ? turnoff = true: turnoff = false
        }
      else if (response.type)
      {
        toast.warn('======== ERROR NAN ========\n'+response.type,
                  {
                  position: 'bottom-center',
                  closeButton: false, pauseOnHover: false,
                  theme: 'dark', hideProgressBar: true, autoClose: 3500
                }    
        );
      }

      else {
        throw new Error('El error no pudo ser desplegado')
      }
    }    
  return await response.json() as T
  
}

const post = async <T>(url: string, body: any, isFormData:boolean = false) => {
    //console.log('Valor de formData: ', isFormData);
  const response = await fetch(process.env.REACT_APP_URL_API + url, {
    method: 'POST',
    headers: (isFormData) ? headerFormData: headers,
    body
  })

    if (!response.ok) {
      if (response.status) {
        toast.warn('======== ERROR '+response.status+' ========\n'+HTTPErrorCode[response.status],
                  {
                  position: 'bottom-center',
                  closeButton: false, pauseOnHover: false,
                  theme: 'dark', hideProgressBar: true, autoClose: 3500
                }    
        );
        response.status === 401 ? turnoff = true: turnoff = false
      }
      
      else if (response.type)
      {
        toast.warn('======== ERROR NAN ========\n'+response.type,
                  {
                  position: 'bottom-center',
                  closeButton: false, pauseOnHover: false,
                  theme: 'dark', hideProgressBar: true, autoClose: 3500
                }    
        );
      }

      else {
        throw new Error('El error no pudo ser desplegado')
      }
    }

  return await response.json() as T
}

const put = async <T>(url: string, body: any, isFormData:boolean = false) => {
  const response = await fetch(process.env.REACT_APP_URL_API + url, {
    method: 'PUT',
    headers: (isFormData) ? headerFormData: headers,
    body
  })

    if (!response.ok) {
      if (response.status) {
        toast.warn('======== ERROR '+response.status+' ========\n'+HTTPErrorCode[response.status],
                  {
                  position: 'bottom-center',
                  closeButton: false, pauseOnHover: false,
                  theme: 'dark', hideProgressBar: true, autoClose: 3500
                }    
        );
        response.status === 401 ? turnoff = true: turnoff = false
      }

      else if (response.type)
      {
        toast.warn('======== ERROR NAN ========\n'+response.type,
                  {
                  position: 'bottom-center',
                  closeButton: false, pauseOnHover: false,
                  theme: 'dark', hideProgressBar: true, autoClose: 3500
                }    
        );
      }

      else {
        throw new Error('El error no pudo ser desplegado')
      }
    }

  return await response.json() as T
}

const _delete = async <T>(url: string) => {
  const response = await fetch(process.env.REACT_APP_URL_API + url, {
    method: 'DELETE',
    headers:headers
  })

    if (!response.ok) {
      if (response.status) {
        toast.warn('======== ERROR '+response.status+' ========\n'+HTTPErrorCode[response.status],
                  {
                  position: 'bottom-center',
                  closeButton: false, pauseOnHover: false,
                  theme: 'dark', hideProgressBar: true, autoClose: 3500
                }    
        );
        response.status === 401 ? turnoff = true: turnoff = false
      }
      
      else if (response.type)
      {
        toast.warn('======== ERROR NAN ========\n'+response.type,
                  {
                  position: 'bottom-center',
                  closeButton: false, pauseOnHover: false,
                  theme: 'dark', hideProgressBar: true, autoClose: 3500
                }    
        );
      }

      else {
        throw new Error('El error no pudo ser desplegado')
      }
    }

  return await response.json() as T
}

export const http = {
  get,
  post,
  put,
  delete: _delete,
  setHeaderAuthorization
}
