import { Formik } from "formik";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthValidationSchema } from "../Validation/AuthValidationSchema";
import { AuthContext } from "../context/AuthContext";
import { FormLoginFields } from './FormLoginFields';
import { ToastContainer } from "react-toastify";

export const FormLogin = () => {

    const { Login } = useContext( AuthContext );

    const navigate = useNavigate();

    const onLogin = (values:any) => {
        const lastPath = localStorage.getItem('lastPath') || '/';
        Login(values.username.trim(), values.password.trim()); 
        navigate(lastPath, {
            replace: true
        });
    }

    return(
        <>
            <ToastContainer/>

            <Formik
                initialValues={{
                    username: '',
                    password: '',
                }}
                onSubmit={(values) => { onLogin(values) }}
                validationSchema={AuthValidationSchema}
                >
                     {
                        (formmik) => (
                            <FormLoginFields />
                        )
                    }
            </Formik>
        </>
        
    );

}