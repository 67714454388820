import { LinkIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";
import { Table } from "../../../../Shared/React/components/styles/Tables";
import { WrapperContent,  WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { CorsCourses} from "../../Domain/Interfaces";
import { CorsCoursesSearchByIdService} from "../../Domain/Services/CorsCoursesApplicationServices";
import { INITIAL_VALUE_CORECOURSES, VALUES_CORECOURSES } from "../../Domain/Types";


export const CoursesExtension = () => {

    const navigate = useNavigate();
    const handleReturn = () => {
        navigate( -1 );
    }

  const [coursesextension, setCoursesExtension] = useState<CorsCourses>(VALUES_CORECOURSES);
  const { courseId } = useParams();

  

    useEffect(() => {
        CorsCoursesSearchByIdService(String(courseId)).then((coursesextension) => {
      
          console.log('CoursesExtension', coursesextension);
          setCoursesExtension(coursesextension);
      }
      )
    }, [])

    return(
    <>
    <WrapperTitle>
                    <div className="flex w-full justify-between h-full">
                    <div className='align-left mb-4 mt-4'>
                    Ampliación Curso : {`${coursesextension.name}`} {`[${coursesextension.code}]`} 
                    </div>
                    <div className='align-right mb-4 mt-2'>
                    <a title='Ver url del curso' href={coursesextension.url} target="_blank" rel="noreferrer"><LinkIcon className='text-primary-900' height='32px'>Ver URL</LinkIcon>
                    </a>
                    </div>
            </div>
    </WrapperTitle> 
            <div className="bg-gray-200 rounded-md p-2 flex ">
                <div className="p-2">
                    <ul className="list-none"> 
                    <li><b>Fecha:</b></li>
                    <li><b>Dependencia:</b></li>
                    <li><b>Plataforma:</b></li>
                    </ul>
                </div>
                <div className="bg-white p-2 rounded-md w-full">
                    <ul className="list-none"> 
                    <li>{`${coursesextension.creation_date}`}</li>
                    <li>{`${coursesextension.dependency.name}`}</li>
                    <li>{`${coursesextension.platform.name}`}</li>
                    </ul>
                </div>
                <div className="p-2">
                    <ul>
                    <li><b>Estado:</b></li>
                    <li><b>Docente:</b></li>
                    <li><b>Proveedor:</b></li>
                    </ul>
                </div>
                <div className="bg-white p-2 w-full rounded-md">
                    <ul>
                    <li>{`${coursesextension.status.name}`}</li>
                    <li>{coursesextension.user_id ==='' ? 'No hay docente asignado' : `${coursesextension.user.name}`}</li>
                    <li>{`${coursesextension.provider.name}`}</li>
                    </ul>
                </div>
            </div>
            <br/>
            <br/>
        <WrapperTitle>
            Programas y Facultades
        </WrapperTitle>
        <WrapperContent>
            <br></br>
            <Table>
                <thead>
                    <tr>
                        <th className='border-slate-700'>Facultad</th>
                        <th className='border-slate-700'>Programa</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        coursesextension.programs.map(programs =>
                            <tr>
                                <td>{programs.faculty.name}</td>
                                <td>{programs.Programs.name}</td>
                            </tr>
                        )
                    }
                    
            </tbody>
            </Table>
            <div className="mt-4 text-right"><ButtonPrimary type='button' onClick={handleReturn}>Atrás</ButtonPrimary></div>
            
        </WrapperContent>
    </>
)
}


