import {v4 as uuidv4} from "uuid";
import {CorsCourses, CorsCoursesLoad,CoursesCreate,uploableFieldResponseDto} from "./Interfaces";

export const VALUES_CORECOURSES:CorsCourses = {
    id                   : uuidv4(),
    name                 : '',
    code                 : '',
    dependency_id        : '',
    platform_id          : '',
    provider_id          : '',
    status_id            : '',
    user_id              : '',
    url                  : '',
    creation_date        : '',
    faculty_id           : '',
    program_id           : '', 
    dependency           : {id: "", name: ""},
    platform             : {id: "", name: ""},
    provider             : {id: "", name: ""},
    status               : {id: "", name: ""},
    user                 : {id: "", name: ""},
    programs             : [{Programs:{id:"",name:"",faculty_id:""},faculty:{id: "", name: ""}}],
    has_pending_review   : '',
}

export const INITIAL_VALUE_CORECOURSES:CoursesCreate = {
    id                   : uuidv4(),
    name                 : '',
    code                 : '',
    dependency_id        : '',
    platform_id          : '',
    provider_id          : '',
    user_id              : '',
    url                  : '',
    creation_date        : '',
    program_id           : '', 
}


export const LOAD_VALUE_CORECOURSES:CorsCoursesLoad = {
    id                   : uuidv4(),
    name                 : '',
    code                 : '',
    dependency_id        : '',
    platform_id          : '',
    provider_id          : '',
    status_id            : '',
    user_id              : '',
    url                  : '',
    creation_date        : '',
    faculty_id           : '',
    programs             : [""],
    program_id           : [""]   
}



export const LOAD_VALUE_MASIVECHARGE:uploableFieldResponseDto = {
    document  : null
}