

import { uploableFieldResponseDto } from '../../Domain/Interfaces'
import { LOAD_VALUE_MASIVECHARGE } from "../../Domain/Types";
import { useContext, useEffect, useState } from "react";

import { BulkLoadFieldsForm } from './BulkLoadFieldsForm';
import { BulkLoadValidationSchema } from '../Validation/BulkLoadValidationSchema';
import { uploableFieldService } from '../../Domain/Services/BulkLoadListService';
import { StatusListService } from "../../../Status/Domain/Services/StatusApplicationServices";

import { Formik } from "formik";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { Link, useNavigate } from 'react-router-dom';
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ToastContainer } from 'react-toastify';
import { Status } from "../../../Status/Domain/Interfaces";
import { ButtonPrimary } from '../../../../Shared/React/components/styles/Buttons';
import { Table } from '../../../../Shared/React/components/styles/Tables';
import { DependencyModalList } from './ModalList/DependencyModalList';
import { PlatformModalList } from './ModalList/PlatformModalList';
import { ProviderModalList } from './ModalList/ProviderModalList';

export const BulkLoadChargeUploable = () => {
    let classBg = "bg-[#fff]";
    let tempFile = '';
    let rpterror;
    let rptmensaje;
    const navigate = useNavigate();

    const [errores, seterrores] = useState([]);
    const [info, setinfo] = useState([]);


    const onSubmitEventElement = (uploable: uploableFieldResponseDto) => {
        let respuesta;
        const formData = new FormData();
        formData.set('document', tempFile);
        uploableFieldService(formData).then((resp) => {
            console.log('ahh');
            respuesta = JSON.parse(JSON.stringify(resp));

            if (respuesta.success) {
                seterrores(respuesta.payload.messages);
                setinfo(respuesta.payload.info);
            }
        });
    }

    const handlerFile = (e: any) => {
        tempFile = e.target.files[0];
    }


    return (
        <>
            <ToastContainer />
            <WrapperTitle>
                Carga masiva de Cursos
            </WrapperTitle>

            <WrapperContent>
                <div className='bg-primary-500 text-2xl p-2 text-white w-full text-center items-center rounded-t-xl'>Pasos para subir el archivo </div>
                <div className='bg-white p-4 rounded-b-lg align-center text-center items-center justify-center w-full flex'>

                    <div>
                        <div>
                            Crea un archivo CSV con la siguiente estructura.
                            <br />
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>
                                            Código Institucional
                                        </th>    
                                        <th>
                                            Nombre Curso
                                        </th>    
                                        <th>
                                            Código Dependencia
                                        </th>    
                                        <th>
                                            Código Plataforma
                                        </th>    
                                        <th>
                                            URL
                                        </th>
                                        <th>
                                            Código Proveedor
                                        </th>    
                                        <th>
                                            Fecha de Creación (YYYY-MM-DD)
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div>

                            <span className='w-full flex align-center text-center items-center justify-center p-2 mt-2'>

                            </span>
                            <div className='w-full flex items-center justify-center p-2'>
                                Deja la encabezado con la estructura dada anteriormente.   
                            </div>
                            <div className='w-full flex items-center justify-center p-2'>
                                Ingresa los respectivos códigos del sistema dentro del archivo.
                            </div>
                            <div className='w-full flex items-center justify-center p-2'>
                                El formato de fecha debe ser  <strong> YYYY-MM-DD </strong>.
                            </div>
                            <div className='w-full flex items-center justify-center p-2'>
                                Solo se permite archivo con extensión CSV.
                            </div>

                            <span className='w-full flex align-center text-center items-center justify-center p-2 mt-2'>
                                Puedes buscar los código del sistema en los siguientes enlaces.
                            </span>
                            <span className='w-full flex items-center justify-center p-2'>
                            <ButtonPrimary title='Dependencias' data-bs-toggle="modal" data-bs-target="#listDependency">Dependencias</ButtonPrimary>
                            <ButtonPrimary title='Plataformas' data-bs-toggle="modal" data-bs-target="#listPlatform">Plataformas</ButtonPrimary>
                            <ButtonPrimary title='Proveedores' data-bs-toggle="modal" data-bs-target="#listProvider">Proveedores</ButtonPrimary>
                                <DependencyModalList/>
                                <PlatformModalList/>
                                <ProviderModalList/>
                            </span>
                        </div>

                    </div>
                </div>

                <Formik
                    initialValues={LOAD_VALUE_MASIVECHARGE}
                    onSubmit={(values) => { onSubmitEventElement(values) }}
                    validationSchema={BulkLoadValidationSchema}
                >
                    {
                        (formmik) => (
                            <BulkLoadFieldsForm fatherEvent={handlerFile} />
                        )
                    }
                </Formik>
                <div className="info">
                    {Object.keys(errores).length > 0 ? (
                        <div>
                            <div>
                                <div className='mt-6 bg-primary-500 text-2xl p-2 text-white w-full text-center items-center rounded-t-xl'>Información de lectura del archivo</div>
                                <div className='p-6 bg-white rounded-b-lg '>
                                    {JSON.parse(JSON.stringify(info)).success}<br />
                                    {JSON.parse(JSON.stringify(info)).duplicados}<br />
                                    {JSON.parse(JSON.stringify(info)).errors}<br />
                                    {JSON.parse(JSON.stringify(info)).leidos}<br />


                                </div>
                                <div >
                                    <Table className='mt-6'>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Línea
                                                </th>
                                                <th>
                                                    Mensajes
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(errores).map((error, index) => {
                                                rpterror = JSON.parse(JSON.stringify(error));
                                                if (index % 2 === 0) {
                                                    classBg = "bg-[#ececec]";
                                                } else {
                                                    classBg = "bg-[#fff]";
                                                }
                                                if (rpterror.errors) {
                                                    return (
                                                        <tr className={`border-b ${classBg}`}>
                                                            <td className='text-center'>
                                                                {rpterror.linea}
                                                            </td>
                                                            <td >
                                                                <ul>
                                                                    {Object.values(rpterror.errors).map((mensaje) => {
                                                                        rptmensaje = JSON.parse(JSON.stringify(mensaje));
                                                                        return <li>{rptmensaje}</li>;
                                                                    })}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </WrapperContent>
        </>
    )
}



