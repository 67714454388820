export const SyncData = [
    {
        "header":
        {
            "tittle_file": "Piloto alistamiento autom\u00e1tico.xls",
            "total_registros_leer": 50,
            "total_grupos_leer": 50,
            "total_cursos_leer": 16
        },
        "crear":
        {
            "2":
            {
                "fila": 2,
                "codigo_asignatura": "PRECBSBASI18001",
                "mssg": "El dato est\u00e1 sincronizado existosamente",
                "status": "ok"
            },
            "3":
            {
                "fila": 3,
                "codigo_asignatura": "PRECBSBASI18001",
                "mssg": "La semilla SEM-PRECBSBASI18001-2023-2 a crear  ya existe",
                "status": "error"
            },
            "4":
            {
                "fila": 4,
                "codigo_asignatura": "PRECBSBASI18001",
                "mssg": "La semilla SEM-PRECBSBASI18001-2023-2 a crear  ya existe",
                "status": "error"
            },
            "5":
            {
                "fila": 5,
                "codigo_asignatura": "PRECBSBASI18001",
                "mssg": "La semilla SEM-PRECBSBASI18001-2023-2 a crear  ya existe",
                "status": "error"
            },
            "6":
            {
                "fila": 6,
                "codigo_asignatura": "PRECBSBASI18001",
                "mssg": "La semilla SEM-PRECBSBASI18001-2023-2 a crear  ya existe",
                "status": "error"
            },
            "7":
            {
                "fila": 7,
                "codigo_asignatura": "PRECBSBASI18001",
                "mssg": "La semilla SEM-PRECBSBASI18001-2023-2 a crear  ya existe",
                "status": "error"
            },
            "8":
            {
                "fila": 8,
                "codigo_asignatura": "PRECBSBASI18001",
                "mssg": "La semilla SEM-PRECBSBASI18001-2023-2 a crear  ya existe",
                "status": "error"
            },
            "9":
            {
                "fila": 9,
                "codigo_asignatura": "PRETSODISC21013",
                "mssg": "El dato est\u00e1 sincronizado existosamente",
                "status": "ok"
            },
            "10":
            {
                "fila": 10,
                "codigo_asignatura": "PREIDIBASI18006",
                "mssg": "La semilla SEM-PREIDIBASI18006-2023-2 a crear  ya existe",
                "status": "error"
            },
            "11":
            {
                "fila": 11,
                "codigo_asignatura": "PREIDIBASI18006",
                "mssg": "La semilla SEM-PREIDIBASI18006-2023-2 a crear  ya existe",
                "status": "error"
            },
            "12":
            {
                "fila": 12,
                "codigo_asignatura": "PREIDIBASI18006",
                "mssg": "La semilla SEM-PREIDIBASI18006-2023-2 a crear  ya existe",
                "status": "error"
            },
            "13":
            {
                "fila": 13,
                "codigo_asignatura": "PREIDIBASI18006",
                "mssg": "La semilla SEM-PREIDIBASI18006-2023-2 a crear  ya existe",
                "status": "error"
            },
            "14":
            {
                "fila": 14,
                "codigo_asignatura": "PREIDIBASI18006",
                "mssg": "La semilla SEM-PREIDIBASI18006-2023-2 a crear  ya existe",
                "status": "error"
            },
            "15":
            {
                "fila": 15,
                "codigo_asignatura": "PREIDIBASI18007",
                "mssg": "La semilla SEM-PREIDIBASI18007-2023-2 a crear  ya existe",
                "status": "error"
            },
            "16":
            {
                "fila": 16,
                "codigo_asignatura": "PREIDIBASI18007",
                "mssg": "La semilla SEM-PREIDIBASI18007-2023-2 a crear  ya existe",
                "status": "error"
            },
            "17":
            {
                "fila": 17,
                "codigo_asignatura": "PREINVBASI18003",
                "mssg": "La semilla SEM-PREINVBASI18003-2023-2 a crear  ya existe",
                "status": "error"
            },
            "18":
            {
                "fila": 18,
                "codigo_asignatura": "PRETSODISC21015",
                "mssg": "El curso core con c\u00f3digo PRETSODISC21015 no existe",
                "status": "error"
            },
            "19":
            {
                "fila": 19,
                "codigo_asignatura": "PRETSODISC21016",
                "mssg": "El curso core con c\u00f3digo PRETSODISC21016 no existe",
                "status": "error"
            },
            "20":
            {
                "fila": 20,
                "codigo_asignatura": "PRETSOPROF21030",
                "mssg": "El curso core con c\u00f3digo PRETSOPROF21030 no existe",
                "status": "error"
            },
            "21":
            {
                "fila": 21,
                "codigo_asignatura": "PREIDIBASI18004",
                "mssg": "La semilla SEM-PREIDIBASI18004-2023-2 a crear  ya existe",
                "status": "error"
            },
            "22":
            {
                "fila": 22,
                "codigo_asignatura": "PREIDIBASI18004",
                "mssg": "La semilla SEM-PREIDIBASI18004-2023-2 a crear  ya existe",
                "status": "error"
            },
            "23":
            {
                "fila": 23,
                "codigo_asignatura": "PREIDIBASI18004",
                "mssg": "La semilla SEM-PREIDIBASI18004-2023-2 a crear  ya existe",
                "status": "error"
            },
            "24":
            {
                "fila": 24,
                "codigo_asignatura": "PREIDIBASI18004",
                "mssg": "La semilla SEM-PREIDIBASI18004-2023-2 a crear  ya existe",
                "status": "error"
            },
            "25":
            {
                "fila": 25,
                "codigo_asignatura": "PREIDIBASI18004",
                "mssg": "La semilla SEM-PREIDIBASI18004-2023-2 a crear  ya existe",
                "status": "error"
            },
            "26":
            {
                "fila": 26,
                "codigo_asignatura": "PREIDIBASI18007",
                "mssg": "La semilla SEM-PREIDIBASI18007-2023-2 a crear  ya existe",
                "status": "error"
            },
            "27":
            {
                "fila": 27,
                "codigo_asignatura": "PREIDIBASI18007",
                "mssg": "La semilla SEM-PREIDIBASI18007-2023-2 a crear  ya existe",
                "status": "error"
            },
            "28":
            {
                "fila": 28,
                "codigo_asignatura": "PREIDIBASI18003",
                "mssg": "La semilla SEM-PREIDIBASI18003-2023-2 a crear  ya existe",
                "status": "error"
            },
            "29":
            {
                "fila": 29,
                "codigo_asignatura": "PREHUMBASI18001",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "30":
            {
                "fila": 30,
                "codigo_asignatura": "PREHUMBASI18001",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "31":
            {
                "fila": 31,
                "codigo_asignatura": "PREHUMBASI18001",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "32":
            {
                "fila": 32,
                "codigo_asignatura": "PREHUMBASI18001",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "33":
            {
                "fila": 33,
                "codigo_asignatura": "PRETSOPROF21028",
                "mssg": "La semilla SEM-PRETSOPROF21028-2023-2 a crear  ya existe",
                "status": "error"
            },
            "34":
            {
                "fila": 34,
                "codigo_asignatura": "PRETSOPROF21027",
                "mssg": "El curso core con c\u00f3digo PRETSOPROF21027 no existe",
                "status": "error"
            },
            "35":
            {
                "fila": 35,
                "codigo_asignatura": "PREIDIBASI18003",
                "mssg": "La semilla SEM-PREIDIBASI18003-2023-2 a crear  ya existe",
                "status": "error"
            },
            "36":
            {
                "fila": 36,
                "codigo_asignatura": "PREIDIBASI18003",
                "mssg": "La semilla SEM-PREIDIBASI18003-2023-2 a crear  ya existe",
                "status": "error"
            },
            "37":
            {
                "fila": 37,
                "codigo_asignatura": "PREIDIBASI18003",
                "mssg": "La semilla SEM-PREIDIBASI18003-2023-2 a crear  ya existe",
                "status": "error"
            },
            "38":
            {
                "fila": 38,
                "codigo_asignatura": "PREIDIBASI18003",
                "mssg": "La semilla SEM-PREIDIBASI18003-2023-2 a crear  ya existe",
                "status": "error"
            },
            "39":
            {
                "fila": 39,
                "codigo_asignatura": "PREINVBASI18003",
                "mssg": "La semilla SEM-PREINVBASI18003-2023-2 a crear  ya existe",
                "status": "error"
            },
            "40":
            {
                "fila": 40,
                "codigo_asignatura": "PRETSOPROF21031",
                "mssg": "El curso core con c\u00f3digo PRETSOPROF21031 no existe",
                "status": "error"
            },
            "41":
            {
                "fila": 41,
                "codigo_asignatura": "PRETSOPROF21027",
                "mssg": "El curso core con c\u00f3digo PRETSOPROF21027 no existe",
                "status": "error"
            },
            "42":
            {
                "fila": 42,
                "codigo_asignatura": "PREHUMBASI18001",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "43":
            {
                "fila": 43,
                "codigo_asignatura": "PREHUMBASI18001",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "44":
            {
                "fila": 44,
                "codigo_asignatura": "PREIDIBASI18005",
                "mssg": "La semilla SEM-PREIDIBASI18005-2023-2 a crear  ya existe",
                "status": "error"
            },
            "45":
            {
                "fila": 45,
                "codigo_asignatura": "PREIDIBASI18005",
                "mssg": "La semilla SEM-PREIDIBASI18005-2023-2 a crear  ya existe",
                "status": "error"
            },
            "46":
            {
                "fila": 46,
                "codigo_asignatura": "PREIDIBASI18005",
                "mssg": "La semilla SEM-PREIDIBASI18005-2023-2 a crear  ya existe",
                "status": "error"
            },
            "47":
            {
                "fila": 47,
                "codigo_asignatura": "PREIDIBASI18005",
                "mssg": "La semilla SEM-PREIDIBASI18005-2023-2 a crear  ya existe",
                "status": "error"
            },
            "48":
            {
                "fila": 48,
                "codigo_asignatura": "PREIDIBASI18005",
                "mssg": "La semilla SEM-PREIDIBASI18005-2023-2 a crear  ya existe",
                "status": "error"
            },
            "49":
            {
                "fila": 49,
                "codigo_asignatura": "PREIDIBASI18005",
                "mssg": "La semilla SEM-PREIDIBASI18005-2023-2 a crear  ya existe",
                "status": "error"
            },
            "50":
            {
                "fila": 50,
                "codigo_asignatura": "PREIDIBASI18005",
                "mssg": "La semilla SEM-PREIDIBASI18005-2023-2 a crear  ya existe",
                "status": "error"
            },
            "51":
            {
                "fila": 51,
                "codigo_asignatura": "PREIDIBASI18002",
                "mssg": "El curso core con c\u00f3digo PREIDIBASI18002 no existe",
                "status": "error"
            }
        },
        "reponse_crear":
        {
            "success": true,
            "payload": [],
            "message": "Proceso completado con exito"
        },
        "asociar":
        {
            "2":
            {
                "fila": 2,
                "codigo_grupo": "PRECHU2302B010037"
            },
            "3":
            {
                "fila": 3,
                "codigo_grupo": "PRECHU2302B010038",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010038 no existe",
                "status": "error"
            },
            "4":
            {
                "fila": 4,
                "codigo_grupo": "PRECHU2302B010041",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010041 no existe",
                "status": "error"
            },
            "5":
            {
                "fila": 5,
                "codigo_grupo": "PRECHU2302B010042",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010042 no existe",
                "status": "error"
            },
            "6":
            {
                "fila": 6,
                "codigo_grupo": "PRECHU2302B010043",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010043 no existe",
                "status": "error"
            },
            "7":
            {
                "fila": 7,
                "codigo_grupo": "PRECHU2302B010045",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010045 no existe",
                "status": "error"
            },
            "8":
            {
                "fila": 8,
                "codigo_grupo": "PRECHU2302B010047",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010047 no existe",
                "status": "error"
            },
            "9":
            {
                "fila": 9,
                "codigo_grupo": "PRECHU2302B010086"
            },
            "10":
            {
                "fila": 10,
                "codigo_grupo": "PRECHU2302B010107",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010107 no existe",
                "status": "error"
            },
            "11":
            {
                "fila": 11,
                "codigo_grupo": "PRECHU2302B010108",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010108 no existe",
                "status": "error"
            },
            "12":
            {
                "fila": 12,
                "codigo_grupo": "PRECHU2302B010109",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010109 no existe",
                "status": "error"
            },
            "13":
            {
                "fila": 13,
                "codigo_grupo": "PRECHU2302B010110",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010110 no existe",
                "status": "error"
            },
            "14":
            {
                "fila": 14,
                "codigo_grupo": "PRECHU2302B010113",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010113 no existe",
                "status": "error"
            },
            "15":
            {
                "fila": 15,
                "codigo_grupo": "PRECHU2302B010114",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010114 no existe",
                "status": "error"
            },
            "16":
            {
                "fila": 16,
                "codigo_grupo": "PRECHU2302B010115"
            },
            "17":
            {
                "fila": 17,
                "codigo_grupo": "PRECHU2302B010240",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010240 no existe",
                "status": "error"
            },
            "18":
            {
                "fila": 18,
                "codigo_grupo": "PRECHU2302B010255",
                "mssg": "El curso core con c\u00f3digo PRETSODISC21015 no existe",
                "status": "error"
            },
            "19":
            {
                "fila": 19,
                "codigo_grupo": "PRECHU2302B010256",
                "mssg": "El curso core con c\u00f3digo PRETSODISC21016 no existe",
                "status": "error"
            },
            "20":
            {
                "fila": 20,
                "codigo_grupo": "PRECHU2302B010257",
                "mssg": "El curso core con c\u00f3digo PRETSOPROF21030 no existe",
                "status": "error"
            },
            "21":
            {
                "fila": 21,
                "codigo_grupo": "PRECHU2302B010268",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010268 no existe",
                "status": "error"
            },
            "22":
            {
                "fila": 22,
                "codigo_grupo": "PRECHU2302B010269",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010269 no existe",
                "status": "error"
            },
            "23":
            {
                "fila": 23,
                "codigo_grupo": "PRECHU2302B010276"
            },
            "24":
            {
                "fila": 24,
                "codigo_grupo": "PRECHU2302B010277",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010277 no existe",
                "status": "error"
            },
            "25":
            {
                "fila": 25,
                "codigo_grupo": "PRECHU2302B010279",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010279 no existe",
                "status": "error"
            },
            "26":
            {
                "fila": 26,
                "codigo_grupo": "PRECHU2302B010284",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010284 no existe",
                "status": "error"
            },
            "27":
            {
                "fila": 27,
                "codigo_grupo": "PRECHU2302B010291",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010291 no existe",
                "status": "error"
            },
            "28":
            {
                "fila": 28,
                "codigo_grupo": "PRECHU2302B010297",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B010297 no existe",
                "status": "error"
            },
            "29":
            {
                "fila": 29,
                "codigo_grupo": "PRECHU2302B020033",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "30":
            {
                "fila": 30,
                "codigo_grupo": "PRECHU2302B020036",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "31":
            {
                "fila": 31,
                "codigo_grupo": "PRECHU2302B020038",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "32":
            {
                "fila": 32,
                "codigo_grupo": "PRECHU2302B020042",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "33":
            {
                "fila": 33,
                "codigo_grupo": "PRECHU2302B020069"
            },
            "34":
            {
                "fila": 34,
                "codigo_grupo": "PRECHU2302B020075",
                "mssg": "El curso core con c\u00f3digo PRETSOPROF21027 no existe",
                "status": "error"
            },
            "35":
            {
                "fila": 35,
                "codigo_grupo": "PRECHU2302B020087",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B020087 no existe",
                "status": "error"
            },
            "36":
            {
                "fila": 36,
                "codigo_grupo": "PRECHU2302B020088",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B020088 no existe",
                "status": "error"
            },
            "37":
            {
                "fila": 37,
                "codigo_grupo": "PRECHU2302B020089",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B020089 no existe",
                "status": "error"
            },
            "38":
            {
                "fila": 38,
                "codigo_grupo": "PRECHU2302B020090",
                "mssg": "El grupo con c\u00f3digo PRECHU2302B020090 no existe",
                "status": "error"
            },
            "39":
            {
                "fila": 39,
                "codigo_grupo": "PRECHU2302B020140"
            },
            "40":
            {
                "fila": 40,
                "codigo_grupo": "PRECHU2302B020237",
                "mssg": "El curso core con c\u00f3digo PRETSOPROF21031 no existe",
                "status": "error"
            },
            "41":
            {
                "fila": 41,
                "codigo_grupo": "PRECHU2302B020238",
                "mssg": "El curso core con c\u00f3digo PRETSOPROF21027 no existe",
                "status": "error"
            },
            "42":
            {
                "fila": 42,
                "codigo_grupo": "PRECHU2302B020244",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "43":
            {
                "fila": 43,
                "codigo_grupo": "PRECHU2302B020245",
                "mssg": "El curso core con c\u00f3digo PREHUMBASI18001 no existe",
                "status": "error"
            },
            "44":
            {
                "fila": 44,
                "codigo_grupo": "PREICA2302B010004",
                "mssg": "El grupo con c\u00f3digo PREICA2302B010004 no existe",
                "status": "error"
            },
            "45":
            {
                "fila": 45,
                "codigo_grupo": "PREICA2302B010005",
                "mssg": "El grupo con c\u00f3digo PREICA2302B010005 no existe",
                "status": "error"
            },
            "46":
            {
                "fila": 46,
                "codigo_grupo": "PREICA2302B010006",
                "mssg": "El grupo con c\u00f3digo PREICA2302B010006 no existe",
                "status": "error"
            },
            "47":
            {
                "fila": 47,
                "codigo_grupo": "PREICA2302B010007",
                "mssg": "El grupo con c\u00f3digo PREICA2302B010007 no existe",
                "status": "error"
            },
            "48":
            {
                "fila": 48,
                "codigo_grupo": "PREICA2302B010009",
                "mssg": "El grupo con c\u00f3digo PREICA2302B010009 no existe",
                "status": "error"
            },
            "49":
            {
                "fila": 49,
                "codigo_grupo": "PREICA2302B010063",
                "mssg": "El grupo con c\u00f3digo PREICA2302B010063 no existe",
                "status": "error"
            },
            "50":
            {
                "fila": 50,
                "codigo_grupo": "PREICA2302B010066",
                "mssg": "El grupo con c\u00f3digo PREICA2302B010066 no existe",
                "status": "error"
            },
            "51":
            {
                "fila": 51,
                "codigo_grupo": "PREPROF2302B020005",
                "mssg": "El curso core con c\u00f3digo PREIDIBASI18002 no existe",
                "status": "error"
            },
            "canvas":
            {
                "11350":
                {
                    "mssg": "Asociando a la plantilla con id11350 los siguientes ids de grupos: , 11348",
                    "status": "ok"
                }
            }
        }
    }
]