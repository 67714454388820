import {object, string, ref} from 'yup'

export const UserValidationSchema = object().shape({
    name                : string ().required('Requerido'),
    last_name                : string ().required('Requerido'),
    email                : string ().required('Requerido').matches(/([\w\-\.]+@[\w\-]+[\.{1}]\w{2,})$|([\w\-\.]+@[\w\-]+[\.{1}]\w{2,3}[\.{1}]\w{2})$/, 'el correo debe tener el formato correcto'),
    document                : string ().required('Requerido'),
    role_id                : string ().required('Requerido'),
})

export const UserEditValidationSchema = object().shape({
    name                : string ().required('Requerido'),
    last_name                : string ().required('Requerido'),
    email                : string ().required('Requerido').matches(/([\w\-\.]+@[\w\-]+[\.{1}]\w{2,})$|([\w\-\.]+@[\w\-]+[\.{1}]\w{2,3}[\.{1}]\w{2})$/, 'el correo debe tener el formato correcto'),
    document                : string ().required('Requerido'),
    role_id                : string ().required('Requerido'),
})

export const UserPassValidationSchema = object().shape({
    new_password                : string ().required('Requerido').matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,'Minimo 8 caracteres, al menos debe tener una mayuscula, una minuscula, y un numero'),
    confirm_new_password:  string().when('new_password', (new_password, field) =>  new_password ? field.required('Requerido').oneOf([ref('new_password')], 'Ambos campos no son similares') : field),

})