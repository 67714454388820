import { Routes, Route } from "react-router-dom"
import { UserCreate } from "./Components/UserCreate"
import { UserEdit } from "./Components/UserEdit"

import {  UserList  } from "./Components/UserList"
import { UserPassword } from "./Components/UserPassword"

export const UserRouter = () => 
{
    return (
        <Routes>
            <Route path="/" element={<UserList/>}/>     
            <Route path="/first-login" element={<UserPassword/>} />      
        </Routes>
    )
}