import { Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useEffect, useState } from 'react';
import { Program } from '../../Domain/Interfaces';

import { useNavigate, useParams } from "react-router-dom"
import { INITIAL_VALUE_PROGRAM } from "../../Domain/Types";

import { ToastContainer } from 'react-toastify';
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ProgramSearchByIdService, ProgramUpdateService } from "../../Domain/Services/ProgramApplicationServices";
import { ProgramValidationSchema } from "../Validation/ProgramValidationSchema";
import { ProgramFieldsForm } from "./ProgramFieldsForm";
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

interface Props {
  id: string,
  service: any
}


export const ProgramEdit = ({ ...row }:Props) => {

  const ProgramId = row.id;

  const [program, setProgram] = useState<Program>(INITIAL_VALUE_PROGRAM);

 
  const onSubmitEventElement = (program:any) => {

    program.id = String(ProgramId);
    
    ProgramUpdateService (program).then((resp) =>{
      if (toastAlert(resp)) {
          row.service()   
      }
  });
  }

  const loadProgram = () => {ProgramSearchByIdService(String(ProgramId)).then((program) => setProgram(program))};
  

  return (
    <>
    <ModalExtructure className="modal" id={"editProgram-"+row.id} onMouseEnter={loadProgram} aria-labelledby={"edit-Label-Program-"+row.id} aria-hidden="true">
    <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id={"edit-Label-Program"+row.id}>Editar Programa</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
    <WrapperContent>
    {program.name !== '' ?
      <Formik
        enableReinitialize={true}
        initialValues={program}
        onSubmit={ values => { onSubmitEventElement(values) }}
        validationSchema={ProgramValidationSchema}>
          {
            (formmik) => (
              <ProgramFieldsForm/>
            )
          }
      </Formik>
:

<div>
  <img className="self-center w-full" src="/images/loading.svg" alt="" />
  <ButtonPrimary className="pt-5" data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
</div>
}
    </WrapperContent>
    </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>

    </>
  )
}