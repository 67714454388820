export let HTTPErrorCode:any = {
    /* 1XX Codes */
    100: 'Continue',
    101: 'Cambiando Protocolos',
    102: 'Processing',
    103: 'Early Hints',
    110: 'Response Is Stale',
    111: 'Revalidation Failed',
    112: 'Operacion desconectada',
    113: 'Heuristic Expiration',
    199: 'Miscellaneous Warning',
    /* 2XX Codes */
    200: 'OK',
    201: 'Creado',
    202: 'Aceptada',
    203: 'Non-Authoritative Information',
    204: 'Sin Contenido',
    205: 'Reset Content',
    206: 'Partial Content',
    207: 'Multi-Status',
    208: 'Already Reported',
    214: 'Transformation Applied',
    218: 'This Is Fine',
    226: 'IM Used',
    299: 'Miscellaneous Persistent Warning',
    /* 3XX Codes */
    300: 'Multiple Choices',
    301: 'Movido Permanentemente',
    302: 'Encontrado',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    306: 'Switch Proxy',
    307: 'Temporary Redirect',
    308: 'Permanent Redirect',
    /* 4XX Codes */
    400: 'Solicitud Errada',
    401: 'La sesion a expirado',
    403: 'Acceso prohibido',
    404: 'Archivo no encontrado',
    405: 'Metodo de comunicacion no permitido',
    406: 'Los valores no fueron aceptados',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflicto',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Payload Too Large',
    414: 'URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Range Not Satisfiable',
    417: 'Expectation Failed',
    418: "I'm a Teapot",
    419: 'Page Expired',
    420: 'Method Failure or Enhance Your Calm',
    421: 'Misdirected Request',
    422: 'Unprocessable Entity',
    423: 'Locked',
    424: 'Failed Dependency',
    425: 'Too Early',
    426: 'Upgrade Required',
    428: 'Precondition Required',
    429: 'Too Many Requests',
    430: 'HTTP Status Code',
    431: 'Request Header Fields Too Large',
    440: 'Login Time-Out',
    444: 'No Response',
    449: 'Retry With',
    450: 'Blocked by Windows Parental Controls',
    451: 'Unavailable For Legal Reasons',
    460: 'Client Closed Connection Prematurely',
    463: 'Too Many Forwarded IP Addresses',
    464: 'Incompatible Protocol',
    494: 'Request Header Too Large',
    495: 'SSL Certificate Error',
    496: 'SSL Certificate Required',
    497: 'HTTP Request Sent to HTTPS Port',
    498: 'Invalid Token',
    499: 'Token Required or Client Closed Request',
    /* 5XX Codes */
    500: 'A ocurrido un error en el servidor',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    506: 'Variant Also Negotiates',
    507: 'Almacenamiento Insuficiente',
    508: 'Bucle de Informacion detectado',
    509: 'Se a excedido el ancho de banda',
    510: 'Not Extended',
    511: 'Network Authentication Required',
    520: 'El servidor web esta retornando un error desconocido',
    521: 'El servidor web esta fuera de linea',
    522: 'Connection Timed Out',
    523: 'Origin Is Unreachable',
    524: 'A Timeout Occurred',
    525: 'SSL Handshake Failed',
    526: 'Invalid SSL Certificate',
    527: 'Railgun Listener to Origin',
    529: 'El servicio esta sobrecargado',
    530: 'El sitio se ha quedado congelado',
    561: 'Unauthorized',
    598: 'Network Read Timeout Error',
    599: 'Network Connect Timeout Error',
    /* 9XX Codes */
    999: 'Unauthorized',
  };