export interface ItemMenu {
    name: string,
    icon: string
    path: string | null,
    menuActive: string[],
    roles: string[],
    children: SecondLeveltemMenu[] | null
}

export interface SecondLeveltemMenu {
    name: string,
    path: string | null,
    roles: string[],
    children: ThirdLevelItemMenu[] | null,

}

export interface ThirdLevelItemMenu {
    name: string,
    path: string,
}

interface Menu {
    items: ItemMenu[]
}

export const MenuPlatform: Menu = {
    items: [
        {
            name: 'Cursos',
            icon: 'ArchiveIcon',
            menuActive: ['/dashboard/course'],
            roles: ['administrator', 'manager', 'teacher', 'monitor'],
            path: null,
            children: [
                {
                    name: 'Cursos CORE',
                    path: '/dashboard/course',
                    children: null,
                    roles: ['administrator', 'manager', 'teacher', 'monitor'],
                },
                {
                    name: 'Crear Curso',
                    path: '/dashboard/course/create',
                    children: null,
                    roles: ['administrator', 'monitor'],

                },
                {
                    name: 'Cursos Deshabilitados',
                    path: '/dashboard/course/disabled',
                    children: null,
                    roles: ['administrator'],
                }
            ]
        },
        {
            name: 'Administración',
            icon: 'ArchiveIcon',
            menuActive: ['/dashboard/dependency', '/dashboard/faculty', '/dashboard/platform', '/dashboard/provider', '/dashboard/user'],
            path: null,
            roles: ['administrator', 'monitor'],
            children: [
                {
                    name: 'Dependencias',
                    path: '/dashboard/dependency',
                    children: null,
                    roles: ['administrator', 'monitor'],
                },
                {
                    name: 'Facultades',
                    path: '/dashboard/faculty',
                    children: null,
                    roles: ['administrator', 'monitor'],
                },
                {
                    name: 'Plataformas',
                    path: '/dashboard/platform',
                    children: null,
                    roles: ['administrator', 'monitor'],

                },
                {
                    name: 'Proveedores',
                    path: '/dashboard/provider',
                    children: null,
                    roles: ['administrator', 'monitor'],

                },
                {
                    name: 'Usuarios',
                    path: '/dashboard/user',
                    children: null,
                    roles: ['administrator', 'monitor'],
                },

                {
                    name: 'Roles',
                    path: '/dashboard/role',
                    children: null,
                    roles: ['administrator',  'monitor']

                },
            ]

        },
        {
            name: 'Cargas masivas',
            icon: 'ArchiveIcon',
            menuActive: ['/dashboard/course/cargar'],
            path: null,
            roles: ['administrator'],
            children: [{
                name: 'Cargar Cursos',
                path: '/dashboard/bulkload/courses',
                children: null,
                roles: ['administrator'],
            }, {
                name: 'Carga Programas de los Cursos',
                path: '/dashboard/bulkload/programs',
                children: null,
                roles: ['administrator'],
            },]
        },
        {
            name:'Sincronización',
            icon: 'ArchiveIcon',
            menuActive: ['/dashboard/sync/'],
            path: null,
            roles:['administrator', 'monitor'],
            children: [{
                name: 'Listado de Alistamientos',
                path: '/dashboard/sync/',
                children: null,
                roles: ['administrator'],
            }, {
                name: 'Cargar Alistamiento',
                path: '/dashboard/sync/upload',
                children: null,
                roles: ['administrator'],
            },]
        }
    ]
}