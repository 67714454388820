import { Routes, Route } from "react-router-dom"
import {  ProgramList,  } from "./Components/ProgramList"

export const ProgramRouter = () => 
{
    return (
        <Routes>
            <Route path="/" element={<ProgramList/>}/>      
        </Routes>
    )
}