import { Routes, Route } from "react-router-dom"
import { BulkLoadChargeUploable } from "./Components/BulkLoadChargeUploable"
import { BulkLoadProgramsUploable } from "./Components/BulkLoadProgramsUploable"

export const BulkLoadRouter = () => 
{
    return (
        <Routes>
            <Route path="/courses" element={<BulkLoadChargeUploable/>} />
            <Route path="/programs" element={<BulkLoadProgramsUploable/>} />
        </Routes>
    )
}