import { http } from "../../../Shared/Http/http";
import { AuthResponseDTO } from "../Interfaces";

export const authService = async(username: string, password:string) => {

    const AuthData = await http.post<AuthResponseDTO>('auth/authentication', JSON.stringify({username, password}));
    console.log('AuthData', AuthData);
    return AuthData.payload.data;

}

export const logoutService = async() => {

    const response = await http.get<AuthResponseDTO>('auth/logout');

    return response;

}
