import { Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useEffect, useState } from 'react';
import { Provider } from '../../Domain/Interfaces';

import { useNavigate, useParams } from "react-router-dom"
import { INITIAL_VALUE_PROVIDER } from "../../Domain/Types";

import { ToastContainer } from 'react-toastify';
import { toastAlert } from '../../../../Shared/Toast/Alert';

import { ProviderValidationSchema } from "../Validation/ProviderValidationSchema";
import { ProviderFieldsForm } from "./ProviderFieldsForm";
import { ProviderSearchByIdService, ProviderUpdateService } from "../../Domain/Services/ProviderApplicationServices";
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";
interface Props {
  id: string,
  service: any,
}


export const ProviderEdit = ({ ...row }:Props) => {

  const ProviderId = row.id;

  const [provider, setProvider] = useState<Provider>(INITIAL_VALUE_PROVIDER);

  const loadProvider = () => {
    ProviderSearchByIdService(String(ProviderId)).then((provider) => setProvider(provider))
  }

  const onSubmitEventElement = (program:any) => {

    provider.id = String(ProviderId);
    
    ProviderUpdateService (program).then((resp) =>{
      if (toastAlert(resp)) {
          row.service() 
      }
  });
  }

  return (
    <>
    <ModalExtructure className="modal" onMouseEnter={loadProvider} id={"editProvider-"+row.id} aria-labelledby={"edit-Label-Provider-"+row.id} aria-hidden="true">
      <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id={"edit-Label-Provider"+row.id}>Editar Proveedor</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
          <WrapperContent>
    {provider.name !== '' ?
      <Formik
        enableReinitialize={true}
        initialValues={provider}
        onSubmit={ values => { onSubmitEventElement(values) }}
        validationSchema={ProviderValidationSchema}>
          {
            (formmik) => (
              <ProviderFieldsForm/>
            )
          }
      </Formik>
      :

        <div>
          <img className="self-center w-full" src="/images/loading.svg" alt="" />
          <ButtonPrimary className="pt-5" data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
        </div>
        }
    </WrapperContent>
    </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
    </>
  )
}