import { AnnotationIcon} from "@heroicons/react/outline";

import { CoreCoursesReviewAnswer, CoreCoursesReviewCommentsProps } from "../../Domain/Interfaces";

import { LinkPrimary } from "../../../../Shared/React/components/styles/Buttons";
import { CoreCoursesReviewAnswerListService, CoreCoursesReviewCommentsListService } from "../../Domain/Services/CoreCoursesReviewApplicationServices";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CorsCoursesReviewAnswerItem } from "./CoreCoursesReviewAnswerItem";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";


export const CorsCoursesReviewCommentsItem = ({coursereviewcomments, fatherEvent} : CoreCoursesReviewCommentsProps) => {

    const {
        id,
        user_name,
        commentary,
        created_at,
        review_state,
    } = coursereviewcomments;

    const fatherEvent2 = (id:string) => 
    {
        setCoursesReviewAnswer(
            prev => prev.filter(coursesreviewanswer => coursesreviewanswer.id !== id
            )
        );
    try {
            toast.info('Se ha borrado una Respuesta',
                {
                position: 'bottom-center',
                closeButton: false,
                theme: 'colored', hideProgressBar: true, autoClose: 2500
                }
            )}
        catch{}
    }

    const { isTeacher,isAdmin } = useContext( AuthContext );

    const [coursesreviewanswer, setCoursesReviewAnswer] = useState<CoreCoursesReviewAnswer[]>([]);

    useEffect(() => {
        CoreCoursesReviewAnswerListService(String(id)).then(setCoursesReviewAnswer)
    }, [id])

      return (
        <>
        <p>
            <div className="w-full pb-4  ">
                <div className="rounded-t-md bg-gray-300 p-2 w-fit"><b>Fecha de comentario:</b> {created_at}</div>
                    <div className="rounded-tr-lg rounded-b-lg bg-gray-300 w-full p-2">
                        <div className="bg-white p-2 rounded-lg border-gray-300 border"> 
                            {commentary}
                        </div>
                        <div className="flex w-full pt-2 pl-2 bg-gray-300 rounded-b-md">
                            <div className="text-left w-full pt-2">{user_name}</div>
                            <div className='text-right w-full'>
                            {review_state !== 'fbd9942b-6593-4aa4-a2d2-1b2b11905e57' ?
                                (isTeacher(),isAdmin) ? <> 
                                <LinkPrimary to={id+'/answer/create'}>Responder&nbsp;<AnnotationIcon height={24}></AnnotationIcon></LinkPrimary>
                                </>:''
                            :'' }
                            </div>
                        </div>
                </div>
            </div>
                {
                    coursesreviewanswer.map
                        (coursesreviewanswer => 
                        <CorsCoursesReviewAnswerItem 
                            key =  {id} 
                            coursereviewanswer = {coursesreviewanswer}
                            fatherEvent =  {fatherEvent}
                        />

                    )
                }
        </p>
       </>   
    )

}