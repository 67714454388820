import { http } from '../../../../Shared/Http/http';
import {CoreCoursesReview, CoreCoursesReviewAnswer, CoreCoursesReviewAnswerMultipleResponseDTO, CoreCoursesReviewAnswerResponseDTO, CoreCoursesReviewComments, CoreCoursesReviewCommentsDTO, CoreCoursesReviewCommentsMultipleResponseDTO, CoreCoursesReviewCommentsResponseDTO, CoreCoursesReviewDTO, CoreCoursesReviewMultipleResponseDTO, CoreCoursesReviewResponseDTO, CoreCoursesReviewList} from '../Interfaces';

export const CoreCoursesReviewListService = async (id:string) => {

    const corecoursesreview = await http.get<CoreCoursesReviewMultipleResponseDTO>('course/reviews/all/'+id);
    return corecoursesreview.payload.data.map
    ((corecoursesreview):CoreCoursesReviewList=> ({
        id                   : corecoursesreview.id,
        course_id            : corecoursesreview.course_id,
        user_id              : corecoursesreview.user_id,
        description          : corecoursesreview.description,
        state_id             : corecoursesreview.state_id,
        user_name            : corecoursesreview.user_name,
        state_name           : corecoursesreview.state_name,
        created_at           : corecoursesreview.created_at,
        approving_user_id    : corecoursesreview.approving_user_id,
        approving_user_name  : corecoursesreview.approving_user_name

    }));
}

export const CoreCoursesReviewListAllService = async () => {

    const corecoursesreview = await http.get<CoreCoursesReviewMultipleResponseDTO>('course/review/all');
    return corecoursesreview.payload.data.map
    ((corecoursesreview):CoreCoursesReviewList=> ({
        id                   : corecoursesreview.id,
        course_id            : corecoursesreview.course_id,
        user_id              : corecoursesreview.user_id,
        description          : corecoursesreview.description,
        state_id             : corecoursesreview.state_id,
        user_name            : corecoursesreview.user_name,
        state_name           : corecoursesreview.state_name,
        created_at           : corecoursesreview.created_at,
        approving_user_id    : corecoursesreview.approving_user_id,
        approving_user_name  : corecoursesreview.approving_user_name
       

    }));
}


export const CoreCoursesReviewSearchByIdService = async (reviewId:string) => {

    const CoreCoursesreview = await http.get<CoreCoursesReviewResponseDTO>('course/review/'+ reviewId);

    return CoreCoursesreview.payload.data;

}

export const CoreCoursesReviewCreateService
 = async (coursereview:CoreCoursesReview|any) => {

    return await http.post<CoreCoursesReviewResponseDTO>
    ('course/add/review', JSON.stringify(coursereview));

}

export const CoreCoursesReviewUpdateService
 = async (coursereview:CoreCoursesReview|any) => 
{
    return await http.post<CoreCoursesReviewResponseDTO>
    ('course/update/review-state/' + coursereview.id, JSON.stringify(coursereview));

}

export const CoreCoursesReviewDeleteService
 = async (id:string) => 
{
    return await http.delete<CoreCoursesReviewResponseDTO>
    ('course/delete/review/' + id);

}


// servicios comentarios

export const CoreCoursesReviewCommentsListService = async (id:string) => {

    const coursereviewcomments = await http.get<CoreCoursesReviewCommentsMultipleResponseDTO>('course/find/review/'+id);
    return coursereviewcomments.payload.data.map
    ((coursereviewcomments):CoreCoursesReviewComments=> ({
        id                   : coursereviewcomments.id,
        review_id            : coursereviewcomments.review_id,
        commentary           : coursereviewcomments.commentary,
        comment_id           : coursereviewcomments.comment_id,
        answers              : coursereviewcomments.answers,
        create_at            : coursereviewcomments.create_at,
        answer               : coursereviewcomments.answer,
        user_id              : coursereviewcomments.user_id,
        created_at           : coursereviewcomments.created_at,  
        user_name            : coursereviewcomments.user_name,
        review_state         : coursereviewcomments.review_state
    }))};

    export const CoreCoursesReviewCommentsCreateService
 = async (coursereviewcomments:CoreCoursesReview|any) => {

    return await http.post<CoreCoursesReviewCommentsResponseDTO>
    ('course/add/comment', JSON.stringify(coursereviewcomments));

}

    export const CoreCoursesReviewCommentsUpdateService
    = async (coursereviewcomments:CoreCoursesReviewComments|any) => 
   {
       return await http.post<CoreCoursesReviewCommentsResponseDTO>
       ('course/update/comment' + coursereviewcomments.id, JSON.stringify(coursereviewcomments));
   
   }
   
   export const CoreCoursesReviewCommentsDeleteService
    = async (id:string) => 
   {
   
       return await http.delete<CoreCoursesReviewCommentsDTO>
       ('course/delete/comment' + id);
   
   }

// servicios de respuesta

export const CoreCoursesReviewAnswerListService = async (id:string) => {

    const coursereviewanswer = await http.get<CoreCoursesReviewAnswerMultipleResponseDTO>('course/find/answer/'+id);
    return coursereviewanswer.payload.data.map
    ((coursereviewanswer):CoreCoursesReviewAnswer=> ({
        id                   : coursereviewanswer.id,
        comment_id           : coursereviewanswer.comment_id,
        answer               : coursereviewanswer.answer,
        user_id              : coursereviewanswer.user_id,
        user_name            : coursereviewanswer.user_name,
        created_at           : coursereviewanswer.created_at     
    }))};

    export const CoreCoursesReviewAnswerCreateService
 = async (coursereviewanswer:CoreCoursesReview|any) => {

    return await http.post<CoreCoursesReviewAnswerResponseDTO>
    ('course/add/answer', JSON.stringify(coursereviewanswer));

}

