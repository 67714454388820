import { Routes, Route } from "react-router-dom"
import {  DependencyList  } from "./Components/DependencyList"

export const DependencyRouter = () => 
{
    return (
        <Routes>
            <Route path="/" element={<DependencyList/>}/>       
        </Routes>
    )
}