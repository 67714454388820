import { useEffect, useState } from 'react'
import { Provider } from '../../Domain/Interfaces';
import { Table, TableFilter } from "../../../../Shared/React/components/styles/Tables";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { ButtonPrimary, LinkPrimary } from "../../../../Shared/React/components/styles/Buttons";

import { toast } from 'react-toastify';
import { ProviderDeleteService, ProviderListService } from '../../Domain/Services/ProviderApplicationServices';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DataTableStyle } from '../../../../Shared/React/components/styles/DataTableStyle';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { ValidatorBox } from '../../../../Shared/React/components/field/ValidatorBox';
import { ProviderCreate } from './ProviderCreate';
import { ProviderEdit } from './ProviderEdit';



export const ProviderList = () => {

    const { id } = useParams();


    const [provider, setProvider] = useState<Provider[]>([]);

    
     const ProviderDelete = (id:string) => {
        ProviderDeleteService(id).then((result) => { 

            setProvider(prev => prev.filter(provider => provider.id !== id));
             try {
                  toast.info('Se ha eliminado un Proveedor',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      }
                  )}
              catch{}
        })
      } 
 
      const navigate = useNavigate();
      const handleReturn = () => {
          navigate("../.." );
      }

      useEffect(() => {
        ProviderListService().then(setProvider)

    }, [id])

    const LoadProviderList = () => {
      ProviderListService().then(setProvider)  }

    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);

    
    const ProviderItem =
    [
        {
          name: 'Nombre',
          selector: (row:any) => row.name,
          cell: (row:any) => row.name,
          sortable: true,
		      reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        {
            name: 'Código del Sistema',
            selector: (row:any) => row.system_code,
            cell: (row:any) => row.system_code,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
          },
        { 
          name: 'Acciones',
          width:'12%',
          reorder: true,
          center: true,
          verticalAlign: 'middle',
          selector: (row:any) => row.name,
          cell: (row:any) => 
          <div className='flex justify-center w-full h-full'>
            <button title='Editar un Proveedor' data-bs-toggle="modal" data-bs-target={"#editProvider-"+row.id}>
                    <PencilIcon className='text-primary-900' height='28px'/>
          </button>
            <button title='Borrar un programa' data-bs-toggle="modal" data-bs-target={"#ProviderDelete-"+row.id} >
                <TrashIcon className='text-primary-900' height='28px'/>
            </button>
            
            <ValidatorBox
                        id={row.id}
                        name={row.name}
                        actionname="ProviderDelete"
                        service={ProviderDelete}
                        action="deshabilitar el proveedor"
                        />
                        <ProviderEdit service={LoadProviderList} id={row.id}/>
         </div>
      },
    ]

    return (
        <>
        <WrapperTitle>
            Proveedores
        </WrapperTitle>
        <WrapperContent>
        
        <div className='text-right mb-8 mt-2'>
            <ButtonPrimary title='Crear Proveedor' data-bs-toggle="modal" data-bs-target="#createProvider">
                    Crear Proveedor
                </ButtonPrimary>
                <ProviderCreate service={LoadProviderList}/>
            </div>

            {provider[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay proveedores creados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
           
            <TableFilter 
                  columns={ProviderItem}
                  data={provider}
                  customStyles={DataTableStyle}
                  pagination
                  responsive
                  striped
                  highlightOnHover
              />}
        </WrapperContent>
        </>
            
        )
}