import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthState } from '../Auth/Domain/Interfaces';
import { AuthContext } from '../Auth/Infrastructure/context/AuthContext';

export const PublicRoute = ({ children }:any) => {
    const { authState } = useContext( AuthContext );

    return (!authState.logged)
        ? children
        : <Navigate to="/dashboard/course" />
}

