import { useContext, useEffect, useState } from 'react'
import { WrapperContent, WrapperTitle } from '../../../../Shared/React/components/styles/Wrappers';

import { DataTableStyle } from '../../../../Shared/React/components/styles/DataTableStyle';
import { TableFilter } from '../../../../Shared/React/components/styles/Tables';

import { Role } from '../../Domain/Interfaces';
import { RoleDeleteService, RoleListService } from '../../Domain/Services/RoleApplicationServices';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ButtonPrimary, LinkPrimary } from '../../../../Shared/React/components/styles/Buttons';
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
import { ValidatorBox } from '../../../../Shared/React/components/field/ValidatorBox';
import { RoleCreate } from './RoleCreate';
import { RoleEdit } from './RoleEdit';


export const RoleList = () => {
    
    const [roles, setRole] = useState<Role[]>([]);
    const { isAdmin } = useContext( AuthContext );
    
    useEffect(() => {
        RoleListService().then(setRole)
    }, [])

    const LoadRoleList = () => {
      RoleListService().then(setRole)
  }

    const RoleDelete = (id: string) => {
      RoleDeleteService(id).then((resp) => { 
        if (JSON.stringify(resp.success) === 'true') {
          setRole(prev => prev.filter(roles => roles.id !== id));
          toast.info('Se ha eliminado un rol',
                    {
                    position: 'bottom-center',
                    closeButton: false,
                    theme: 'colored', hideProgressBar: true, autoClose: 2500
                    }
                )}
        else {
                toast.error('No es posible eliminar este rol',
                    {
                    position: 'bottom-center',
                    closeButton: false,
                    theme: 'colored', hideProgressBar: true, autoClose: 2500
                    })
                }}
          )
    }

    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);

  const RoleItem =
  [
    {
      name: 'Nombre',
      selector: (row:any) => row.name,
      cell: (row:any) => row.name,
      sortable: true,
      reorder: true,
      filterable: true,
      marginBottom: '10px',
    },
     {
      name: 'Etiqueta',
      selector: (row:any) => row.label,
      cell: (row:any) => row.label,
      sortable: true,
      reorder: true,
      filterable: true,
      marginBottom: '10px',
    },
    {
        name: 'Descripcion',
        selector: (row:any) => row.description,
        cell: (row:any) => row.description,
        sortable: true,
        reorder: true,
        filterable: true,
        marginBottom: '10px',
    },
    {
      name: 'Acciones',
      width: '12%',
      reorder: true,
      center: true,
      selector: (row:any) => row.name,
      cell: (row:any) =>
        <div className='flex justify-center w-full h-full'>
          <button title='Editar un Rol' data-bs-toggle="modal" data-bs-target={"#editRole-"+row.id}>
                    <PencilIcon className='text-primary-900' height='28px'/>
          </button>
          <button title='Borrar un rol' data-bs-toggle="modal" data-bs-target={"#deleteRole-"+row.id} >
                <TrashIcon className='text-primary-900' height='28px' />
          </button>
          <ValidatorBox
                        id={row.id}
                        name={row.name}
                        actionname="deleteRole"
                        service={RoleDelete}
                        action="deshabilitar un rol"
                        />
                        <RoleEdit id={row.id} service={LoadRoleList}/>
        </div>
    }
  ]  
    
  return (
    <>
    <ToastContainer/>
    <WrapperTitle>
        Roles
    </WrapperTitle>
    <WrapperContent>
      <div className='p-2 w-full rounded-lg'>
        {
                isAdmin() ?
                <div className='text-right mb-8 mt-2'>
                <ButtonPrimary title='Crear Rol' data-bs-toggle="modal" data-bs-target="#createRole">
                        Crear Rol
                    </ButtonPrimary>
                    <RoleCreate service={LoadRoleList}/>
                </div>
              :''
            }
            {roles[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay roles creados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
        <TableFilter 
              columns={RoleItem}
              data={roles}
              customStyles={DataTableStyle}
              pagination
              responsive
              striped
              highlightOnHover
          />}
      </div>
    </WrapperContent>
    </>
        
    )
}