import { Form, Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useEffect, useState } from 'react';
import { Dependency } from '../../Domain/Interfaces';
import { DependencySearchByIdService, DependencyUpdateService  } from '../../Domain/Services/DependencyApplicationServices';
import { useNavigate } from "react-router-dom"
import { INITIAL_VALUE_DEPENDENCY } from "../../Domain/Types";
import { DependencyFieldsForm } from './DependencyFieldsForm';
import { DependencyValidationSchema } from '../Validation/DependencyValidationSchema';
import { ToastContainer } from 'react-toastify';
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

interface Props {
  id: string,
  service: any,
}

export const DependencyEdit = ({ ...row }:Props) => {

  const navigate = useNavigate();

      const dependency_id = row.id;


      const [dependency, setDependency] = useState<Dependency>(INITIAL_VALUE_DEPENDENCY);
   


  const onSubmitEventElement = (values:Dependency) => {
    DependencyUpdateService (values).then((resp) =>{
      if (toastAlert(resp)) {
        row.service();   
      }
  });
  }

  const loadDependency = () => {DependencySearchByIdService(String(dependency_id)).then((dependency) => setDependency(dependency))};
  

  return (
    <>
    <ModalExtructure className="modal" onMouseEnter={loadDependency} id={"editDependency-"+row.id} aria-labelledby={"edit-Label-Dependency-"+row.id} aria-hidden="true" style={{top: 55}} >
    <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content" >
                    <ModalHeader className="modal-header">
                        <ModalTitle id={"edit-Label-Dependency"+row.id}>Editar Dependencia</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
    <WrapperContent>
    {dependency.name !== '' ? 
      <Formik
        enableReinitialize={true}
        initialValues={dependency}
        onSubmit={ values => { onSubmitEventElement(values) }}
        validationSchema={DependencyValidationSchema}>
          {
            (formmik) => (
              <DependencyFieldsForm/>
            )
          }
      </Formik>
      :

      <div>
        <img className="self-center w-full" src="/images/loading.svg" alt="" />
        <ButtonPrimary className="pt-5" data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
      </div>
      }
    </WrapperContent>
    </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
    </>
  )
}