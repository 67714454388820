import { useContext, useEffect, useState} from 'react'
import { User} from '../../Domain/Interfaces';
import { TableFilter } from "../../../../Shared/React/components/styles/Tables";
import { UserDeleteService, UserListService, UserResetPasswordService } from '../../Domain/Services/UserApplicationServices';
import { DataTableStyle } from '../../../../Shared/React/components/styles/DataTableStyle';
import { WrapperContent, WrapperTitle } from '../../../../Shared/React/components/styles/Wrappers';
import { ButtonPrimary, LinkPrimary } from '../../../../Shared/React/components/styles/Buttons';
import { Link } from 'react-router-dom';
import { CheckCircleIcon, LockClosedIcon, PencilAltIcon, PencilIcon, TicketIcon, TrashIcon } from '@heroicons/react/outline';
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import { ValidatorBox } from '../../../../Shared/React/components/field/ValidatorBox';
import { UserCreate } from './UserCreate';
import { UserEdit } from './UserEdit';
import { UserFacultyAssign } from '../../../UserFaculty/Infrastructure/Components/UserFacultyAssign';

export const UserList = () => {

   const [users, setUser] = useState<User[]>([]);
   const { authState, isMonitor, isTeacher, isManager, isAdmin, getRoles } = useContext( AuthContext );

    useEffect(() => {
      //@ts-ignore
        UserListService(getRoles(), authState.user?.id).then(setUser)
    }, [authState.user?.id, getRoles])

    const LoadUserList = () => {
      //@ts-ignore
        UserListService(getRoles(), authState.user?.id).then(setUser)
    }

    const DeleteUser = (uuid: string) => {
      UserDeleteService(uuid).then((result) => { 
           setUser(prev => prev.filter(user => user.uuid !== uuid));
           try {
                toast.info('Se ha borrado un Usuario',
                    {
                    position: 'bottom-center',
                    closeButton: false,
                    theme: 'colored', hideProgressBar: true, autoClose: 2500
                    }
                )}
            catch{}
      })
    }

    const PassResetUser = (uuid: string) => {
      UserResetPasswordService(uuid).then((result) => { 
           try {
                toast.info('Se ha restablecido la contraseña',
                    {
                    position: 'bottom-center',
                    closeButton: false,
                    theme: 'colored', hideProgressBar: true, autoClose: 2500
                    }
                )}
            catch{}
      })
    }

    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);
  

    const UserItem =
    [
        {
          name: 'Documento',
          width:'12%',
          selector: (row:any) => row.document,
          cell: (row:any) => row.document,
          sortable: true,
          reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        {
          name: 'Nombre',
          selector: (row:any) => row.name,
          cell: (row:any) => row.name,
          sortable: true,
		      reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        {
          name: 'Apellido',
          selector: (row:any) => row.last_name,
          cell: (row:any) => row.last_name,
          sortable: true,
		      reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        {
          name: 'Correo',
          selector: (row:any) => row.email,
          cell: (row:any) => row.email,
          sortable: true,
		      reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        {
          name: 'Rol',
          width:'12%',
          selector: (row:any) => row.role_name,
          cell: (row:any) => row.role_name,
          sortable: true,
		      reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        { 
          name: 'Acciones',
          width:'8%',
          reorder: true,
          center: true,
          verticalAlign: 'middle',
          selector: (row:any) => row.name,
          cell: (row:any) => 
          <div className='flex'>
                    <button title='Editar un Usuario' data-bs-toggle="modal" data-bs-target={"#editUser-"+row.uuid}>
                      <PencilIcon className='text-primary-900' height='28px'/>
                    </button>
                    <button title='Restablecer Contraseña' onClick={ () => {PassResetUser(row.uuid)} }>
                      <LockClosedIcon className='text-primary-900' height='28px'/>
                    </button>
                    {
                        (row.role_name !== 'Administrador') ? <> 

                    <button title='Gestionar Facultades Asignadas' data-bs-toggle="modal" data-bs-target={"#facultyUser-"+row.uuid}>
                    <TicketIcon className='text-primary-900' height='28px'/>
                    </button>
                    </>:
                    ''
                    }
                    {(isAdmin() ) ?
                    <button title='Borrar un Usuario' data-bs-toggle="modal" data-bs-target={"#deleteUser-"+row.uuid}>
                      <TrashIcon className='text-primary-900' height='28px'/>
                    </button>
                     : ''
                    }
                    <UserFacultyAssign uuid={row.uuid}/>
                    <UserEdit uuid={row.uuid} service={LoadUserList}/>
                    <ValidatorBox
                        id={row.uuid}
                        name={row.name+" "+row.last_name}
                        actionname="deleteUser"
                        service={DeleteUser}
                        action="deshabilitar el usuario"
                        />

          </div>
      },
    ]
    
    return (
        <>
        <ToastContainer/>
        <WrapperTitle>
            Usuarios
        </WrapperTitle>
        <WrapperContent>
            <div className='text-right mb-8 mt-2'>
                  <ButtonPrimary title='Crear Usuario' data-bs-toggle="modal" data-bs-target="#createUser">
                    Crear Usuario
                  </ButtonPrimary>
                  <UserCreate service={LoadUserList}/>
            </div>
          <div className='p-2 w-full rounded-lg'>
          {users[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay usuarios creados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
            <TableFilter 
                  columns={UserItem}
                  data={users}
                  customStyles={DataTableStyle}
                  pagination
                  responsive
                  striped
                  highlightOnHover
              />
          }
          </div>
          
        </WrapperContent>
        </>
            
        )
}