import { http } from '../../../../Shared/Http/http';
import {Faculty, FacultiesResponseDTO, FacultyResponseDTO} from '../Interfaces';
import { manager, admin, teacher, monitor } from '../../../../Auth/Infrastructure/context/AuthProvider'; 
import { KeyValue } from '../../../CorsCourses/Domain/Interfaces';


export const FacultyListService = async ( roles:KeyValue[], id:string ) => {

    let route = '';
    
    switch (roles[0].name) {
        case admin:
            route = 'faculty/list';
        break;

        case manager:
            route = 'faculty/' + id + '/list';
        break;
        
        case monitor:
            route = 'faculty/list';
        break;
    
        default:
            break;
    }

    const faculty = await http.get<FacultiesResponseDTO>(route);
    return faculty.payload.data.map
    ((faculty):Faculty=> ({
        id                   : faculty.id,
        name                 : faculty.name,
        programs             : faculty.programs
    }));
}

export const FacultyListProgramsService = async () => {

    const faculty = await http.get<FacultiesResponseDTO>('faculty/listPrograms');
    return faculty.payload.data.map
    ((faculty):Faculty=> ({
        id                   : faculty.id,
        name                 : faculty.name,
        programs             : faculty.programs,
    }));
}

export const FacultySearchByIdService = async (id:string) => {

    const CorsCourses = await http.get<FacultyResponseDTO>('faculty/facultad/' + id);

    return CorsCourses.payload.data;

}

export const FacultyCreateService
 = async (faculty:Faculty|any) => {

    return await http.post<FacultyResponseDTO>
    ('faculty/create', JSON.stringify(faculty));

}

export const FacultyUpdateService
 = async (faculty:Faculty|any) => 
{
    return await http.post<FacultyResponseDTO>
    ('faculty/update/' + faculty.id, JSON.stringify(faculty));

}

export const FacultyDeleteService
 = async (facultyId:string) => 
{

    return await http.delete<FacultyResponseDTO>
    ('faculty/delete/' + facultyId);

}