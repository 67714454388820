import {array, object, string, mixed} from 'yup'

const SUPPORTED_FORMATS = ['text/csv', 'application/csv'];

export const CorsCoursesValidationSchema = object().shape({
    name: string().required('Requerido'),
    code: string().required('Requerido'),
    url: string().required('Requerido').matches(/(\bhttp:\/\/|\bhttps:\/\/)((\w{3,}\.\w+\.\w{2,3}\.\w{2})|(\w{3,}\.\w+\.\w{2,3})|(\w{3,}\.\w{2,3}))(:[0-9]{1,4}|)($|\/[a-zA-Z0-9_\/\-\+\=?]+)/,'La direccion debe ser valida'),
    dependency_id: string().required('Requerido'),
    platform_id: string().required('Requerido'),
    provider_id : string().required('Requerido'),
    creation_date : string().required('Requerido'),
    program_id : array().required('Requerido'),
})

export const BulkLoadValidationSchema = object().shape({
    document : mixed().nullable(),
})