

import { uploableFieldResponseDto } from '../../Domain/Interfaces'
import { LOAD_VALUE_MASIVECHARGE } from "../../Domain/Types";
import { useEffect, useState } from "react";

import { BulkLoadFieldsForm } from './BulkLoadFieldsForm';
import { BulkLoadValidationSchema } from '../Validation/BulkLoadValidationSchema';
import { uploableFieldService2 } from '../../Domain/Services/BulkLoadListService';
import { StatusListService } from "../../../Status/Domain/Services/StatusApplicationServices";

import { Formik } from "formik";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Status } from "../../../Status/Domain/Interfaces";
import { ButtonPrimary, LinkPrimary } from '../../../../Shared/React/components/styles/Buttons';
import { Table } from '../../../../Shared/React/components/styles/Tables';
import { FacultyModalList } from './ModalList/FacultyModalList';

export const BulkLoadProgramsUploable = () => {
    let classBg = "bg-[#fff]";
    let tempFile = '';
    let rpterror;
    let rptmensaje;
    const navigate = useNavigate();

    const [multistatus, setMultipleStatus] = useState<Status[]>([]);
    const [multistatus2, setMultipleStatus2] = useState<Status[]>([]);
    useEffect(() => {
        StatusListService('0').then((resultado) => setMultipleStatus(resultado));
        StatusListService('1').then((resultado) => setMultipleStatus2(resultado));
    }, [])

    const [errores, seterrores] = useState([]);
    const [info, setinfo] = useState([]);


    const onSubmitEventElement = (uploable: uploableFieldResponseDto) => {
        let respuesta;
        const formData = new FormData();
        formData.set('document', tempFile);
        uploableFieldService2(formData).then((resp) => {
            console.log('ahh');
            respuesta = JSON.parse(JSON.stringify(resp));

            if (respuesta.success) {
                seterrores(respuesta.payload.messages);
                setinfo(respuesta.payload.info);
            }
        });
    }

    const handlerFile = (e: any) => {
        tempFile = e.target.files[0];
    }


    return (
        <>
            <ToastContainer />
            <WrapperTitle>
                Carga masiva de programas de los Cursos
            </WrapperTitle>

            <WrapperContent>
                <div className='bg-primary-500 text-2xl p-2 text-white w-full text-center items-center rounded-t-xl'>Pasos para subir el archivo </div>
                <div className='bg-white p-4 rounded-b-lg align-center text-center items-center justify-center w-full flex'>

                    <div>
                        <div>
                            <div>
                                Crea un archivo CSV con la siguiente estructura.
                                <br />
                                <Table>
                                    <tr>
                                            <th>
                                                Código Institucional
                                            </th>
                                            <th>
                                                Código Programa
                                            </th>
                                    </tr>
                                </Table>
                            </div>
                            
                            <div className='w-full flex items-center justify-center p-2'>
                                Deja la cabacera con la estructura dada anteriormente   
                            </div>
                            <div className='w-full flex items-center justify-center p-2'>
                                Ingrese los respectivos códigos del curso y del programa.
                            </div>
                            <div className='w-full flex items-center justify-center p-2'>
                                los códigos de programa los puedes encontrar en su respectiva facultad.
                            </div>
                            <span className='w-full flex items-center justify-center p-2'>
                            <ButtonPrimary title='Facultades' data-bs-toggle="modal" data-bs-target="#listFaculty">Facultades</ButtonPrimary>
                            <FacultyModalList/>

                            </span>
                        </div>
                    </div>
                </div>

                <Formik
                    initialValues={LOAD_VALUE_MASIVECHARGE}
                    onSubmit={(values) => { onSubmitEventElement(values) }}
                    validationSchema={BulkLoadValidationSchema}
                >
                    {
                        (formmik) => (
                            <BulkLoadFieldsForm fatherEvent={handlerFile} />
                        )
                    }
                </Formik>
                <div className="info">
                    {Object.keys(errores).length > 0 ? (
                        <div>
                            <div>
                                <div className='mt-6 bg-primary-500 text-2xl p-2 text-white w-full text-center items-center rounded-t-xl'>Información de lectura del archivo</div>
                                <div className='p-6 bg-white rounded-b-lg '>
                                    {JSON.parse(JSON.stringify(info)).success}<br />
                                    {JSON.parse(JSON.stringify(info)).duplicados}<br />
                                    {JSON.parse(JSON.stringify(info)).errors}<br />
                                    {JSON.parse(JSON.stringify(info)).leidos}<br />


                                </div>
                                <div >
                                    <Table className='mt-6'>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Línea
                                                </th>
                                                <th>
                                                    Mensajes
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(errores).map((error, index) => {
                                                rpterror = JSON.parse(JSON.stringify(error));
                                                if (index % 2 === 0) {
                                                    classBg = "bg-[#ececec]";
                                                } else {
                                                    classBg = "bg-[#fff]";
                                                }
                                                if (rpterror.errors) {
                                                    return (
                                                        <tr className={`border-b ${classBg}`}>
                                                            <td className='text-center'>
                                                                {rpterror.linea}
                                                            </td>
                                                            <td >
                                                                <ul>
                                                                    {Object.values(rpterror.errors).map((mensaje) => {
                                                                        rptmensaje = JSON.parse(JSON.stringify(mensaje));
                                                                        return <li>{rptmensaje}</li>;
                                                                    })}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </WrapperContent>
        </>
    )
}



