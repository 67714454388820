import { Routes, Route } from "react-router-dom"
import { CorsCoursesCreate } from "./Components/CorsCoursesCreate"
import { CorsCoursesDisabledList } from "./Components/CorsCoursesDisabledList"
import { CorsCoursesEdit } from "./Components/CorsCoursesEdit"
import {  CorsCoursesList,  } from "./Components/CorsCoursesList"
import { CoursesExtension } from "./Components/CoursesExtension"

export const CorsCoursesRouter = () => 
{
    return (
        <Routes>
            <Route path="/" element={<CorsCoursesList/>}/>
            <Route path="/disabled" element={<CorsCoursesDisabledList/>}/>
            <Route path="/create" element={<CorsCoursesCreate/>} />
            <Route path="/edit/:courseId" element={<CorsCoursesEdit/>} />
            <Route path="/:courseId/detail" element={<CoursesExtension/>} />           
            <Route path="/:courseId" element={<CoursesExtension/>} />           

        </Routes>
    )
}