import { useEffect, useState} from 'react'
import { Platform} from '../../Domain/Interfaces';
import { Table, TableFilter } from "../../../../Shared/React/components/styles/Tables";
import { WrapperContent, WrapperTitle} from "../../../../Shared/React/components/styles/Wrappers";
import { ButtonPrimary, LinkPrimary } from "../../../../Shared/React/components/styles/Buttons";
import { toast } from 'react-toastify';
import { PlatformDeleteService, PlatformListService } from '../../Domain/Services/PlatformApplicationServices';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { ValidatorBox } from '../../../../Shared/React/components/field/ValidatorBox';
import { DataTableStyle } from '../../../../Shared/React/components/styles/DataTableStyle';
import { PlatformCreate } from './PlatformCreate';
import { PlatformEdit } from './PlatformEdit';



export const PlatformList = () => {


    const [platforms, setPlatform] = useState<Platform[]>([]);

    const PlatformDelete = (id: string) => {
        PlatformDeleteService(id).then((result) => { 

            setPlatform(prev => prev.filter(program => program.id !== id));
             try {
                  toast.info('Se ha deshabilitado una Plataforma',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      }
                  )}
              catch{}
        })
      }

    useEffect(() => {
        PlatformListService().then(setPlatform)
    }, [])

   const LoadPlatformList = () => {
      PlatformListService().then(setPlatform)
  }

    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);

    const PlatformItem =
    [
        {
          name: 'Nombre',
          selector: (row:any) => row.name,
          cell: (row:any) => row.name,
          sortable: true,
		      reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        {
            name: 'Código del Sistema',
            selector: (row:any) => row.system_code,
            cell: (row:any) => row.system_code,
            sortable: true,
            reorder: true,
            filterable: true,
            marginBottom: '10px',
          },
        { 
          name: 'Acciones',
          width:'12%',
          reorder: true,
          center: true,
          verticalAlign: 'middle',
          selector: (row:any) => row.name,
          cell: (row:any) => 
          <div className='flex justify-center w-full h-full'>
          {/*<button title='Editar una Plataforma' data-bs-toggle="modal" data-bs-target={"#editPlatform-"+row.id}>
                    <PencilIcon className='text-primary-900' height='28px'/>
        </button>*/}
            <button title='Borrar una plataforma' data-bs-toggle="modal" data-bs-target={"#deletePlatform-"+row.id} >
                <TrashIcon className='text-primary-900' height='28px'/>
            </button>

            <ValidatorBox
                        id={row.id}
                        name={row.name}
                        actionname="deletePlatform"
                        service={PlatformDelete}
                        action="deshabilitar una plataforma"
                        />
            <PlatformEdit id={row.id} service={LoadPlatformList}/>
         </div>
      },
    ]

    
  return (
        <>
        <WrapperTitle>
            Plataformas
        </WrapperTitle>
        <WrapperContent>
        <div className='text-right mb-8 mt-2'>
            <ButtonPrimary title='Crear Platforma' data-bs-toggle="modal" data-bs-target="#createPlatform">
                    Crear Platforma
                </ButtonPrimary>
               <PlatformCreate  service={LoadPlatformList}/>
            </div>

            {platforms[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay plataformas creadas para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
              <TableFilter 
              columns={PlatformItem}
              data={platforms}
              customStyles={DataTableStyle}
              pagination
              responsive
              striped
              highlightOnHover
          />

    }
            
        </WrapperContent>
        </>
    )
}