import { Route, Routes } from "react-router-dom"
import { CorsCoursesReviewCommentsList } from "./Components/CoreCoursesReviewCommentsList"
import { CoreCoursesReviewCommentsCreate } from "./Components/CoreCoursesReviewCommentsCreate"
import { CorsCoursesReviewCreate } from "./Components/CoreCoursesReviewCreate"
import { CorsCoursesReviewList } from "./Components/CoreCoursesReviewList"
import { CoreCoursesReviewAnswerCreate } from "./Components/CoreCoursesReviewAnswersCreate"
import { CorsCoursesReviewStatusChangeCreate } from "./Components/CoreCoursesReviewStatusChangeEdit"

export const CoreCoursesReviewRouter = () => 
{
    return (
        <Routes>
            <Route path="/" element={<CorsCoursesReviewList/>}/>
            <Route path="/create" element={<CorsCoursesReviewCreate/>}/>
            <Route path="/:reviewId/comment" element={<CorsCoursesReviewCommentsList/>}/>
            <Route path="/:reviewId/comment/create" element={<CoreCoursesReviewCommentsCreate/>}/>
            <Route path="/:reviewId/comment/:commentaryId/answer/create" element={<CoreCoursesReviewAnswerCreate/>}/>
            <Route path="/:reviewId/statuschange" element={<CorsCoursesReviewStatusChangeCreate/>}/>
        </Routes>
    )
}