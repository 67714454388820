import { http } from '../../../../Shared/Http/http';
import {UserFaculty, UserFacultyResponseDTO, UserFacultiesResponseDTO} from '../Interfaces';

export const UserFacultyListService = async () => {
    const userfaculty = await http.get<UserFacultiesResponseDTO>('faculty-user/list');
    return userfaculty.payload.data.map
    ((userfaculty):UserFaculty=> ({
        id                    : userfaculty.id,
        user_uuid             : userfaculty.user_uuid,
        faculty_id            : userfaculty.faculty_id,
    }));
}

export const UserFacultyListByUserIdService = async (uuid:string) => {

    const user = await http.get<UserFacultiesResponseDTO>('faculty-user/find/user/' + uuid);

    return user.payload.data;

}

export const UserFacultyAssignService
 = async (userfaculty:UserFaculty|any) => {

    return await http.post<UserFacultyResponseDTO>
    ('faculty-user/associate', JSON.stringify(userfaculty));

}

export const UserFacultyDeleteService
 = async (uuid:string) => 
{

    return await http.delete<UserFacultyResponseDTO>
    ('faculty-user/delete/' + uuid);

}