import { http } from '../../../../Shared/Http/http';
import { CorsCourses, CorsCoursesMultipleResponseDTO, CorsCoursesResponseDTO, CorsCoursesResponseLoadDTO, CourseFaculty,uploableFieldResponseDto,uploableField, KeyValue, DisabledCourses,downloadAllCoursesResponseDto } from '../Interfaces';
import { manager, admin, teacher, monitor } from '../../../../Auth/Infrastructure/context/AuthProvider'; 




export const uploableFieldService
 = async (uploable:FormData|any) => 
{
    return await http.post<uploableFieldResponseDto>
    ('course/carga', uploable, true);

}

export const uploableFieldService2
 = async (uploable:FormData|any) => 
{
    return await http.post<uploableFieldResponseDto>
    ('course/carga_programas', uploable, true);

}

export const downloadAllCourses
 = async () => 
{
    return await http.get<downloadAllCoursesResponseDto>
    ('course/export');

}
