import { Field, Form } from "formik"
import { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { InputField, SelectField } from "../../../../Shared/React/components/field"
import {ButtonPrimary} from "../../../../Shared/React/components/styles/Buttons";



export const FacultyFieldsForm = () => {
    
    const navigate = useNavigate();
    const handleReturn = () => {
        navigate( 0 );
    }

    return (
            <Form>
                <InputField
                label='Nombre'
                name='name'
                type='text'>
                </InputField>

                <div className="pt-5">
                <ButtonPrimary data-bs-dismiss="modal" type="reset">Cerrar</ButtonPrimary>
                <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                </div>
            </Form>
    )
}