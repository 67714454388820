import { ToastContainer } from "react-toastify";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { DataTableStyle } from "../../../../Shared/React/components/styles/DataTableStyle";
import { useEffect, useState } from "react";
import { EnlistmentFile, Sync } from "../../Domain/Interfaces";
import { EnlistmentFileService, SyncListService } from "../../Domain/Services/SyncApplicationServices";
import { TableFilter } from "../../../../Shared/React/components/styles/Tables";
import { Link, useParams } from "react-router-dom";
import {  Button, LinkPrimary } from "../../../../Shared/React/components/styles/Buttons";
import { term_data_name } from "./SyncList";
import { DocumentReportIcon, DownloadIcon } from "@heroicons/react/outline";


export const SyncDetails = () => {

    const {id}=useParams();
    
    const [SyncData, setSyncList] = useState<EnlistmentFile[]>([]);

    useEffect(() => {
        EnlistmentFileService(String(id)).then(setSyncList)
    }, [id])
    
    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);

    const SyncItem = [
        {
            name: 'Archivo',
            selector: (row:any) => row.file_name,
            cell: (row:any) => row.file_name,
            sortable: true,
            reorder: true,
            filterable: false,
            marginBottom: '10px',
        },
        {
            name: 'Fecha de Carga',
            selector: (row:any) => row.created_at,
            cell: (row:any) => row.created_at,
            sortable: true,
            reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        { 
            name: 'Acciones',
            width:'8%',
            reorder: true,
            center: true,
            verticalAlign: 'middle',
            selector: (row:any) => row.file_name,
            cell: (row:any) => 
            <div className='flex justify-center w-full h-full'>
              <a target="_blank" rel="noreferrer" title='Descargar Archivo de Alistamiento' href={process.env.REACT_APP_URL_SEE_FILES+'/'+row.file_saved_name} download={row.file_saved_name}>
                    <DownloadIcon className='text-primary-900' height='28px'/>
                </a>
                <Link title='Ver detalles de un alistamiento' to={row.id}>
                <DocumentReportIcon className='text-primary-900' height='28px'/>
            </Link>
           </div>
        },
    ]



    return ( <>        
    <ToastContainer/>
    <WrapperTitle>
            Detalles de Alistamiento: {term_data_name}
    </WrapperTitle>
    <WrapperContent>
    {SyncData[0] === void[] 
             ? 
              (showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay archivos de alistamiento guardados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   )
            :
        <TableFilter 
                  columns={SyncItem}
                  data={SyncData}
                  customStyles={DataTableStyle}
                  pagination
                  responsive
                  striped
                  highlightOnHover
              />
}
              <div className='text-right pt-5'>
        <LinkPrimary to='../'>Atrás</LinkPrimary>
        </div>
    </WrapperContent>
    </>);

};