import { http } from '../../../../Shared/Http/http';
import { Role, RoleMultipleResponseDTO, RoleResponseDTO } from '../Interfaces';

export const RoleListService = async () => {

    const Role = await http.get<RoleMultipleResponseDTO>('role/list');
    return Role.payload.data.map
        ((role): Role => ({
            id: role.id,
            name: role.name,
            label: role.label,
            description: role.description
        }));
}

export const RoleCreateService
    = async (role: Role | any) => {
        return await http.post<RoleResponseDTO>
            ('role/create', JSON.stringify(role));
    }

export const RoleSearchByIdService = async (id:string) => {

        const Role = await http.get<RoleResponseDTO>('role/list-role/' + id);
    
        return Role.payload.data;
    
    }
    

export const RoleUpdateService
    = async (role:Role|any) => 
   {
       return await http.post<RoleResponseDTO>
       ('role/update/' + role.id, JSON.stringify(role));
   
   }


   export const RoleDeleteService
 = async (id:string) => 
{

    return await http.delete<RoleResponseDTO>
    ('role/delete/' + id);

}