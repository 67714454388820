import { http } from '../../../../Shared/Http/http';
import {Provider, ProvidersResponseDTO, ProviderResponseDTO} from '../Interfaces';

export const ProviderListService = async () => {

    const provider = await http.get<ProvidersResponseDTO>('provider/list');

    return provider.payload.data.map
    ((provider):Provider=> ({
        id                   : provider.id,
        name                 : provider.name,
        system_code          : provider.system_code,
    }));
}

export const ProviderCreateService
 = async (provider:Provider|any) => {

    return await http.post<ProviderResponseDTO>
    ('provider/create', JSON.stringify(provider));

}

export const ProviderUpdateService
 = async (provider:Provider|any) => 
{
    return await http.post<ProviderResponseDTO>
    ('provider/update/' + provider.id, JSON.stringify(provider));

}

export const ProviderDeleteService
 = async (id:string) => 
{

    return await http.delete<ProviderResponseDTO>
    ('provider/delete/' + id);

}

export const ProviderSearchByIdService = async (id:string) => {

    const Provider = await http.get<ProviderResponseDTO>('provider/byId/' + id);

    return Provider.payload.data;

}

