import { http } from "../../../../Shared/Http/http"
import { Enlistment, EnlistmentFile, EnlistmentFileMultipleResponseDTO, EnlistmentMultipleResponseDTO, Sync, SyncMultipleResponseDTO, Term, TermMultipleResponseDTO } from '../Interfaces';

export const createTemplateService = async (file: FormData) => {

    return await http.post('sync/template/create', file, true);

}

export const asocciationGroupService = async (file: FormData) => {

    return await http.post('sync/association', file, true);

}

export const getAllTermsService = async () => {
    const response = await http.get<TermMultipleResponseDTO>('sync/terms/all');

    return response.payload.data.map((term): Term => ({
        id: term.id,
        name: term.name,
        start_at: term.start_at,
        end_at: term.end_at,
        created_at: term.created_at,
        grading_period_group_id: term.grading_period_group_id,
        sis_term_id: term.sis_term_id,
        sis_import_id: term.sis_import_id
    }));
}

export const SyncListService = async () => {
    const response = await http.get<SyncMultipleResponseDTO>('sync/list');

    return response.payload.data.map((sync): Sync => ({
        id: sync.id,
    }));
}

export const SyncClearCacheService = (): Promise<void> => {
    return http.get('sync/borrar');
}

export const EnlistmentService = async () => {
    const response = await http.get<EnlistmentMultipleResponseDTO>('enlistments/list');
    
    return response.payload.data.map((enlistment): Enlistment => ({
        id: enlistment.id,
        term_id: enlistment.term_id,
        status: enlistment.status
    }));
}

export const EnlistmentFileService = async (id:string) => {
    const response = await http.get<EnlistmentFileMultipleResponseDTO>('enlistments/list/files/'+id);
    
    return response.payload.data.map((enlistmentfile): EnlistmentFile => ({
        id: enlistmentfile.id,
        enlistment_id: enlistmentfile.enlistment_id,
        file_name: enlistmentfile.file_name,
        file_saved_name: enlistmentfile.file_saved_name,
        created_at: enlistmentfile.created_at
    }));
}

