import { Form } from "formik";
import { InputField } from "../../../../Shared/React/components/field";
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

export const UserPassFields = () => {


    return (
            <Form>
                <InputField
                label='Contraseña'
                name='new_password'
                type='password'
                />

                <InputField
                label='Confirmar Contraseña'
                name='confirm_new_password'
                type='password'/>


                <div className='text-right mt-2'>
                    <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                </div>
            </Form>
    )
}
