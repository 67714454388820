   
    import { Provider } from '../../Domain/Interfaces';
    import { INITIAL_VALUE_PROVIDER } from "../../Domain/Types";
    import { Formik } from "formik";
    import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
    import { useNavigate, useParams } from 'react-router-dom';
    import { v4 as uuidv4 } from 'uuid'
    import { toastAlert } from '../../../../Shared/Toast/Alert';
    import { ToastContainer } from 'react-toastify';
    import { ProviderCreateService } from '../../Domain/Services/ProviderApplicationServices';
    import { ProviderValidationSchema } from '../Validation/ProviderValidationSchema';
    import { ProviderFieldsForm } from './ProviderFieldsForm';
    
    interface Props {
        service: any,
      }


    export const ProviderCreate = ({...row}:Props) => {
    
        const navigate = useNavigate();

      //  const {id}=useParams();
       
        const onSubmitEventElement = (Provider:Provider) => {
            Provider.id = uuidv4();

          //  Provider.faculty_id = String(id); 
           
           
            ProviderCreateService(Provider).then((resp) =>{
                if (toastAlert(resp)) {
                    row.service() 
                }
            });
        }
        
      
        return (
            <>
            <ModalExtructure className="modal" id="createProvider" aria-labelledby="create-Label-Provider" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content"  >
                    <ModalHeader className="modal-header">
                        <ModalTitle id="create-Label-Provider">Crear Proveedor</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
            <ToastContainer/>
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_PROVIDER}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={ProviderValidationSchema}>
                    {
                        (formmik) => (
                            <ProviderFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>

            </>
        )
    }
