import { http } from '../../../../Shared/Http/http';
import {Program, ProgramsResponseDTO, ProgramResponseDTO} from '../Interfaces';

export const ProgramListService = async (facultyId:string) => {

    const program = await http.get<ProgramsResponseDTO>('program/list/' + facultyId);
    return program.payload.data.map
    ((program):Program=> ({
        id                   : program.id,
        name                 : program.name,
        system_code          : program.system_code,
        faculty_id           : program.faculty_id
    }));
}

export const ProgramCreateService
 = async (program:Program|any) => {

    return await http.post<ProgramResponseDTO>
    ('program/create', JSON.stringify(program));

}

export const ProgramUpdateService
 = async (program:Program|any) => 
{
    return await http.post<ProgramResponseDTO>
    ('program/update/' + program.id, JSON.stringify(program));

}

export const ProgramSearchByIdService = async (id:string) => {

    const Program = await http.get<ProgramResponseDTO>('program/programa/' + id);

    return Program.payload.data;

}

export const ProgramDeleteService
 = async (id:string) => 
{

    return await http.delete<ProgramResponseDTO>
    ('program/delete/' + id);

}