import { Field, Form } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import { InputField, SelectField } from "../../../../Shared/React/components/field";
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";
import { Role } from "../../../Role/Domain/Interfaces";
import { RoleListService } from "../../../Role/Domain/Services/RoleApplicationServices";
import { HalfInputField } from "../../../../Shared/React/components/field/HalfInputField";
import { HalfSelectField } from "../../../../Shared/React/components/field/HalfSelectField";

export const UserFieldsForm = ({isEdit=false}) => {

    const [role, setRole] = useState<Role[]>([]);
    const { authState, isAdmin, isTeacher,isManager, isMonitor, getRoles } = useContext( AuthContext );

    useEffect(() => {
        RoleListService().then((resultado) => setRole(resultado));
    }, [])


    const navigate = useNavigate();
    const handleReturn = () => {
        navigate( -1 );
    }

    return (
            <Form>
                <div className="grid grid-cols-2 gap-2 place-content-stretch">
                <HalfInputField className="flex-col w-fit flex-auto"
                label='Documento'
                name='document'
                type='text'/>

                <HalfInputField  className="flex-col w-fit flex-auto"
                label='E-Mail'
                name='email'
                type='email'/>

                <HalfInputField className="flex-col w-fit flex-auto"
                label='Nombre'
                name='name'
                type='text'/>

                <HalfInputField className="flex-col w-fit flex-auto"
                label='Apellido'
                name='last_name'
                type='text'/>

            </div>

            <HalfSelectField  className="flex-row flex"
                name="role_id"
                label='Rol de Usuario'
                as="select"
                >
                    <option key='' value=''>
                    Seleccione un Rol
                    </option>

                {
                    isMonitor() ? role
                    .filter(rol => rol.id === "de177e6f-023d-4ae8-a7be-903a4e9db92d")
                    .map(rol => {
                        return (<>
                            <option key={rol.id} value={`${rol.id}`}>
                                {rol.label}
                            </option>
                            </>
                        )
                    })
                    :
                    role.map((role) => {
                        return (<>

                            <option key={role.id} value={`${role.id}`}>
                                {role.label}
                            </option>
                            </>
                        )
                    })

                }
                </HalfSelectField>
                <div className="pt-5">
                <ButtonPrimary data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
              <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
              </div>
            </Form>
    )
}
