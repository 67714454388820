import { useContext, useEffect, useState } from "react";

import {
  ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent
} from "../../../../../Shared/React/components/styles/Wrappers";
import { DataTableStyle } from "../../../../../Shared/React/components/styles/DataTableStyle";
import { TableFilter } from "../../../../../Shared/React/components/styles/Tables";
import {
  ButtonPrimary,
} from "../../../../../Shared/React/components/styles/Buttons";
import "flowbite/dist/flowbite.min.css";
import { AuthContext } from "../../../../../Auth/Infrastructure/context/AuthContext";
import { Faculty } from "../../../../Faculty/Domain/Interfaces";
import { FacultyListService } from "../../../../Faculty/Domain/Services/FacultyApplicationServices";
import { Link } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";

export const FacultyModalList = () => {
    const [faculties, setFaculty] = useState<Faculty[]>([]);
    const { authState, getRoles } = useContext(AuthContext);

  
    useEffect(() => {
      //@ts-ignore
      FacultyListService(getRoles(), authState.user?.id).then(setFaculty);
    }, []);
  


  let FacultyItem = [
    {
      name: "Nombre",
      selector: (row: any) => row.name,
      cell: (row: any) => row.name,
      sortable: true,
      marginBottom: "10px",
    },
    {
      name: "Ver",
      width: "15%",
      reorder: true,
      center: true,
      verticalAlign: "middle",
      selector: (row: any) => row.name,
      cell: (row: any) => (

          <div className="flex justify-center w-full h-full">
            <Link title="Ver Programas" to={"../../faculty/"+row.id + "/program/"}>
              <EyeIcon className="text-primary-900" height="28px" />
            </Link></div>
    )
    },
  ];

  return (
    <>
      <ModalExtructure className="modal" id="listFaculty" aria-labelledby="list-Label-Faculty" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="list-Label-Faculty">Facultades</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
      <WrapperContent>
      <div className='p-2 w-full rounded-lg  text-left'>
        <TableFilter
          columns={FacultyItem}
          data={faculties}
          customStyles={DataTableStyle}
          responsive
          striped
        />
        </div>
      </WrapperContent>
    </ModalBody>
    <div className="p-2">
                <ButtonPrimary data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
                </div>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
    </>
  );
};
