import { Routes, Route } from "react-router-dom"
import { RoleCreate } from "./Components/RoleCreate"
import { RoleEdit } from "./Components/RoleEdit"
import { RoleList } from "./Components/RoleList"


export const RoleRouter = () =>
{
    return (
        <Routes>
            <Route path="/" element={<RoleList/>} /> 
        </Routes>
    )
}