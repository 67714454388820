
    
    import { CoreCoursesReview } from '../../Domain/Interfaces'
    import { INITIAL_VALUE_CORECOURSESREVIEW } from "../../Domain/Types";
    import { CoreCoursesReviewstatusValidationSchema, CoreCoursesReviewValidationSchema } from '../Validation/CoreCoursesReviewValidationSchema';
    import { Formik } from "formik";
    import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
    import { useNavigate, useParams } from 'react-router-dom';
    import { v4 as uuidv4 } from 'uuid'
    import { toastAlert } from '../../../../Shared/Toast/Alert';
    import { ToastContainer } from 'react-toastify';
    import { CoreCoursesReviewStatusChange } from './CoreCoursesReviewStatusChange';
    import { useContext, useEffect, useState } from 'react';
    import { CoreCoursesReviewListAllService, CoreCoursesReviewSearchByIdService, CoreCoursesReviewUpdateService } from '../../Domain/Services/CoreCoursesReviewApplicationServices';
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
    
    
    export const CorsCoursesReviewStatusChangeCreate = () => {
    
        const navigate = useNavigate();

        const [corecoursesreview, setCoreCourseReview] = useState<CoreCoursesReview>(INITIAL_VALUE_CORECOURSESREVIEW);
        const {reviewId}=useParams();

        const { authState, isTeacher, getRoles } = useContext( AuthContext );

        useEffect( ()=>{
            CoreCoursesReviewSearchByIdService(String(reviewId)).then(
                (corecoursesreview) => {
                    const coursesreview = {  
                        id                   : corecoursesreview.id,
                        course_id            : corecoursesreview.course_id,
                        user_id              : corecoursesreview.user_id,
                        state_id             : corecoursesreview.state_id,
                        user_name            : corecoursesreview.user_name,
                        state_name           : corecoursesreview.state_name,
                        description          : corecoursesreview.description,
                        created_at           : corecoursesreview.created_at,
                        approving_user_id    : corecoursesreview.approving_user_id  

                    }
                    console.log(coursesreview);
                    setCoreCourseReview(coursesreview)
                }
            )
            
        }, [reviewId])
    

        const onSubmitEventElement = (CoreCoursesReview:any) => {
            //TODO: Cambiar any
            CoreCoursesReview.id = String(reviewId);
            CoreCoursesReview.approving_user_id = authState.user?.id;

            CoreCoursesReviewUpdateService(CoreCoursesReview).then((resp) =>{
                if (toastAlert(resp)) {
                    setTimeout(function(){
                            navigate(-1);
                        },2500);    
                }
            });
        }
        
    
      
        return (
            <>
            <ToastContainer/>
            <WrapperTitle>
                Cambio de estado
            </WrapperTitle>
            <WrapperContent>
                <Formik
                    enableReinitialize={true}
                    initialValues={corecoursesreview}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={CoreCoursesReviewstatusValidationSchema}>
                    {
                        (formmik) => (
                            <CoreCoursesReviewStatusChange />
                        )
                    }
                </Formik>
            </WrapperContent>
            </>
        )
    }


