import {object, string} from 'yup'


export const CoreCoursesReviewValidationSchema = object().shape({
    description             : string ().required('Requerido'),
}
)

export const CoreCoursesReviewCommentValidationSchema = object().shape({
    commentary             : string ().required('Requerido'),
}
)

export const CoreCoursesAnswerValidationSchema = object().shape({
    answer             : string ().required('Requerido'),
}
)

export const CoreCoursesReviewstatusValidationSchema = object().shape({
    state_id            : string ().required('Requerido'),
}
)