
import { DocumentReportIcon, EyeIcon, FolderRemoveIcon, LinkIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import { ValidatorBox } from "../../../../Shared/React/components/field/ValidatorBox";
import { ButtonPrimary, LinkPrimary } from "../../../../Shared/React/components/styles/Buttons"
import { DataTableStyle } from "../../../../Shared/React/components/styles/DataTableStyle";
import { Table, TableFilter } from "../../../../Shared/React/components/styles/Tables"
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers"
import { CorsCourses } from "../../../CorsCourses/Domain/Interfaces";
import { CorsCoursesSearchByIdService } from "../../../CorsCourses/Domain/Services/CorsCoursesApplicationServices";
import { INITIAL_VALUE_CORECOURSES, VALUES_CORECOURSES } from "../../../CorsCourses/Domain/Types";
import { CoreCoursesReviewList } from "../../Domain/Interfaces";
import { CoreCoursesReviewDeleteService, CoreCoursesReviewListService } from "../../Domain/Services/CoreCoursesReviewApplicationServices";
import { CoreCoursesReviewItem } from "./CoreCoursesReviewItem";


export const CorsCoursesReviewList = () => {

const [coursesreview, setCoursesReview] = useState<CoreCoursesReviewList[]>([]);

const {id} = useParams();

const { isAdmin, isManager, isTeacher, isMonitor } = useContext( AuthContext );



const navigate = useNavigate();
    const handleReturn = () => {
        navigate( -1 );
    }


useEffect(() => {
    CoreCoursesReviewListService(String(id)).then(setCoursesReview)
}, [id])


const [coursesextension, setCoursesExtension] = useState<CorsCourses>(VALUES_CORECOURSES);

useEffect(() => {
    CorsCoursesSearchByIdService(String(id)).then((coursesextension) => {
      console.log('CoursesExtension', coursesextension);
      setCoursesExtension(coursesextension);
  }
  )
}, [])

const ReviewDelete = (id: string) => {
    CoreCoursesReviewDeleteService(id).then((resp) => { 
        if (JSON.stringify(resp.success) === 'true') {
        setCoursesReview(prev => prev.filter(coursesreview => coursesreview.id !== id));
          toast.info('Se ha eliminado una revision',
                    {
                    position: 'bottom-center',
                    closeButton: false,
                    theme: 'colored', hideProgressBar: true, autoClose: 2500
                    }
                )}
        else {
                toast.error('No es posible eliminar una revision asignada a un curso',
                    {
                    position: 'bottom-center',
                    closeButton: false,
                    theme: 'colored', hideProgressBar: true, autoClose: 2500
                    })
                }})
  }


const CourseReviewItem =
    [
        {
            name: 'Fecha de solicitud',
            selector: (row:any) => row.created_at,
            cell: (row:any) => row.created_at,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        {
            name: 'Técnico a Cargo',
            selector: (row:any) => row.user_name,
            cell: (row:any) => row.user_name,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        {
            name: 'Estado',
            selector: (row:any) => row.state_name,
            cell: (row:any) => row.state_name,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        {
            name: 'Aprovado por Docente',
            selector: (row:any) => row.approving_user_name,
            cell: (row:any) => row.approving_user_name,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        {
            name: 'Descripción',   
            selector: (row:any) => row.description,
            cell: (row:any) => row.description,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        { 
            name: 'Acciones',
            width:'12%',
            reorder: true,
            center: true,
            verticalAlign: 'middle',
            selector: (row:any) => row.name,
            cell: (row:any) => 
            <div className='flex'>

                    <Link title='Ver Comentarios de Revisiónes' to={row.id+"/comment"}>
                    <DocumentReportIcon className='text-primary-900' height='28px'/>
                    </Link>                   
                     
                      {
                        isTeacher() ? <> 
                      <Link title='Cambiar Estado de Revisión' to={row.id+"/statuschange"}>
                      <PencilIcon className='text-primary-900' height='28px' />
                      </Link> 
                      </>:''
                    }

                {isAdmin() ?
                <button title='Borrar una Revisión'  data-bs-toggle="modal" data-bs-target={"#reviewDelete-"+row.id}>
                    <TrashIcon className='text-primary-900' height='28px' />
                </button>
                :''
                }

                <ValidatorBox
                        id={row.id}
                        name={row.name}
                        actionname="reviewDelete"
                        service={ReviewDelete}
                        action="borrar una revisión"
                        />
                    
            </div>
      },
    ]


    return (
        <>
        <WrapperTitle>
            Ampliación Curso : {`${coursesextension.name}`} {`[${coursesextension.code}]`} 
        </WrapperTitle>      
            <div className="bg-gray-200 rounded-md p-2 flex mb-4">
                <div className="p-2">
                    <ul className="list-none"> 
                    <li><b>Fecha:</b></li>
                    <li><b>Dependencia:</b></li>
                    <li><b>Plataforma:</b></li>
                    </ul>
                </div>
                <div className="bg-white p-2 rounded-md w-full">
                    <ul className="list-none"> 
                    <li>{`${coursesextension.creation_date}`}</li>
                    <li>{`${coursesextension.dependency.name}`}</li>
                    <li>{`${coursesextension.platform.name}`}</li>
                    </ul>
                </div>
                <div className="p-2">
                    <ul>
                    <li><b>Estado:</b></li>
                    <li><b>Docente:</b></li>
                    <li><b>Proveedor:</b></li>
                    </ul>
                </div>
                <div className="bg-white p-2 w-full rounded-md">
                    <ul>
                    <li>{`${coursesextension.status.name}`}</li>
                    <li>{coursesextension.status.name === '' ? '': coursesextension.user_id ==='' ? 'No hay docente asignado' : `${coursesextension.user.name}`}</li>
                    <li>{`${coursesextension.provider.name}`}</li>
                    </ul>
                </div>
            </div>

        <WrapperTitle>
            Revisiones de curso
        </WrapperTitle>
        <ToastContainer/>
        <WrapperContent>
            <div className='text-right mb-8 mt-2'>
                <ButtonPrimary type='button' onClick={handleReturn}>
                    Atrás
                </ButtonPrimary>

                { (coursesextension.has_pending_review === '0') ? 

                (coursesextension.user_id ==='') ? <><span className="rounded-2xl text-white p-3 bg-gray-500 cursor-not-allowed" title="Debe haber un docente asignado para crear una revisión">Agregar una Revisión</span>
                <div className="text-red-500 pt-2">Debe haber un docente asignado para crear una revisión</div></> :
                 ((isAdmin() || isMonitor()) ? (<>  
                    <LinkPrimary to="create" >
                        Agregar una Revisión
                    </LinkPrimary> 
                    </>) : '') 
                : (coursesextension.has_pending_review === '1') ? <><span className="rounded-2xl text-white p-3 bg-gray-500 cursor-not-allowed" title="Ya hay una revisión en estado pendiente">Agregar una Revisión</span>
                <div className="text-red-500 pt-2">Ya hay una revisión en estado pendiente</div></> : '' }

            
            </div>
            <TableFilter 
                  columns={CourseReviewItem}
                  data={coursesreview}
                  customStyles={DataTableStyle}
                  pagination
                  responsive
                  striped
                  highlightOnHover
              />
        </WrapperContent>
        </>
    )
}