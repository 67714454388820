import { INITIAL_VALUE_FACULTY } from "../../Domain/Types";
    import { FacultyFieldsForm } from './FacultyFieldsForm';
    import { FacultyValidationSchema } from '../Validation/FacultyValidationSchema';
    import { FacultyCreateService } from '../../Domain/Services/FacultyApplicationServices';
    import { Faculty } from '../../Domain/Interfaces';
    import { Formik } from "formik";
    import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
    import { Params, useNavigate } from 'react-router-dom';
    import { v4 as uuidv4 } from 'uuid'
    import { toastAlert } from '../../../../Shared/Toast/Alert';
    
    interface Props {
        service: any,
    }

export const FacultyCreate = ({...row}:Props) => {
    
    const navigate = useNavigate();
    const onSubmitEventElement = (Faculty:Faculty) => {
        Faculty.id = uuidv4();
        FacultyCreateService(Faculty).then((resp) =>{
            if (toastAlert(resp)) {
                row.service();
            }
        });
    }

  
    return (
        <>
        <ModalExtructure className="modal" id="createFaculty" aria-labelledby="create-Label-Faculty" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="create-Label-Faculty">Crear Facultad</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
        <WrapperContent>
            <Formik
                initialValues={INITIAL_VALUE_FACULTY}
                onSubmit={ (values) => { onSubmitEventElement(values) }}
                validationSchema={FacultyValidationSchema}>
                {
                    (formmik) => (
                        <FacultyFieldsForm />
                    )
                }
            </Formik>
        </WrapperContent>
        </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
        </>
    )
}
