import { Routes, Route } from "react-router-dom"
import {  FacultyList,  } from "./Components/FacultyList"

export const FacultyRouter = () => 
{
    return (
        <Routes>
            <Route path="/" element={<FacultyList/>}/>                      
        </Routes>
    )
}