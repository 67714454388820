import { ActionLogin } from "./Interfaces";
import { AuthState } from './Interfaces';

export const actions:ActionLogin = {
    login: 'login',
    logout: 'logout'
}

export const INITIAL_STATE:AuthState = {
    logged: false,
}