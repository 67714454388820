import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Layout } from "../Shared/React/components/layout/Layout";
// Auth Routes
import { FormLogin } from "../Auth/Infrastructure/Components/FormLogin";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
// Router List
import { CorsCoursesRouter } from "../CORS/CorsCourses/Infrastructure/CorsCoursesRouter";
import { DependencyRouter } from "../CORS/Dependency/Infrastructure/DependencyRouter";
import { FacultyRouter } from "../CORS/Faculty/Infrastructure/FacultyRouter";
import { PlatformRouter } from "../CORS/Platform/Infrastructure/PlatformRouter";
import { ProgramRouter } from "../CORS/Program/Infrastructure/ProgramRouter";
import { ProviderRouter } from "../CORS/Provider/Infrastructure/ProviderRouter";
import { CoreCoursesReviewRouter } from "../CORS/CoreCoursesReview/Infrastructure/CoreCoursesRouter";
import { UserFacultyRouter } from "../CORS/UserFaculty/Infrastructure/UserFacultyRouter";
//Error Router
import { ErrorRouter } from "../Error/Infrastructure/ErrorRouter";
import { UserRouter } from "../CORS/User/Infrastructure/UserRouter";
import { FormPassRemember } from "../Auth/Infrastructure/Components/FormPassRemember";
import { SyncRouter } from "../CORS/Sync/Infrastructure/SyncRouter";
import { RoleRouter } from "../CORS/Role/Infrastructure/RoleRouter";
import { BulkLoadRouter } from "../CORS/BulkLoad/Infrastructure/BulkLoadRouter";




export const AppRouter = () => {

    return(
        <>
        <BrowserRouter>
            
            <Routes>

                <Route path="/dashboard/*" element={
                    <PrivateRoute>
                        <Routes>
                            <Route path="" element={ <Layout /> }>
                                <Route path="course/*" element={<CorsCoursesRouter/>}/> 
                                <Route path="dependency/*" element={<DependencyRouter/>}/> 
                                <Route path="faculty/*" element={<FacultyRouter/>}/> 
                                <Route path="platform/*" element={<PlatformRouter/>}/> 
                                <Route path="faculty/:id/program/*" element={<ProgramRouter/>}/>
                                <Route path="provider/*" element={<ProviderRouter/>}/>
                                <Route path="course/:id/review/*" element={<CoreCoursesReviewRouter/>}/>
                                <Route path="user/*" element={<UserRouter/>}/>
                                <Route path="user/:uuid/faculty/*" element={<UserFacultyRouter/>}/>
                                <Route path="sync/*" element={<SyncRouter/>} />
                                <Route path="role/*" element={<RoleRouter/>}/>
                                <Route path="bulkload/*" element={<BulkLoadRouter/>}/>
                                {/*404 Error Handler*/}
                                <Route path="/*" element={ < ErrorRouter />} />
                            </Route>
                        </Routes>
                    </PrivateRoute>
                }/>

                <Route path="/*" element={ 
                    <PublicRoute>
                        <Routes>
                            <Route path="" element={ < FormLogin />} />
                            <Route path="/remember" element={ < FormPassRemember />} />
                            {/*404 Error Handler*/}
                            <Route path="/*" element={ < ErrorRouter />} />
                        </Routes>
                    </PublicRoute>               
                } />                
                
            </Routes>

        </BrowserRouter>
        </>
    )


}