import { ErrorMessage, Field, Form } from "formik"
import { useNavigate } from "react-router-dom";
import { ButtonLogin, ButtonPrimary } from '../../../Shared/React/components/styles/Buttons';
import { WrapperLogin } from "../../../Shared/React/components/styles/Wrappers";
import { resetTurnoff, turnoff } from "../../../Shared/Http/http";

export const FormLoginFields = () => {

    const navigate = useNavigate();
    const handleReturn = () => {
        navigate( 'remember' );
    }

    //TODO Voolver component los inputs y eliminar las validaciones html
    return(
        <div className="flex" onLoad={turnoff===true ? navigate(0) : resetTurnoff}>
        <WrapperLogin>
            <Form>
                <img className="w-72" src='images/icon.svg' alt="IU Digital"/>
                <div className="mb-3 mt-6">
                    <Field className="form-control text-xl font-bold border rounded-xl py-4 text-center text-gray-700 leading-tight bg-gray-200"
                    name="username"
                    placeholder="Usuario"
                    type="text"
                    />
                    <ErrorMessage name='username' component='div' className='text-left text-red-600 mb-2'/> 
                </div>
                <div className="mb-3">
                    <Field className="form-control text-xl font-bold border rounded-xl py-4 text-center text-gray-700 leading-tight bg-gray-200"
                    name="password"
                    type="password"
                    placeholder="Contraseña"
                    req                   />
                    <ErrorMessage name='password' component='div' className='text-left text-red-600 mb-2'/> 
                </div>
                <div className="mb-10">
                    <ButtonLogin type='submit'>Ingresar</ButtonLogin>
                </div>
                <ButtonPrimary type='button' onClick={handleReturn}>Restaurar Contraseña</ButtonPrimary>
            </Form>
        </WrapperLogin>
        <div
        style={{backgroundImage: `url(images/login.png)`}}
        className="bg-cover bg-origin-padding h-screen bg-no-repeat w-full">
        </div>
        </div>
    )
}