    import { INITIAL_VALUE_DEPENDENCY } from "../../Domain/Types";
    import { DependencyFieldsForm } from './DependencyFieldsForm';
    import { DependencyValidationSchema } from '../Validation/DependencyValidationSchema';
    import { DependencyCreateService, DependencyListService } from '../../Domain/Services/DependencyApplicationServices';
    import { Dependency } from '../../Domain/Interfaces';
    import { Form, Formik } from "formik";
    import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
    import { useNavigate } from 'react-router-dom';
    import { v4 as uuidv4 } from 'uuid'
    import { toastAlert } from '../../../../Shared/Toast/Alert';
    import { toast, ToastContainer } from 'react-toastify';
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";
import { useEffect, useState } from "react";

interface Props {
    service: any,
}

    

export const DependencyCreate = ({...row}:Props) => {


    const onSubmitEventElement = (Dependency:Dependency) => {
        Dependency.id = uuidv4();
        DependencyCreateService(Dependency).then((resp) =>{
            if (JSON.stringify(resp.success) === 'false')  {
                toast.error('Hay una dependencia que coincide con el acrónimo que se puede crear para esta',
                    {
                    position: 'bottom-center',
                    closeButton: false,
                    theme: 'colored', hideProgressBar: true, autoClose: 2500
                    })
                }
            else if (toastAlert(resp)) { 

                row.service();
                
                }
            }
          
        );
    }

  
    return (
        <>
        <ModalExtructure className="modal" id="createDependency" aria-labelledby="create-Label-Dependency" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="create-Label-Dependency">Crear Dependencia</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
        <WrapperContent>
            <Formik
                initialValues={INITIAL_VALUE_DEPENDENCY}
                onSubmit={ (values) => { onSubmitEventElement(values) }}
                validationSchema={DependencyValidationSchema}
                >
                {
                    (formmik) => (
                            <DependencyFieldsForm />
                                
                    )
                }
            </Formik>
        </WrapperContent>
                    </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
        </>
    )
}
