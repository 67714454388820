import { Form } from "formik"
import { InputField } from "../../../../Shared/React/components/field"
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

export const RoleFieldsForm = () => {

    return (
        <Form>

            <InputField
                label='Nombre del rol'
                name='name'
                type='text'
            />

            <InputField
                label='Etiqueta'
                name='label'
                type='text'
            />

            <InputField
                label='Descripción'
                name='description'
                type='text'
            />
            
                <div className="pt-5">
                <ButtonPrimary data-bs-dismiss="modal" type="reset">Cerrar</ButtonPrimary>
                <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                </div>
               

        </Form>
    )
}