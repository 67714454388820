import { Form } from "formik"
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import { SelectField } from "../../../../Shared/React/components/field"
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons"
import { Status } from "../../../Status/Domain/Interfaces";
import { StatusListService } from "../../../Status/Domain/Services/StatusApplicationServices";



export const CoreCoursesReviewStatusChange = () => {

  const navigate = useNavigate();
  const handleReturn = () => {
      navigate( -1 );
  }

  const [multistatus, setMultipleStatus] = useState<Status[]>([])
  const { authState, isTeacher, isAdmin } = useContext( AuthContext );

  const Teacher = "0";
  const Admin = "1";
  
  useEffect(() => {
    if(isTeacher()){
        StatusListService(Teacher).then((resultado) => setMultipleStatus(resultado));

    }else{
      StatusListService(Admin).then((resultado) => setMultipleStatus(resultado));
    }
}, [])

 
    return(
      <Form>
      <SelectField
                name="state_id"
                label='Estado'
                as="select">
                    <option value="">SELECCIONE UNA OPCIÓN</option>
                    {multistatus.map((status) => {
                        return (
                        <option key={status.id} value={`${status.id}`}>
                            {status.name}
                        </option>
                        )
                    })}
            </SelectField>

                <div className='text-right mt-2'>
                    <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                    <ButtonPrimary type='button' onClick={handleReturn}>Atrás</ButtonPrimary>
                </div>
            </Form>


)}

