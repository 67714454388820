import {v4 as uuidv4} from "uuid";
import {User, UserFirstLog, UserName, UserPass} from "./Interfaces";

export const INITIAL_VALUE_USERS:User = {
    uuid                 : uuidv4(),
    name                 : '',
    last_name            : '',
    document             : '',
    email                : '',
    role_id              : '',
    role_name            : '',
    change_password      : '',
    confirm_new_password : '',

}

export const INITIAL_VALUE_PASS:UserPass = {
    uuid                 : uuidv4(),
    password            : ''
}

export const INITIAL_VALUE_FIRST_LOG:UserFirstLog = {
    uuid                 : uuidv4(),
    change_password      : '',
    confirm_new_password : '',

}

export const INITIAL_VALUE_NAME:UserName = {
    uuid                 : uuidv4(),
    name                 : '',
    last_name            : '',
}