import { useEffect, useState} from 'react'

import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../../Shared/React/components/styles/Wrappers";
import { ButtonPrimary } from '../../../../../Shared/React/components/styles/Buttons';
import { TableFilter } from '../../../../../Shared/React/components/styles/Tables';
import { Platform } from '../../../../Platform/Domain/Interfaces';
import { PlatformListService } from '../../../../Platform/Domain/Services/PlatformApplicationServices';
import { DataTableStyle } from '../../../../../Shared/React/components/styles/DataTableStyle';



export const PlatformModalList = () => {


    const [platforms, setPlatform] = useState<Platform[]>([]);



    useEffect(() => {
        PlatformListService().then(setPlatform)
    }, [])

    const PlatformItem =
  [
    {
      name: 'Nombre',
      selector: (row:any) => row.name,
      cell: (row:any) => row.name,
      sortable: true,
      marginBottom: '10px',
    },
     {
      name: 'Código del Sistema',
      selector: (row:any) => row.system_code,
      cell: (row:any) => row.system_code,
      sortable: true,
      marginBottom: '10px',
    }
  ] 

    
  return (
    <>
    <ModalExtructure className="modal" id="listPlatform" aria-labelledby="list-Label-Platform" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="list-Label-Platform">Plataformas</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
    <WrapperContent>
      <div className='p-2 w-full rounded-lg  text-left'>
        <TableFilter 
              columns={PlatformItem}
              data={platforms}
              customStyles={DataTableStyle}
              responsive
              striped
          />
      </div>
    </WrapperContent>
    </ModalBody>
    <div className="p-2">
                <ButtonPrimary data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
                </div>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
    </>
    )
}