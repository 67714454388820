import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
import { ButtonPrimary, LinkPrimary } from "../../../../Shared/React/components/styles/Buttons"
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers"
import { CoreCoursesReviewComments } from "../../Domain/Interfaces";
import { CoreCoursesReviewCommentsListService } from "../../Domain/Services/CoreCoursesReviewApplicationServices";
import { CorsCoursesReviewCommentsItem } from "./CoreCoursesReviewCommentsItem";


export const CorsCoursesReviewCommentsList = () => {

    const [coursesreviewcomments, setCoursesReviewComments] = useState<CoreCoursesReviewComments[]>([]);

const {reviewId} = useParams();
const { isTeacher, isMonitor } = useContext( AuthContext );

const navigate = useNavigate();
    const handleReturn = () => {
        navigate( -1 );
    }

const fatherEvent = (id:string) => 
{
    setCoursesReviewComments(
        prev => prev.filter(coursesreviewcomments => coursesreviewcomments.id !== id,
        )
    );
   
   try {
        toast.info('Se ha borrado un Comentario',
            {
            position: 'bottom-center',
            closeButton: false,
            theme: 'colored', hideProgressBar: true, autoClose: 2500
            }
        )}
    catch{}
}

useEffect(() => {
    CoreCoursesReviewCommentsListService(String(reviewId)).then(setCoursesReviewComments) 
}, [reviewId])

console.log()

    return (
        <>
        <WrapperTitle>
            Comentarios de revisión de curso
        </WrapperTitle>
        <WrapperContent>
            <div className='text-right mb-8 mt-2'>
                <ButtonPrimary type='button' onClick={handleReturn}>Atrás</ButtonPrimary>
                {(coursesreviewcomments.map (coursesreviewcomments => coursesreviewcomments.review_state)[0]) !== 'fbd9942b-6593-4aa4-a2d2-1b2b11905e57' ?
                    ( isTeacher() || isMonitor() ) ?
                        <LinkPrimary to='create'>Agregar un comentario</LinkPrimary>
                        :''
                    :''
                }
            </div>
            
            <div>
                {
                    coursesreviewcomments.length !== 0 ?
                    coursesreviewcomments.map
                        (coursesreviewcomments => 
                        <CorsCoursesReviewCommentsItem 
                            key =  {coursesreviewcomments.id} 
                            coursereviewcomments = {coursesreviewcomments}
                            fatherEvent =  {fatherEvent}
                        /> 
                        )
                        :
                            <div className="w-full pb-4  ">
                                <div className="rounded-tr-lg rounded-b-lg bg-gray-300 w-full p-2">
                                    <div className="bg-white p-2 rounded-lg border-gray-300 border text-center"> 
                                        No hay comentarios en la revision
                                    </div>
                                </div>
                            </div>
                }
            </div>
        </WrapperContent>
        </>
    )
}