import { Form } from "formik"
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import { SelectField } from "../../../../Shared/React/components/field"
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons"
import { Faculty } from "../../../Faculty/Domain/Interfaces";
import { FacultyListService } from "../../../Faculty/Domain/Services/FacultyApplicationServices";

export const UserFacultyFieldsForm = () => {

    const navigate = useNavigate();
    

    const [faculties, setFaculty] = useState<Faculty[]>([]);
    const { authState, getRoles } = useContext( AuthContext );

    useEffect(() => {
        //@ts-ignore
        FacultyListService( getRoles(), authState.user?.id ).then((resultado) => setFaculty(resultado))
    }, [])

    return(
        <Form>
                <SelectField
                    name="faculty_id"
                    label='Facultades'
                    as="select"
                    > 
                <option> SELECCIONE LA FACULTAD</option>
                    {faculties.map((faculty) => {
                        return (
                        <option key={faculty.id} value={faculty.id}>
                            {faculty.name}
                        </option>
                        )
                    })}
                </SelectField>
                <div className='text-right mt-2'>
                    <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                </div>
            </Form>
    )
}