import { toast, ToastOptions } from "react-toastify";
import { ResponseDTO } from "../Http/ResponseDTO";
import 'react-toastify/dist/ReactToastify.css'
import { CorsCoursesMultipleResponseDTO, CorsCoursesResponseDTO } from "../../CORS/CorsCourses/Domain/Interfaces";
import { CoreCoursesReviewAnswerMultipleResponseDTO, CoreCoursesReviewAnswerResponseDTO, CoreCoursesReviewCommentsMultipleResponseDTO, CoreCoursesReviewCommentsResponseDTO, CoreCoursesReviewMultipleResponseDTO, CoreCoursesReviewResponseDTO } from "../../CORS/CoreCoursesReview/Domain/Interfaces";
import { FacultiesResponseDTO, FacultyResponseDTO } from "../../CORS/Faculty/Domain/Interfaces";
import { UserResponseDTO, UsersResponseDTO } from "../../CORS/User/Domain/Interfaces";
import { UserFacultiesResponseDTO, UserFacultyResponseDTO } from "../../CORS/UserFaculty/Domain/Interfaces";
import { AuthResponseDTO } from "../../Auth/Domain/Interfaces";
import { ProgramResponseDTO } from "../../CORS/Program/Domain/Interfaces";
import { DependenciesResponseDTO, DependencyResponseDTO } from "../../CORS/Dependency/Domain/Interfaces";
import { RoleResponseDTO } from "../../CORS/Role/Domain/Interfaces";

const toastConfigurations:ToastOptions = {
    position: toast.POSITION.BOTTOM_CENTER,
    closeButton: false,
    theme: 'colored',
    hideProgressBar: true,
    autoClose: 2500
}
const toastSideConfigurations:ToastOptions = {
    position: toast.POSITION.TOP_LEFT,
    closeButton: false,
    theme: 'colored',
    hideProgressBar: true,
    autoClose: 2500
}
export const toastAlert = (response:ResponseDTO | CorsCoursesResponseDTO | CorsCoursesMultipleResponseDTO |CoreCoursesReviewResponseDTO | CoreCoursesReviewMultipleResponseDTO | CoreCoursesReviewCommentsMultipleResponseDTO | CoreCoursesReviewCommentsResponseDTO | CoreCoursesReviewAnswerMultipleResponseDTO |CoreCoursesReviewAnswerResponseDTO |FacultyResponseDTO | FacultiesResponseDTO | UserResponseDTO | UsersResponseDTO | UserFacultyResponseDTO | DependenciesResponseDTO | DependencyResponseDTO | UserFacultiesResponseDTO | ProgramResponseDTO | RoleResponseDTO | AuthResponseDTO )  => {
    console.log(response.message)
    if (! response.success) {
        const resptext = JSON.stringify(response.errors).replace(/[`~!@#$%^&*()_|+\-=?;'".<>\{\}\[\]\\\/]/gi, '\n')
        toast.error('::::: A OCURRIDO UN ERROR :::::\n'+resptext, toastConfigurations)

        return false;
    }
    toast.success('¡Acción completada correctamente!', toastConfigurations)
    return true;
}
export const toastLeftAlert = (response: ResponseDTO | CorsCoursesResponseDTO | CorsCoursesMultipleResponseDTO |CoreCoursesReviewResponseDTO | CoreCoursesReviewMultipleResponseDTO | CoreCoursesReviewCommentsMultipleResponseDTO | CoreCoursesReviewCommentsResponseDTO | CoreCoursesReviewAnswerMultipleResponseDTO |CoreCoursesReviewAnswerResponseDTO |FacultyResponseDTO | FacultiesResponseDTO | UserResponseDTO | UsersResponseDTO | UserFacultyResponseDTO | UserFacultiesResponseDTO | AuthResponseDTO )  => {
    console.log(response.message)
    if (! response.success) {
        const resptext = JSON.stringify(response.errors).replace(/[`~!@#$%^&*()_|+\-=?;'".<>\{\}\[\]\\\/]/gi, '\n')
        toast.error('::::: A OCURRIDO UN ERROR :::::\n'+resptext, toastSideConfigurations)
        return false;
    }
    toast.success('¡Acción completada correctamente!', toastConfigurations)
    return true;
}
