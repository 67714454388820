import { ErrorMessage, Field, Form } from "formik"
import { useNavigate } from "react-router-dom";
import { ButtonLogin, ButtonPrimary } from '../../../Shared/React/components/styles/Buttons';
import { WrapperLogin } from "../../../Shared/React/components/styles/Wrappers";

export const FormPassRememberFields = () => {

    const navigate = useNavigate();
    const handleReturn = () => {
        navigate( '../' );
    }

    return(
        <div className="flex">
        <WrapperLogin>
            <Form>
                <img className="w-72" src='images/icon.svg' alt="IU Digital"/>
                <div className="mb-3 mt-6">
                    <Field className="form-control text-xl font-bold border rounded-xl py-4 text-center text-gray-700 leading-tight bg-gray-200"
                    name="email"
                    placeholder="Correo"
                    type="email"
                    />
                    <ErrorMessage name='email' component='div' className='text-center text-red-600 mb-2'/>
                </div>
                <div className="mb-10">
                    <ButtonLogin type='submit'>Restaurar Contraseña</ButtonLogin>
                </div>
                <ButtonPrimary type='button' onClick={handleReturn}>Volver al Login</ButtonPrimary>
            </Form>
        </WrapperLogin>
        <div
        style={{backgroundImage: `url(images/login.png)`}}
        className="bg-cover bg-origin-padding h-screen bg-no-repeat w-full">
        </div>
        </div>
    )
}