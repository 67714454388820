import { http } from '../../../../Shared/Http/http';
import {Platform, PlatformsResponseDTO, PlatformResponseDTO} from '../Interfaces';

export const PlatformListService = async () => {

    const platform = await http.get<PlatformsResponseDTO>('platform/list');
    return platform.payload.data.map
    ((platform):Platform=> ({
        id                   : platform.id,
        name                 : platform.name,
        system_code          : platform.system_code,
    }));
}

export const PlatformCreateService
 = async (platform:Platform|any) => {

    return await http.post<PlatformResponseDTO>
    ('platform/create', JSON.stringify(platform));

}

export const PlatformUpdateService
 = async (platform:Platform|any) => 
{
    return await http.post<PlatformResponseDTO>
    ('platform/update/' + platform.id, JSON.stringify(platform));

}

export const PlatformSearchByIdService = async (id:string) => {

    const Platform = await http.get<PlatformResponseDTO>('platform/' + id);

    return Platform.payload.data;

}

export const PlatformDeleteService
 = async (id:string) => 
{

    return await http.delete<PlatformResponseDTO>
    ('course/delete/' + id);

}