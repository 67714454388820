import { useEffect, useState } from 'react'
import { TableFilter } from "../../../../../Shared/React/components/styles/Tables";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../../Shared/React/components/styles/Wrappers";
import { ButtonPrimary } from "../../../../../Shared/React/components/styles/Buttons";
import { useParams } from 'react-router-dom';
import { DataTableStyle } from '../../../../../Shared/React/components/styles/DataTableStyle';
import { ProviderListService } from '../../../../Provider/Domain/Services/ProviderApplicationServices';
import { Provider } from '../../../../Provider/Domain/Interfaces';

export const ProviderModalList = () => {

    const { id } = useParams();


    const [provider, setProvider] = useState<Provider[]>([]);

      useEffect(() => {
        ProviderListService().then(setProvider)
    }, [id])

    
    const ProviderItem =
    [
        {
          name: 'Nombre',
          selector: (row:any)  => row.name,
          cell: (row:any) => row.name,
          sortable: true,
          marginBottom: '10px',
        },
        {
            name: 'Código del Sistema',
            selector: (row:any) => row.system_code,
            cell: (row:any) => row.system_code,
            sortable: true,
            marginBottom: '10px',
          },
    ]

    return (
        <>
    <ModalExtructure className="modal" id="listProvider" aria-labelledby="list-Label-Provider" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="list-Label-Provider">Proveedores</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
    <WrapperContent>
      <div className='p-2 w-full rounded-lg text-left'>
        <TableFilter 
              columns={ProviderItem}
              data={provider}
              customStyles={DataTableStyle}
              responsive
              striped
          />
      </div>
    </WrapperContent>
    </ModalBody>
    <div className="p-2">
                <ButtonPrimary data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
                </div>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
    </>
            
        )
}