import { WrapperIcon, WrapperUserName } from "../styles/Wrappers"
import { useContext } from 'react';
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import NavBar from "../menu/NavBar";
import { ToastContainer } from "react-toastify";
import { turnoff } from "../../../Http/http";


export const Header = () => {

    const { authState, Logout } = useContext(AuthContext); 

    return (
        <header className="flex w-full"> 
        <ToastContainer/>
        <WrapperIcon/>
            <WrapperUserName>
                <div className="flex flex-col hover:bg-primary-500 hover:text-secondary-500  rounded-lg text-primary-500 cursor-pointer text-right px-4 py-0.5 font-medium transition ease-in-out duration-300" onClick={Logout} >
                    { authState.logged 
                        ? <>
                        <span>{authState.user?.name}</span>
                        <span className="font-bold">Cerrar sesión</span>
                        </>
                        : ''
                    }
                </div>
            </WrapperUserName>
        </header>
    )    
}