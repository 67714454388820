import { CorsCoursesLoad } from '../../Domain/Interfaces'
    import { LOAD_VALUE_CORECOURSES } from "../../Domain/Types";
    import { CorsCoursesFieldsForm } from './CorsCoursesFieldsForm';
    import { CorsCoursesValidationSchema } from '../Validation/CorsCoursesValidationSchema';
    import { CorsCoursesSearchByIdEditService, CorsCoursesSearchByIdService, CorsCoursesSideUpdateService, CorsCoursesUpdateService } from '../../Domain/Services/CorsCoursesApplicationServices';

    import { Formik } from "formik";
    import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
    import { useNavigate, useParams } from "react-router-dom";
    import { toastAlert } from '../../../../Shared/Toast/Alert';
    import { ToastContainer } from 'react-toastify';
    import { useContext, useEffect, useState } from 'react';
    import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
    
    
    export const CorsCoursesEdit = () => {

        const navigate = useNavigate();

        const {courseId}=useParams();

        const [corsCourse, setCorsCourse] = useState<CorsCoursesLoad>(LOAD_VALUE_CORECOURSES);

       
    

        const onSubmitEventElement = (CorsCourses:any) => {
            //TODO: Cambiar any

            CorsCourses.id = String(courseId);

            CorsCoursesUpdateService(CorsCourses).then((resp) =>{
                if (toastAlert(resp)) {
                    setTimeout(function(){
                            navigate(-1);
                        },2500);    
                }
            });
        }

        useEffect( ()=>{
            CorsCoursesSearchByIdEditService(String(courseId)).then(
                (result) => {
                    console.log('Data inicial',result);
                    const coursecustom = {   
                        id                   : result.id, 
                        name                 : result.name,
                        code                 : result.code,
                        dependency_id        : result.dependency_id,
                        platform_id          : result.platform_id,
                        provider_id          : result.provider_id,
                        status_id            : result.status_id,
                        user_id              : result.user_id,
                        url                  : result.url,
                        creation_date        : result.creation_date,
                        faculty_id           : result.faculty_id,
                        programs             : result.program_id,
                        program_id           : result.programs
                    }
                    setCorsCourse(coursecustom)
                }
            )
            
        }, [courseId])
    
      
        return (
            <>
            <ToastContainer/>
            
            <WrapperTitle>
                Editar un Curso
            </WrapperTitle>
            

            <WrapperContent>
                <Formik
                    enableReinitialize={true}
                    initialValues={corsCourse}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={CorsCoursesValidationSchema}>
                    {
                        (formmik) => (
                            <CorsCoursesFieldsForm/>
                        )
                    }
                </Formik>
            </WrapperContent>
            </>
        )
    }
    


