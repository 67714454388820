import { Formik } from "formik"
import { useNavigate, useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers"
import { UserFacultyFieldsForm } from "./UserFacultyFieldsForm"
import { v4 as uuidv4 } from 'uuid'
import { UserFaculty } from "../../Domain/Interfaces"
import { INITIAL_VALUE_USERFACULTY } from "../../Domain/Types"
import { UserFacultyAssignService, UserFacultyDeleteService, UserFacultyListByUserIdService } from "../../Domain/Services/UserFacultyApplicationServices"
import { UserListByIdService } from "../../../User/Domain/Services/UserApplicationServices"
import { INITIAL_VALUE_NAME } from "../../../User/Domain/Types"
import { UserName } from "../../../User/Domain/Interfaces"
import { useEffect, useState } from "react"
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons"
import { TableFilter } from "../../../../Shared/React/components/styles/Tables"
import { DataTableStyle } from "../../../../Shared/React/components/styles/DataTableStyle"
import { TrashIcon } from "@heroicons/react/outline"
import { toastAlert } from "../../../../Shared/Toast/Alert"
import { ValidatorBox } from "../../../../Shared/React/components/field/ValidatorBox"

interface Props {
    uuid: string,
  }

export const UserFacultyAssign = ({ ...row }:Props) => {

    const uuid= row.uuid;

    const navigate = useNavigate();

    const [userdatalist, setUser] = useState<UserName>(INITIAL_VALUE_NAME);

    const [users, setUserData] = useState<UserFaculty[]>([]);

    const onSubmitEventElement = (UserFaculty:UserFaculty) => {
        UserFaculty.id = uuidv4();
        UserFaculty.user_uuid = String(uuid);
        UserFacultyAssignService(UserFaculty).then((resp) =>{
            if (toastAlert(resp)) {
                UserFacultyListByUserIdService(String(uuid)).then(setUserData); 
            }
        });
    }

    const loadUserFaculty = () =>{
        UserListByIdService(String(uuid)).then(
            (userdatalist) => {
                const userdata = {   
                    uuid                 : userdatalist.uuid,
                    name                 : userdatalist.name,
                    last_name            : userdatalist.last_name,
                }
                setUser(userdata);
            });
        UserFacultyListByUserIdService(String(uuid)).then(setUserData);
    }

    const UserFacultyDelete = (id: string) => {
        UserFacultyDeleteService(id).then((resp) => { 
          if (JSON.stringify(resp.success) === 'true') {
            setUserData(prev => prev.filter(userfaculty => userfaculty.id !== id));
            toast.info('Se ha eliminado una facultad de un usuario',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      }
                  )}
          else {
                  toast.error('No es posible eliminar la asignacion de facultad',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      })
                  }}
            )
      }

    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);

    const UserFacultyItem =
    [
        {
          name: 'Facultad',
          selector: (row:any) => row.faculty_name,
          cell: (row:any) => row.faculty_name,
          sortable: true,
          reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        { 
          name: 'Acciones',
          width:'20%',
          reorder: true,
          center: true,
          verticalAlign: 'middle',
          selector: (row:any) => row.faculty_name,
          cell: (row:any) => 
          <div className='flex justify-center w-full h-full'>
                    <button title='Borrar una Facultad de un Usuario' onClick={ () => {UserFacultyDeleteService(row.id).then((resp) => { 
          if (JSON.stringify(resp.success) === 'true') {
            setUserData(prev => prev.filter(userfaculty => userfaculty.id !== row.id));
            toast.info('Se ha eliminado una facultad de un usuario',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      }
                  )}
          else {
                  toast.error('No es posible eliminar la asignacion de facultad',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      })
                  }}
            )} }>
                    <TrashIcon className='text-primary-900' height='28px'/>
                    </button>
          </div>
      },
    ]

    return(<>
    <ModalExtructure className="modal"  onMouseEnter={loadUserFaculty} id={"facultyUser-"+row.uuid} aria-labelledby={"faculty-Label-User-"+row.uuid} aria-hidden="true">
    <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id={"faculty-Label-User"+row.uuid}>Asignar Facultad al Usuario:<br/>{userdatalist.name} {userdatalist.last_name}</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
        <WrapperContent>
            <Formik
                initialValues={INITIAL_VALUE_USERFACULTY}
                onSubmit={ (values) => { onSubmitEventElement(values) }}
                >
                {
                    (formmik) => (
                        <UserFacultyFieldsForm />
                    )
                }
            </Formik>
            <div className='mt-2 p-2 w-full rounded-lg text-left'>
            {users[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay usuarios creados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
            
            <TableFilter 
                  columns={UserFacultyItem}
                  data={users}
                  customStyles={DataTableStyle}
                  pagination
                  responsive
                  striped
                  highlightOnHover
              />
        }
        </div>
          <div className='text-right m-2'>
          <ButtonPrimary className="pt-5" data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
          </div>
        </WrapperContent>
        </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
        </>
        )}