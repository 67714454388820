import { useEffect, useState } from 'react'
import { Program } from '../../Domain/Interfaces';
import { Table, TableFilter } from "../../../../Shared/React/components/styles/Tables";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { ButtonPrimary, LinkPrimary } from "../../../../Shared/React/components/styles/Buttons";

import { ToastContainer, toast } from 'react-toastify';
import { ProgramDeleteService, ProgramListService } from '../../Domain/Services/ProgramApplicationServices';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DataTableStyle } from '../../../../Shared/React/components/styles/DataTableStyle';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { ValidatorBox } from '../../../../Shared/React/components/field/ValidatorBox';
import { ProgramCreate } from './ProgramCreate';
import { ProgramEdit } from './ProgramEdit';

export const ProgramList = () => {

    const { id } = useParams();


    const [programs, setProgram] = useState<Program[]>([]);
    
    const ProgramDelete = (id: string) => {
        ProgramDeleteService(id).then((result) => { 

            setProgram(prev => prev.filter(program => program.id !== id));
             try {
                  toast.info('Se ha deshabilitado un Programa',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      }
                  )}
              catch{}
        })
      }
 
      const navigate = useNavigate();
      const handleReturn = () => {
          navigate( "./../.." );
      }

    useEffect(() => {
        ProgramListService(String(id)).then(setProgram)
    }, [id])


    const LoadProgramList =() => {
      ProgramListService(String(id)).then(setProgram)
  }


    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);
  

    const ProgramItem =
    [
        {
          name: 'Nombre',
          selector: (row:any) => row.name,
          cell: (row:any) => row.name,
          sortable: true,
		      reorder: true,
          filterable: true,
          marginBottom: '10px',
        },
        {
            name: 'Código del Sistema',
            selector: (row:any) => row.system_code,
            cell: (row:any) => row.system_code,
            sortable: true,
            reorder: true,
            filterable: true,
            marginBottom: '10px',
          },
        { 
          name: 'Acciones',
          width:'12%',
          reorder: true,
          center: true,
          verticalAlign: 'middle',
          selector: (row:any) => row.name,
          cell: (row:any) => 
          <div className='flex justify-center w-full h-full'>
            <button title='Editar un Programa' data-bs-toggle="modal" data-bs-target={"#editProgram-"+row.id}>
                    <PencilIcon className='text-primary-900' height='28px'/>
          </button>
            <button title='Borrar un programa' data-bs-toggle="modal" data-bs-target={"#deleteProgram-"+row.id} >
                <TrashIcon className='text-primary-900' height='28px'/>
            </button>

            <ValidatorBox
                        id={row.id}
                        name={row.name}
                        actionname="deleteProgram"
                        service={ProgramDelete}
                        action="deshabilitar el programa"
                        />
            <ProgramEdit service={LoadProgramList} id={row.id}/>
         </div>
      },
    ]

    return (
        <>
        <WrapperTitle>
            Programas
        </WrapperTitle>
        <WrapperContent>
          <ToastContainer/>
        
            <div className='text-right mb-8 mt-2'>
            <ButtonPrimary title='Crear Programa' data-bs-toggle="modal" data-bs-target="#createProgram">
                    Crear Programa
                </ButtonPrimary>
                <ProgramCreate service={LoadProgramList}/>
            </div>
            
            {programs[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay programas creados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
              <TableFilter 
              columns={ProgramItem}
              data={programs}
              customStyles={DataTableStyle}
              pagination
              responsive
              striped
              highlightOnHover
          />

    }
        <div className='text-right pt-5'>
        <ButtonPrimary type='button' onClick={handleReturn}>Atrás</ButtonPrimary>
        </div>
        </WrapperContent>
        </>
            
        )
}