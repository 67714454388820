import {v4 as uuidv4} from "uuid";
import {CoreCoursesReview, CoreCoursesReviewAnswer, CoreCoursesReviewComments, CoreCoursesReviewList} from "./Interfaces";

export const INITIAL_VALUE_CORECOURSESREVIEW:CoreCoursesReview = {
        id                   : uuidv4(),
        course_id            : "",
        user_id              : "",
        description          : "",
            
}

export const INITIAL_VALUE_CORECOURSESREVIEWLIST:CoreCoursesReviewList = {
        id                   : uuidv4(),
        course_id            : "",
        user_id              : "",
        state_id             : "",
        user_name            : "",
        state_name           : "",
        description          : "",
        created_at           : "", 
        approving_user_id    : "", 
        approving_user_name  : ""

}

export const INITIAL_VALUE_CORECOURSESREVIEWCOMMENTS:CoreCoursesReviewComments = {
        id                   : uuidv4(),
        review_id            : "",
        commentary           : "",
        create_at            : "",
        comment_id           : "",
        answers              : {id: "", comment_id: "", answer: "",  user_name: "", created_at: "", user_id: ""},
        answer               : "",
        user_id              : "",
        created_at           : "",
        user_name            : "",  
        review_state         : "", 
}

export const INITIAL_VALUE_CORECOURSESREVIEWANSWER:CoreCoursesReviewAnswer = {
        id                   : uuidv4(),
        comment_id           : "",
        answer               : "",
        user_name            : "",
        created_at           : "",
        user_id              : ""      
}