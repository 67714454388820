import { useEffect, useState, useContext } from "react";

import {
  WrapperContent,
  WrapperTitle,
} from "../../../../Shared/React/components/styles/Wrappers";
import { DataTableStyle } from "../../../../Shared/React/components/styles/DataTableStyle";
import { TableFilter } from "../../../../Shared/React/components/styles/Tables";
import { Faculty } from "../../Domain/Interfaces";
import {
  FacultyDeleteService,
  FacultyListService,
} from "../../Domain/Services/FacultyApplicationServices";
import {
  ButtonPrimary,
  LinkPrimary,
} from "../../../../Shared/React/components/styles/Buttons";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import { Link } from "react-router-dom";
import {
  ExclamationIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import React from "react";
import "flowbite/dist/flowbite.min.css";
import { toast, ToastContainer } from "react-toastify";
import { ValidatorBox } from "../../../../Shared/React/components/field/ValidatorBox";
import { FacultyEdit } from "./FacultyEdit";
import { FacultyCreate } from "./FacultyCreate";

export const FacultyList = () => {
  const [faculties, setFaculty] = useState<Faculty[]>([]);
  const { authState, isAdmin, isMonitor, getRoles } = useContext(AuthContext);

  useEffect(() => {
    //@ts-ignore
    FacultyListService(getRoles(), authState.user?.id).then(setFaculty);
  }, []);

  const LoadFacultyList = () => {
    //@ts-ignore
    FacultyListService(getRoles(), authState.user?.id).then(setFaculty);
  };

  const FacultyDelete = (id: string) => {
    FacultyDeleteService(id).then((result) => {
      setFaculty((prev) => prev.filter((faculty) => faculty.id !== id));
      try {
        toast.info("Se ha deshabilitado una Facultad", {
          position: "bottom-center",
          closeButton: false,
          theme: "colored",
          hideProgressBar: true,
          autoClose: 2500,
        });
      } catch {}
    });
  };

  const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);

  let FacultyItem = [
    {
      name: "Nombre",
      selector: (row: any) => row.name,
      cell: (row: any) => row.name,
      sortable: true,
      reorder: true,
      filterable: true,
      marginBottom: "10px",
    },
    {
      name: "Acciones",
      width: "12%",
      reorder: true,
      center: true,
      verticalAlign: "middle",
      selector: (row: any) => row.name,
      cell: (row: any) => (
        <>
          <div className="flex justify-center w-full h-full">
          <button title='Editar una Dependencia' data-bs-toggle="modal" data-bs-target={"#editFaculty-"+row.id}>
                    <PencilIcon className='text-primary-900' height='28px'/>
          </button>
            <Link title="Ver Programas" to={row.id + "/program/"}>
              <EyeIcon className="text-primary-900" height="28px" />
            </Link>
            {isAdmin() ? (
              <button
                title="Borrar una Facultad"
                data-bs-toggle="modal"
                data-bs-target={"#deleteFaculty-" + row.id}
              >
                <TrashIcon className="text-primary-900" height="28px" />
              </button>
            ) : (
              ""
            )}
            <ValidatorBox
              id={row.id}
              name={row.name}
              actionname="deleteFaculty"
              service={FacultyDelete}
              action="deshabilitar la facultad"
            />
            <FacultyEdit id={row.id} service={LoadFacultyList}/>
          </div>
        </>
      ),
    },
  ];

  if (isMonitor()) {
    FacultyItem = FacultyItem.filter((item) => item.name !== "Acciones");
  }

  return (
    <>
      <ToastContainer />
      <WrapperTitle>Facultades</WrapperTitle>
      <WrapperContent>
        {isAdmin() ? (
          <div className="text-right mb-8 mt-2">
            <ButtonPrimary title='Crear Facultad' data-bs-toggle="modal" data-bs-target="#createFaculty">
                    Crear Facultad
                </ButtonPrimary>
            <FacultyCreate service={LoadFacultyList}/>
          </div>
        ) : (
          ""
        )}
        {faculties[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay facultades creadas para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
        <TableFilter
          columns={FacultyItem}
          data={faculties}
          customStyles={DataTableStyle}
          pagination
          responsive
          striped
          highlightOnHover
        />}
      </WrapperContent>
    </>
  );
};
