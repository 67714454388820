import { Formik } from "formik";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { useContext } from 'react';
import { UserPasswordUpdateService  } from '../../Domain/Services/UserApplicationServices';
import { useNavigate } from "react-router-dom"

import { UserPassValidationSchema } from '../Validation/UserValidationSchema';
import { ToastContainer } from 'react-toastify';
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { UserPassFields } from "./UserPassFields";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";

export const UserPassword = () => {

  const navigate = useNavigate();

  const { authState, Logout } = useContext(AuthContext);

  const userdata = {   
    uuid                 : authState.user?.id,
    new_password         : ''
  }

  const onSubmitEventElement = (User:any) => {
    UserPasswordUpdateService (User).then((resp) =>{
      if (toastAlert(resp)) {
          setTimeout(Logout,2500);    
      }
  });
  }
  
    return (
      <>
      <div>
          <ToastContainer/>
          </div>
      <WrapperTitle className="text-center">
          Cambiar Contraseña
      </WrapperTitle>
      <WrapperContent>
        <Formik
          enableReinitialize={true}
          initialValues={userdata}
          onSubmit={ values => { onSubmitEventElement(values)}}
          validationSchema={UserPassValidationSchema}>
            {
              (formmik) => (
                <UserPassFields/>
              )
            }
        </Formik>

      </WrapperContent>
      </>

    )
}