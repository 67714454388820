import { Form, Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useEffect, useState } from 'react';
import { User } from '../../Domain/Interfaces';
import { UserListByIdService, UserUpdateService  } from '../../Domain/Services/UserApplicationServices';
import { useNavigate, useParams } from "react-router-dom"
import { INITIAL_VALUE_USERS } from "../../Domain/Types";
import { UserFieldsForm } from './UserFieldsForm';
import { UserEditValidationSchema } from '../Validation/UserValidationSchema';
import { ToastContainer } from 'react-toastify';
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

interface Props {
  uuid: string,
  service: any
}

export const UserEdit = ({ ...row }:Props) => {

  const navigate = useNavigate();

  const uuid = row.uuid;

  const [userdatalist, setUser] = useState<User>(INITIAL_VALUE_USERS);

  const onSubmitEventElement = (User:any) => {
    //TODO: Cambiar any
    UserUpdateService (User).then((resp) =>{
      if (toastAlert(resp)) {
          row.service()  
      }
  });
  }
  
   const loadUser = () =>{
      UserListByIdService(String(uuid)).then(
        (userdatalist) => {
            const userdata = {   
                uuid                 : userdatalist.uuid,
                name                 : userdatalist.name,
                last_name            : userdatalist.last_name,
                document             : userdatalist.document,
                email                : userdatalist.email,
                role_id              : userdatalist.role_id,
                role_name            : '',
                change_password      : userdatalist.change_password,
                confirm_new_password : '',
            }
            setUser(userdata)
        }
    )
    }
  
    
  
    return (
    <>
    <ModalExtructure className="modal" onMouseEnter={loadUser}  id={"editUser-"+row.uuid} aria-labelledby={"edit-Label-User-"+row.uuid} aria-hidden="true" >
    <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id={"edit-Label-User"+row.uuid}>Editar Usuario</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
          <WrapperContent>

      {userdatalist.name !== '' ? 
      <Formik
          enableReinitialize={true}
          initialValues={userdatalist}
          onSubmit={ values => { onSubmitEventElement(values) }}
          validationSchema={UserEditValidationSchema}>
            {
              (formmik) => (
                <UserFieldsForm isEdit={true} />
              )
            }
        </Formik>
        :

        <div>
          <img className="self-center w-full" src="/images/loading.svg" alt="" />
          <ButtonPrimary className="pt-5" data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
        </div>
        }


      </WrapperContent>
      </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
      </>

    )

}