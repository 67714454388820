import React from 'react';
import { AppRouter } from './router/AppRouter'
import 'tw-elements/dist/css/index.min.css';
import 'flowbite/dist/flowbite.min.css'
import { AuthProvider } from './Auth/Infrastructure/context/AuthProvider';


function App() {
  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
}

export default App;
