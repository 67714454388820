import { Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useEffect, useState } from 'react';
import { Faculty } from '../../Domain/Interfaces';
import { FacultySearchByIdService, FacultyUpdateService  } from '../../Domain/Services/FacultyApplicationServices';
import { useNavigate, useParams } from "react-router-dom"
import { INITIAL_VALUE_FACULTY } from "../../Domain/Types";
import { FacultyFieldsForm } from './FacultyFieldsForm';
import { FacultyValidationSchema } from '../Validation/FacultyValidationSchema';
import { ToastContainer } from 'react-toastify';
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

interface Props {
    id: string,
    service: any,
}

export const FacultyEdit = ({ ...row }:Props) => {

  const facultyId = row.id;

  const [faculty, setFaculty] = useState<Faculty>(INITIAL_VALUE_FACULTY);

  const navigate = useNavigate();

  const onSubmitEventElement = (values:Faculty) => {
    FacultyUpdateService (values).then((resp) =>{
      if (toastAlert(resp)) {
          row.service();   
      }
  });
  }

  const loadFaculty = () => {FacultySearchByIdService(String(facultyId)).then((faculty) => setFaculty(faculty))};


  return (
    <>
    <ModalExtructure className="modal" onMouseEnter={loadFaculty}  id={"editFaculty-"+row.id} aria-labelledby={"edit-Label-Faculty-"+row.id} aria-hidden="true">
    <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content" >
                    <ModalHeader className="modal-header">
                        <ModalTitle id={"edit-Label-Faculty"+row.id}>Editar Facultad</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">

        <ToastContainer/>
        
    <WrapperContent>
    {faculty.name !== '' ? 
      <Formik
        enableReinitialize={true}
        initialValues={faculty}
        onSubmit={ values => { onSubmitEventElement(values) }}
        validationSchema={FacultyValidationSchema}>
          {
            (formmik) => (
              <FacultyFieldsForm/>
            )
          }
      </Formik>
      :

        <div>
          <img className="self-center w-full" src="/images/loading.svg" alt="" />
          <ButtonPrimary className="pt-5" data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
        </div>
        }
    </WrapperContent>
    </ModalBody>
    </ModalContent>
    </ModalDialog>
    </ModalExtructure>
    </>
  )
}