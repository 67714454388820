import { http } from '../../../../Shared/Http/http';
import {User, UsersResponseDTO, UserResponseDTO, KeyValue} from '../Interfaces';
import { manager, admin, teacher, monitor } from '../../../../Auth/Infrastructure/context/AuthProvider'; 

export const UserListService = async (roles:KeyValue[], id:string ) => {

    
    let route = '';
    
    switch (roles[0].name) {
        case admin:
            route = 'user/list';
        break;

        case monitor:
            route = 'user/list-all-teachers';

        break;

        case manager:
            break;

        case teacher:
            break;
    
        default:
            break;
    }

    const user = await http.get<UsersResponseDTO>(route);
    return user.payload.data.map
    ((user):User=> ({
        uuid                 : user.uuid,
        name                 : user.name,
        last_name            : user.last_name,
        document             : user.document,
        email                : user.email,
        role_id              : user.role_id,
        role_name            : user.role_name,
        change_password      : user.change_password,
        confirm_new_password : user.confirm_new_password,

    }));
}


export const UserTeacherListService = async () => {

    const user = await http.get<UsersResponseDTO>('user/list-all-teachers');
    return user.payload.data.map
    ((user):User=> ({
        uuid                 : user.uuid,
        name                 : user.name,
        last_name            : user.last_name,
        document             : user.document,
        email                : user.email,
        role_id              : user.role_id,
        role_name            : user.role_name,
        change_password      : user.change_password,
        confirm_new_password : user.confirm_new_password,
    }));
}

export const UserListByIdService = async (uuid:string) => {

    const user = await http.get<UserResponseDTO>('user/list-user/' + uuid);

    return user.payload.data;

}

export const UserCreateService
 = async (user:User|any) => {

    return await http.post<UserResponseDTO>
    ('user/create', JSON.stringify(user));

}

export const UserUpdateService
 = async (user:User|any) => 
{
    return await http.post<UserResponseDTO>
    ('user/update/' + user.uuid, JSON.stringify(user));

}

export const UserPasswordUpdateService
 = async (user:User|any) => 
{
    return await http.post<UserResponseDTO>
    ('user/update-password-required/' + user.uuid, JSON.stringify(user));

}

export const UserDeleteService
 = async (uuid:string) => 
{

    return await http.delete<UserResponseDTO>
    ('user/delete/' + uuid);

}


export const UserResetPasswordService
 = async (uuid:string) => 
{

    return await http.get<UserResponseDTO>
    ('user/reset-password/' + uuid);

}

export const UserRememberPasswordService
 = async (user:User|any) => 
{

    return await http.post<UserResponseDTO>
    ('user/remember-password/', JSON.stringify(user));

}