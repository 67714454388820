import { Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useEffect, useState } from 'react';
import { Role } from '../../Domain/Interfaces';
import { RoleSearchByIdService, RoleUpdateService  } from '../../Domain/Services/RoleApplicationServices';
import { useNavigate } from "react-router-dom"
import { INITIAL_VALUE_Role } from "../../Domain/Types";
import { RoleFieldsForm } from './RoleFieldsForm';
import { RoleValidationSchema } from '../Validation/RoleValidationSchema';
import { ToastContainer } from 'react-toastify';
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

interface Props {
  id: string,
  service: any,
}

export const RoleEdit = ({ ...row }:Props) => {

  const roleId = row.id;

  const [role, setRole] = useState<Role>(INITIAL_VALUE_Role);

  const loadRole = () => {
    RoleSearchByIdService(String(roleId)).then((role) => setRole(role))
  }

  const navigate = useNavigate();

  const onSubmitEventElement = (values:Role) => {
    RoleUpdateService (values).then((resp) =>{
      if (toastAlert(resp)) {
          row.service()  
      }
  });
  }

  return (
    <>
    <ModalExtructure className="modal"  onMouseEnter={loadRole} id={"editRole-"+row.id} aria-labelledby={"edit-Label-Role-"+row.id} aria-hidden="true" >
    <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id={"edit-Label-Role"+row.id}>Editar Rol</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
          <ToastContainer/>
          <WrapperContent>
    {role.name !== '' ?
      <Formik
        enableReinitialize={true}
        initialValues={role}
        onSubmit={ values => { onSubmitEventElement(values) }}
        validationSchema={RoleValidationSchema}>
          {
            (formmik) => (
              <RoleFieldsForm/>
            )
          }
      </Formik>
      :

        <div>
          <img className="self-center w-full" src="/images/loading.svg" alt="" />
          <ButtonPrimary className="pt-5" data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
        </div>
        }
    </WrapperContent>
    </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
    </>
  )
}