import { ToastContainer } from "react-toastify";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { DataTableStyle } from "../../../../Shared/React/components/styles/DataTableStyle";
import { useEffect, useState } from "react";
import { Enlistment, Term } from "../../Domain/Interfaces";
import { EnlistmentService, getAllTermsService } from "../../Domain/Services/SyncApplicationServices";
import { TableFilter } from "../../../../Shared/React/components/styles/Tables";
import { Link, useSearchParams } from "react-router-dom";
import { DocumentReportIcon } from "@heroicons/react/outline";

export let term_data_name = '';

export const SyncList = () => {
    const [SyncData, setSync] = useState<Enlistment[]>([]);
    const [terms, setTerms] = useState<Term[]>([]);
  
    useEffect(() => {
      EnlistmentService().then(setSync);
      getAllTermsService()
      .then((terms) => {
        setTerms(terms);
      })}, []);
  
      const getTermNameById = (termId: any) => {
        const trimmedTermId = termId.trim();
        const term = terms.find((term) => term.id === Number(trimmedTermId));
        return term ? term.name : '';
      };

      const [showText, setShowText] = useState(false);

      useEffect(() => {
        const timer = setTimeout(() => {
          setShowText(true);
        }, 4000);
        return () => clearTimeout(timer);
      }, []);
  
    const SyncItem = [
      {
        name: 'Periodo',
        selector: (row: any) => row.term_id,
        sortable: true,
        reorder: true,
        filterable: true,
        marginBottom: '10px',
        cell: (row: any) => {
            const termName = getTermNameById(row.term_id);
            return termName;
          },
      },
      {
        name: 'Estado',
        selector: (row:any) => row.status,
        cell: (row:any) => row.status,
        sortable: true,
        reorder: true,
        filterable: true,
        marginBottom: '10px',
    },
    { 
        name: 'Acciones',
        width:'8%',
        reorder: true,
        center: true,
        verticalAlign: 'middle',
        selector: (row:any) => row.term_id,
        cell: (row:any) => 
        <div className='flex justify-center w-full h-full'>
          <Link title='Ver detalles de un alistamiento' to={row.id+"/detail/"} onPointerEnter={() => (term_data_name = getTermNameById(row.term_id))}>
                <DocumentReportIcon className='text-primary-900' height='28px'/>
            </Link>
       </div>
    },
    ];
  
    return (
      <>
        <ToastContainer />
        <WrapperTitle>Listado de Alistamientos</WrapperTitle>
        <WrapperContent>
        {terms[0] === void[] 
             ? 
              (showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay alistamientos creados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   )
            :
          <TableFilter
            columns={SyncItem}
            data={SyncData}
            customStyles={DataTableStyle}
            pagination
            responsive
            striped
            highlightOnHover
          />
              }
        </WrapperContent>
      </>
    );
  };
  