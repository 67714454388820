import { Field, Form } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../../../Shared/React/components/field";
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";
import { DocumentFieldsProps } from "../../Domain/Interfaces";

export const BulkLoadFieldsForm = ({
  fatherEvent
}: DocumentFieldsProps) => {
  return <Form>
            <InputField label="Archivo" name='document' id='document' type='file' onChange={(e: any) => {
      fatherEvent(e);
    }} />
              
            <div className='text-right mt-2'>
                    <ButtonPrimary type='submit'>Enviar</ButtonPrimary>
            </div>
        </Form>;
};