import { CoreCoursesReviewAnswer, CoreCoursesReviewComments, CoreCoursesReviewCommentsProps } from '../../Domain/Interfaces'
import { INITIAL_VALUE_CORECOURSESREVIEWANSWER, INITIAL_VALUE_CORECOURSESREVIEWCOMMENTS } from "../../Domain/Types";

import { Formik } from "formik";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ToastContainer } from 'react-toastify';

import { CoreCoursesReviewAnswerCreateService } from '../../Domain/Services/CoreCoursesReviewApplicationServices';
import { CoreCoursesReviewAnswerFieldsForm } from './CoreCoursesReviewAnswerFieldsForm';
import { useContext } from 'react';
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
import { CoreCoursesAnswerValidationSchema } from '../Validation/CoreCoursesReviewValidationSchema';
    
    
    export const CoreCoursesReviewAnswerCreate = () => {
    
        const { authState, isTeacher, getRoles } = useContext( AuthContext );

            const navigate = useNavigate();

            const {commentaryId}=useParams();

            const onSubmitEventElement = (coursereviewanswer:CoreCoursesReviewAnswer) => {
                coursereviewanswer.id = uuidv4();
                coursereviewanswer.comment_id = String(commentaryId);
                //@ts-ignore
                coursereviewanswer.user_id = authState.user?.id;                

                CoreCoursesReviewAnswerCreateService(coursereviewanswer).then((resp) =>{
                      if (toastAlert(resp)) {
                        setTimeout(function(){
                                navigate(-1);
                            },2500);    
                        }
                    });
                }
      
        return (
            <>
            <ToastContainer/>
            <WrapperTitle>
                Respuesta
            </WrapperTitle>
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_CORECOURSESREVIEWANSWER}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={CoreCoursesAnswerValidationSchema}
                    >
                    {
                        (formmik) => (
                            <CoreCoursesReviewAnswerFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </>
        )
    }