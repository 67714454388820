import { Routes, Route } from "react-router-dom";
import { SyncUpload } from './Components/SyncUpload';
import { SyncList } from './Components/SyncList';
import { SyncDetails } from "./Components/SyncDetails";
import { SyncReportDetails } from "./Components/SyncReportDetails";

export const SyncRouter = () => {
    return(

        <Routes>
            <Route path="/" element={<SyncList/>} /> 
            <Route path="/:id/detail" element={<SyncDetails/>} />
            <Route path="/upload" element={<SyncUpload/>} />  
            <Route path="/:id/detail/:idDetail" element={<SyncReportDetails/>} />    
        </Routes>
    );
}