import { useReducer } from "react";
import jwt_decode from "jwt-decode";
import { AuthContext } from "./AuthContext"
import { AuthReducer } from "./AuthReducer";
import { actions } from '../../Domain/Types';
import { JWTDescode } from '../../Domain/Interfaces';
import { authService, logoutService } from "../../Domain/Services/AuthApplicationServices";
import { toast } from 'react-toastify';
import { http } from "../../../Shared/Http/http";


const init = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    return {
        logged: Object.keys(user).length === 0 ? false : true,
        user
    }
}

export const admin = 'administrator'
export const teacher = 'teacher'
export const manager = 'manager'
export const monitor = 'monitor'

export const AuthProvider = ({ children }:any) => {

    const [authState, dispatch] = useReducer(AuthReducer, {}, init);

    const Login = ( username:string, password:string ) => {
        authService(username, password).then((result) =>{

                const decodeToken:JWTDescode = jwt_decode(result.access_token);

                const user = {
                    jwt: result.access_token,
                    id: decodeToken.id,
                    name: decodeToken.full_name,
                    change_password: decodeToken.change_password,
                }
                const action = {
                    type: actions.login,
                    payload: user
                }
                localStorage.setItem('user', JSON.stringify(user));
                http.setHeaderAuthorization(user.jwt);
                dispatch(action)
        }).catch(err => {
            toast.error('Usuario y/o contraseña incorrectos, vuelte a intentarlo.', {
                position: 'top-left',
                closeButton: false,
                theme: 'colored', hideProgressBar: true, autoClose: 2500
            })
        });
    }

    const Logout = () => {

            localStorage.removeItem('user');
            
            const action = {
                type: actions.logout
            }
            
            dispatch(action)



    }

    const parseJWT = (jwt:string) => {

        return jwt_decode(jwt);

    }

    const getRoles = () => {
        //@ts-ignore
        return parseJWT(authState.user.jwt).roles;

    }

    const find = (name:string) => {
        //@ts-ignore
        return parseJWT(authState.user.jwt).roles.find((role:Role) => {
            return role.name === name
        })
    }
        
    const isAdmin = () => {
        return find(admin) !== undefined
    }
        
    const isTeacher = () => {
        return find(teacher) !== undefined
    }

    const isManager = () => {
        return find(manager) !== undefined
    }

    const isMonitor = () => {
        return find(monitor) !== undefined
    }
        
    const isMenuAcepted = (roles:string[]) => {
            //@ts-ignore
        const validation = parseJWT(authState.user.jwt).roles.find((role) => {
            return roles.includes(role.name)
        })
        return validation !== undefined
    }

    return (
        <AuthContext.Provider value={{ 
            authState,
            Login,
            Logout,
            isAdmin,
            isTeacher,
            isManager,
            isMonitor,

            
            getRoles,
            isMenuAcepted,
         }}>
            { children }
        </AuthContext.Provider>
    );
}