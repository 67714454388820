import { Header } from "./Header"
import { Outlet } from "react-router-dom"
import { NoBarWrapper, Wrapper, WrapperIcon } from '../styles/Wrappers';
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import { UserFirstLog } from "../../../../CORS/User/Domain/Interfaces";
import { INITIAL_VALUE_FIRST_LOG } from "../../../../CORS/User/Domain/Types";
import { UserListByIdService } from "../../../../CORS/User/Domain/Services/UserApplicationServices";
import { UserPassword } from "../../../../CORS/User/Infrastructure/Components/UserPassword";
import NavBar from "../menu/NavBar";
import { Menu } from "./Menu";
import { turnoff } from "../../../Http/http";
import { ExpiredSessionBox } from "../field/ExpiredSessionBox";
import { ToastContainer } from "react-toastify";


export const Layout = () => {

    const navigate = useNavigate();

    const { Logout, authState } = useContext( AuthContext );

    useEffect(() => {
        const checkTurnoff = () => {
          if (turnoff) {
            navigate('..')
          } else {
            setTimeout(checkTurnoff, 100);
          }
        };
    
        const timer = setTimeout(checkTurnoff, 10);
    
        return () => clearTimeout(timer);
      }, []);



    return (<>
    {authState.user?.change_password === '0' ?
    <>  
    {turnoff ?

        <> 
    <img className="w-64 p-4 fixed" src='/images/logo.svg' alt="IU Digital"/>
    <Header />
    <ToastContainer/>
               <ExpiredSessionBox/>
    </>
    :
    <>  
    <Menu />
           <section className="flex relative overflow-show" id="page-wrap">
               <Wrapper>
                   <Outlet/> 
               </Wrapper>
            </section>
    </>
    }
    </>
    :
    authState.user?.change_password === '1' ?
    <>
    <img className="w-64 p-4 fixed" src='/images/icon.svg' alt="IU Digital"/>
    <Header />
           <section className="flex relative">
               <NoBarWrapper>
                   <UserPassword/> 
               </NoBarWrapper>
           </section>

    </>
    :
    <>
    <Header />
    <img className="w-64 p-4 fixed" src='/images/icon.svg' alt="IU Digital"/>
           <section className="flex relative">
           </section>
    </>
    }
    </>
    )
}