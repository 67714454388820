import { actions, INITIAL_STATE } from '../../Domain/Types';

export const AuthReducer = (state = INITIAL_STATE, action:any) => {
    switch (action.type) {
        case actions.login:
            return {
                ...state,
                logged: true,
                user: action.payload
            };   
           
        case actions.logout:
            return {
                logged: false
            }; 
        default:
            return state
    }
}