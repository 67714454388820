import { Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useState } from 'react';
import { Platform } from '../../Domain/Interfaces';

import { useNavigate } from "react-router-dom"
import { INITIAL_VALUE_PLATFORM } from "../../Domain/Types";

import { ToastContainer } from 'react-toastify';
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { PlatformSearchByIdService, PlatformUpdateService } from "../../Domain/Services/PlatformApplicationServices";
import { PlatformFieldsForm } from "./PlatformFieldsForm";
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

interface Props {
  id: string,
  service: any,
}


export const PlatformEdit = ({ ...row }:Props) => {

  const PlatformId = row.id;

  const [platform, setPlatform] = useState<Platform>(INITIAL_VALUE_PLATFORM);

  const navigate = useNavigate();

  const onSubmitEventElement = (platform:any) => {

    platform.id = String(PlatformId);
    
    PlatformUpdateService (platform).then((resp) =>{
      if (toastAlert(resp)) {
          row.service();   
      }
  });
  }

  const loadPlatform = () => {PlatformSearchByIdService(String(PlatformId)).then((platform) => setPlatform(platform))};
  

  return (
    <>
    <ModalExtructure className="modal"  onMouseEnter={loadPlatform}  id={"editPlatform-"+row.id} aria-labelledby={"edit-Label-Platform-"+row.id} aria-hidden="true" >
    <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id={"edit-Label-Platform"+row.id}>Editar Plataforma</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
        <ToastContainer/>
    <WrapperContent>
    {platform.name !== '' ?
      <Formik
        enableReinitialize={true}
        initialValues={platform}
        onSubmit={ values => { onSubmitEventElement(values) }}
        //validationSchema={PlatformValidationSchema}
        >
          {
            (formmik) => (
              <PlatformFieldsForm/>
            )
          }
      </Formik>
:

<div>
  <img className="self-center w-full" src="/images/loading.svg" alt="" />
  <ButtonPrimary className="pt-5" data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
</div>
}
    </WrapperContent>
    </ModalBody>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>

    </>
  )
}