import { Link } from "react-router-dom";
import { SecondLeveltemMenu } from "../../../Domain/Menu/Menu";
import { NavBarThirdItem } from "./NavBarThirdItem";
import { v4 as uuidv4 } from "uuid";
import { useContext } from "react";
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";

interface Prop {
    item:SecondLeveltemMenu
}

export const NavBarSecondItem = ({item}:Prop) => {
    const {name, path, roles, children} = item
    const { isMenuAcepted } = useContext( AuthContext );


    return (
        <>
            {
            isMenuAcepted(roles) ? 
                <li className="full relative my-2 mx-1">
                    { 
                        path ? <Link className="mb-2" to={path}>{ name }</Link> : <span className="mb-2">{ name }</span>
                    }
                    {
                        children?.map( item => <NavBarThirdItem item={item} key={uuidv4()} />)
                    }
                </li>
                :''
            }
        </>
    )
}