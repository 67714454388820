import { Field, Form } from "formik"
import { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { InputField, SelectField } from "../../../../Shared/React/components/field"
import { TextareaField } from "../../../../Shared/React/components/field/TextareaField";
import {ButtonPrimary} from "../../../../Shared/React/components/styles/Buttons";

export const CoreCoursesReviewAnswerFieldsForm = () => {
        
        const navigate = useNavigate();
        const handleReturn = () => {
            navigate( -1 );
        }
    
        return (
            <Form>

            <div className="mb-1">
                <TextareaField  name='answer' placeholder='Escribe un texto aqui' label={"Respuesta"} />
            </div>
    
                    <div className='text-right mt-2'>
                        <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                        <ButtonPrimary type='button' onClick={handleReturn}>Atrás</ButtonPrimary>
                    </div>
                </Form>
        )
}