import { http } from '../../../../Shared/Http/http';
import { CorsCourses, CorsCoursesMultipleResponseDTO, CorsCoursesResponseDTO, CorsCoursesResponseLoadDTO, CourseFaculty,uploableFieldResponseDto,uploableField, KeyValue, DisabledCourses,downloadAllCoursesResponseDto } from '../Interfaces';
import { manager, admin, teacher, monitor } from '../../../../Auth/Infrastructure/context/AuthProvider'; 




export const CorsCoursesListService = async ( roles:KeyValue[], id:string ) => {

    let route = '';
    
    switch (roles[0].name) {
        case admin:
            route = 'course/list';
        break;

        case manager:
            route = 'course/faculties/user/'+ id +'/list';
        break;

        case teacher:
            route = 'course/user/'+ id +'/list';
        break;

        case monitor:
            route = 'course/list';
        break;
    
        default:
            break;
    }

    const corscourses = await http.get<CorsCoursesMultipleResponseDTO>(route);
    console.log('Desde el service', corscourses.payload.data);
    return corscourses.payload.data.map
    ((course):CorsCourses=> ({
        id                   : course.id,
        name                 : course.name,
        code                 : course.code,
        dependency_id        : course.dependency_id,
        platform_id          : course.platform_id,
        provider_id          : course.provider_id,
        status_id            : course.status_id,
        user_id              : course.user_id,
        url                  : course.url,
        creation_date        : course.creation_date,
        faculty_id           : course.faculty_id,
        program_id           : course.program_id,
        dependency           : course.dependency,
        platform             : course.platform,
        provider             : course.provider,
        status               : course.status,
        user                 : course.user,
        programs             : course.programs,
        has_pending_review   : course.has_pending_review,
    }));
}

export const CorsCoursesByTeacherListService = async(id:string) =>
{

    const corscourses = await http.get<CorsCoursesMultipleResponseDTO>('course/user/'+ id +'/list');
    return corscourses.payload.data.map
    ((course):CorsCourses=> ({
        id                   : course.id,
        name                 : course.name,
        code                 : course.code,
        dependency_id        : course.dependency_id,
        platform_id          : course.platform_id,
        provider_id          : course.provider_id,
        status_id            : course.status_id,
        user_id              : course.user_id,
        url                  : course.url,
        creation_date        : course.creation_date,
        faculty_id           : course.faculty_id,
        program_id           : course.program_id,
        dependency           : course.dependency,
        platform             : course.platform,
        provider             : course.provider,
        status               : course.status,
        user                 : course.user,
        programs             : course.programs,
        has_pending_review   : course.has_pending_review,
    }));


}

export const CorsCoursesDisabledListService = async() =>
{
    const corscourses = await http.get<CorsCoursesMultipleResponseDTO>('course/listAll_courses_disabled');
    return corscourses.payload.data.map
    ((course):DisabledCourses=> ({
        id                   : course.id,
        name                 : course.name,
        code                 : course.code,
        creation_date        : course.creation_date,
    }));

}



export const CorsCoursesSearchByIdService = async (id:string) => {

    const CorsCourses = await http.get<CorsCoursesResponseDTO>('course/find/' + id);

    return CorsCourses.payload.data;

}

export const CorsCoursesSearchByIdEditService = async (id:string) => {

    const CorsCourses = await http.get<CorsCoursesResponseLoadDTO>('course/curso_edit/' + id);

    return CorsCourses.payload.data;

}

export const CorsCoursesCreateService
 = async (course:CorsCourses|any) => {

    return await http.post<CorsCoursesResponseDTO>
    ('course/create', JSON.stringify(course));

}

export const CorsCoursesUpdateService
 = async (course:CorsCourses|any) => 
{
    return await http.post<CorsCoursesResponseDTO>
    ('course/update/' + course.id, JSON.stringify(course));

}

export const CorsCoursesSideUpdateService
 = async (course:CorsCourses|any) => 
{
    return await http.post<CorsCoursesResponseDTO>
    ('course/curso_edit/' + course.id, JSON.stringify(course));

}

export const CorsCoursesDisableService
 = async (id:string) => 
{

    return await http.delete<CorsCoursesResponseDTO>
    ('course/delete/' + id);

}

export const CorsCoursesRestoreService
 = async (id:string) => 
{

    return await http.get<CorsCoursesResponseDTO>
    ('course/restore/' + id);

}

export const CorsCoursesDeleteService
 = async (id:string) => 
{

    return await http.delete<CorsCoursesResponseDTO>
    ('course/delete-disabled/' + id);

}

export const CourseFacultiesService
 = async () => 
{
    const courseFaculties = await http.get<CorsCoursesMultipleResponseDTO>('faculty/list');
    return courseFaculties.payload.data.map
    ((courseFaculty):CourseFaculty=> ({
        id                   : courseFaculty.id,
        name                 : courseFaculty.name
    }));

}

export const CourseFacultySearchByIdService = async (id:string) => {

    const CourseFaculty = await http.get<CorsCoursesResponseDTO>('faculty/facultad/' + id);

    return CourseFaculty.payload.data;

} 


export const uploableFieldService
 = async (uploable:FormData|any) => 
{
    return await http.post<uploableFieldResponseDto>
    ('course/carga', uploable, true);

}

export const uploableFieldService2
 = async (uploable:FormData|any) => 
{
    return await http.post<uploableFieldResponseDto>
    ('course/carga_programas', uploable, true);

}

export const downloadAllCourses
 = async () => 
{
    return await http.get<downloadAllCoursesResponseDto>
    ('course/export');

}
