
    
    import { CorsCourses, CoursesCreate } from '../../Domain/Interfaces'
    import { INITIAL_VALUE_CORECOURSES } from "../../Domain/Types";
    import { CorsCoursesFieldsForm } from './CorsCoursesFieldsForm';
    import { CorsCoursesValidationSchema } from '../Validation/CorsCoursesValidationSchema';
    import { CorsCoursesCreateService } from '../../Domain/Services/CorsCoursesApplicationServices';

    import { Formik } from "formik";
    import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
    import { useNavigate } from 'react-router-dom';
    import { v4 as uuidv4 } from 'uuid'
    import { toastAlert } from '../../../../Shared/Toast/Alert';
    import { ToastContainer } from 'react-toastify';
    import { useContext } from 'react';
    import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
    
    
    export const CorsCoursesCreate = () => {
    
        const navigate = useNavigate();
        const onSubmitEventElement = (CorsCourses:CoursesCreate) => {
            CorsCourses.id = uuidv4();
            CorsCoursesCreateService(CorsCourses).then((resp) =>{
                if (toastAlert(resp)) {
                    setTimeout(function(){
                            navigate(-1);
                        },2500);    
                }
            });
        }
        
      
        return (
            <>
            <ToastContainer/>
            <WrapperTitle>
                Crear Curso
            </WrapperTitle>
            
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_CORECOURSES}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={CorsCoursesValidationSchema}>
                    {
                        (formmik) => (
                            <CorsCoursesFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </>
        )
    }
    


