import { useEffect, useState, useContext} from 'react'
import { WrapperContent, WrapperTitle } from '../../../../Shared/React/components/styles/Wrappers';

import { DataTableStyle } from '../../../../Shared/React/components/styles/DataTableStyle';
import { TableFilter } from '../../../../Shared/React/components/styles/Tables';

import { DisabledCourses} from '../../Domain/Interfaces';

import { CorsCoursesDeleteService, CorsCoursesDisabledListService, CorsCoursesRestoreService } from '../../Domain/Services/CorsCoursesApplicationServices';
import { FolderAddIcon, TrashIcon } from '@heroicons/react/outline';
import { toast, ToastContainer } from 'react-toastify';
import { ValidatorBox } from '../../../../Shared/React/components/field/ValidatorBox';

export const CorsCoursesDisabledList = () => {
    const [courses, setCourses] = useState<DisabledCourses[]>([]);

    const CourseDelete = (id: string) => {
        CorsCoursesDeleteService(id).then((result) => { 
            setCourses(prev => prev.filter(course => course.id !== id));
             try {
                  toast.info('Se ha eliminado un Curso',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      }
                  )}
              catch{}
        })
      }

      const CourseRestore = (id: string) => {
        CorsCoursesRestoreService(id).then((result) => { 
            setCourses(prev => prev.filter(course => course.id !== id));
             try {
                  toast.info('Se ha restaurado un Curso',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      }
                  )}
              catch{}
        })
      }

      const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        CorsCoursesDisabledListService().then(setCourses)
    }, [])

    const CorsCoursesDisabledItem =
    [
        {
            name: 'Código Institucional',
            selector: (row:any) => row.code,
            cell: (row:any) => row.code,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        {
            name: 'Nombre Curso',
            selector: (row:any) => row.name,
            cell: (row:any) => row.name,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        { 
            name: 'Acciones',
            width:'12%',
            reorder: true,
            center: true,
            verticalAlign: 'middle',
            selector: (row:any) => row.name,
            cell: (row:any) => 
            <div className='flex'>

                <button title='Restaurar un curso' onClick={ () => {CourseRestore(row.id)} }>
                    <FolderAddIcon className='text-primary-900' height='28px' />
                </button>

                <button title='Eliminar un curso' data-bs-toggle="modal" data-bs-target={"#deleteCourse-"+row.id}>
                    <TrashIcon className='text-primary-900' height='28px' />
                </button>

                <ValidatorBox
                        id={row.id}
                        name={row.name}
                        actionname="deleteCourse"
                        service={CourseDelete}
                        action="eliminar permanentemente el curso"
                        />
                    
            </div>
      },
    ]

    
  return (
        <>
        <ToastContainer/>
        <WrapperTitle>
            Cursos Deshabilitados
        </WrapperTitle>
        <WrapperContent>
        {courses[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay cursos deshabilitados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
                <TableFilter 
                  columns={CorsCoursesDisabledItem}
                  data={courses}
                  customStyles={DataTableStyle}
                  pagination
                  responsive
                  striped
                  highlightOnHover
              />}
        </WrapperContent>
        </>
    )
}