import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent, WrapperSubContent } from '../../../../Shared/React/components/styles/Wrappers';
import { useContext, useState, useEffect, ReactNode } from 'react';
import { AuthContext } from "../../../../Auth/Infrastructure/context/AuthContext";
import { Formik } from "formik";
import { SYNC_COURSES } from "../../Domain/Types";
import { SyncClearCacheService, asocciationGroupService, createTemplateService } from "../../Domain/Services/SyncApplicationServices";
import { SyncFields } from './SyncFields';
import { ButtonPrimary } from '../../../../Shared/React/components/styles/Buttons';
import { SyncValidationSchema } from '../Validation/SyncValidationSchema';
import { ToastContainer } from 'react-toastify';


export const SyncUpload = () => {
    const { authState, isAdmin, isManager, isMonitor, getRoles } = useContext(AuthContext);
    const [responseData, setResponseData] = useState<unknown[]>([]);
    const [responseAsociar, setResponseAsociar] = useState<unknown[]>([]);
    const [responseCrear, setResponseCrear] = useState<unknown[]>([]);
    const [responseEstado, setResponseEstado] = useState('');
    const [responseHeader, setresponseHeader] = useState({
        title_file: '',
        total_registros_leer: 0,
        total_grupos_leer: 0,
        total_cursos_leer: 0,
    });

    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);


    const [message, setMessage] = useState('');
    const delay = 5000;
    let file = '';

    function alista() {

        // setResponseCrear([]);
        let stop = false;
        let cont = 1;
        const fetchData = async () => {
            console.log('empecé');
            const xhr = new XMLHttpRequest();
            xhr.open('GET', process.env.REACT_APP_URL_API + 'sync/alistamiento', true);
            xhr.send();
            let resp;

            xhr.onreadystatechange = function () {

                console.log('iteración: ' + cont);
                cont = cont + 1;

                if (xhr.readyState === 4) {
                }
                if (xhr.status === 200) {
                    resp = xhr.response;
                    console.log(resp);

                    try {

                        if (resp) {
                            const lines = resp.split('\n');
                            lines.forEach((line: unknown) => {


                                if (typeof line === 'string' && line.trim() !== '') {
                                    const responseObject = JSON.parse(line as string);

                                    if (responseObject.header) {
                                        const header = responseObject.header;

                                        const newHeader = {
                                            title_file: header.tittle_file,
                                            total_registros_leer: header.total_registros_leer,
                                            total_grupos_leer: header.total_grupos_leer,
                                            total_cursos_leer: header.total_cursos_leer,
                                        };
                                        setresponseHeader(newHeader);
                                    }
                                }
                            });

                        } else {

                        }

                    } catch (error) {

                    }

                }


            };

        };

        fetchData();
    }

    function clearCache() {
        const newHeader = {
            title_file: '',
            total_registros_leer: 0,
            total_grupos_leer: 0,
            total_cursos_leer: 0,
        };
        setresponseHeader(newHeader);
        SyncClearCacheService().then((resp: void) => {
            console.log(1);

            console.log(2);
        });
    }

    const sleep = (ms: number) => {
        return new Promise<void>(resolve => setTimeout(resolve, ms));
    };


    const onSubmitEventElement = (values: any) => {

        let formData = new FormData();
        formData.set('document', file);
        formData.set('term_id', values.term);
        clearCache();
        setResponseEstado('Estamos preparando el sistema');
        setTimeout(function () {
            setResponseEstado('Copiando cursos');
            setTimeout(function () {
                createTemplateService(formData).then((resp) => {

                    let respuesta;
                    console.log('createTemplateService');
                    //console.log(resp);

                    respuesta = JSON.parse(JSON.stringify(resp));
                    console.log(respuesta);
                    if (respuesta.success) {
                        setResponseEstado('Cursos copiados');
                        console.log('CURSOS COPIADOS');
                        setTimeout(function () {
                            asocciationGroupService(formData).then((resp) => {
                                setResponseEstado('Asociando grupos');
                                console.log(resp);
                                respuesta = JSON.parse(JSON.stringify(resp));
                                if (respuesta.success) {
                                    setTimeout(function () {
                                        setResponseEstado('Grupos asociados');
                                    }, delay);
                                    console.log('grupos asociados');
                                    setTimeout(function () {
                                        setResponseEstado('Hemos finalizado');
                                    }, 10000);
                                } else {
                                    setResponseEstado('Error al asociar los grupos');
                                    console.log('tenemos error con la asociacion ');
                                }

                            }).catch((error) => {
                                // Error: capturar y manejar el error
                                setResponseEstado('Error inesperado, al inentar asociar');
                                console.error('Error en la solicitud de asociar:', error);
                                // Realiza acciones adicionales según sea necesario, como mostrar un mensaje de error al usuario.
                            });
                        }, delay);
                    } else {
                        setResponseEstado('Error al copiar los cursos');
                        console.log('tenemos error con la creación');
                    }

                }).catch((error) => {
                    // Error: capturar y manejar el error
                    setResponseEstado('Error inesperado, intenta cargar de nuevo el archivo y enviarlo nuevamente');
                    console.error('Error en la solicitud de crear:', error);
                    // Realiza acciones adicionales según sea necesario, como mostrar un mensaje de error al usuario.
                });


            }, delay);
        }, delay);
        setTimeout(function () {
            alista();
        }, 15000);

    }

    const handlerFile = (e: any) => {
        file = e.target.files[0];
    }

    return (
        <>
            <ToastContainer />
            <WrapperContent>
                <Formik
                    initialValues={SYNC_COURSES}
                    onSubmit={(values) => { onSubmitEventElement(values) }}
                    validationSchema={SyncValidationSchema}
                >
                    {
                        (formmik) => (
                            <SyncFields fatherEvent={handlerFile} showTerm={true} />
                        )
                    }
                </Formik>
                {responseEstado[0] === void[] 
             ? 
              ''
            :
                <WrapperSubContent className='mt-6'>
                    <div className="w-full text-center text-2xl font-bold pb-2">
                        <div className=' rounded-md bg-primary-500 text-white p-2 mb-2'>Estado del sistema</div>{responseEstado}
                    </div>
                </WrapperSubContent>
                }
                {responseHeader.title_file === '' 
             ? 
              ''
            :
                <WrapperSubContent className='mt-6'>
                    <div className="w-full text-center text-2xl font-bold pb-2">
                    <div className=' rounded-md bg-primary-500 text-white p-2 mb-2'>Estás leyendo el archivo</div>{responseHeader.title_file}
                    </div>
                    <hr className="h-2 bg-black border-0 rounded-xl" />
                    <div className="justify-around flex w-full text-center text-md pt-2">
                        <div className="text-center w-1/3">Registros a leer</div>
                        <div className="text-center w-1/3">Numero de Grupos</div>
                        <div className="text-center w-1/3">Numero de Cursos</div>
                    </div>
                    <div className="justify-around flex w-full text-2xl text-center font-bold ">
                        <div className="text-center w-1/3">{responseHeader.total_registros_leer}</div>
                        <div className="text-center w-1/3">{responseHeader.total_grupos_leer}</div>
                        <div className="text-center w-1/3">{responseHeader.total_cursos_leer}</div>
                    </div>
                </WrapperSubContent>
                }
            </WrapperContent >
        </>
    );
}