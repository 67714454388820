import { CoreCoursesReview, CoreCoursesReviewProps } from '../../Domain/Interfaces'
import { INITIAL_VALUE_CORECOURSESREVIEW } from "../../Domain/Types";
import { CoreCoursesReviewFieldsForm } from './CoreCoursesReviewFieldsForm';
import { CoreCoursesReviewValidationSchema } from '../Validation/CoreCoursesReviewValidationSchema';
import { CoreCoursesReviewCreateService } from '../../Domain/Services/CoreCoursesReviewApplicationServices';

import { Formik } from "formik";
import { WrapperContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'
import { toastAlert } from '../../../../Shared/Toast/Alert';
import { ToastContainer } from 'react-toastify';
import { useContext, useEffect, useState } from 'react';
import { string } from 'yup/lib/locale';

import { CorsCoursesSearchByIdService } from '../../../CorsCourses/Domain/Services/CorsCoursesApplicationServices';
import { CorsCourses } from '../../../CorsCourses/Domain/Interfaces';
import { INITIAL_VALUE_CORECOURSES, VALUES_CORECOURSES } from '../../../CorsCourses/Domain/Types';
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
    
    
    export const CorsCoursesReviewCreate = () => {
    
            const navigate = useNavigate();

            const {id}=useParams();

            const { authState, getRoles } = useContext( AuthContext );

            const onSubmitEventElement = (coursereview:CoreCoursesReview) => {
                coursereview.id = uuidv4();
                coursereview.course_id = String(id);
                //@ts-ignore
                coursereview.user_id  = authState.user?.id;               

                CoreCoursesReviewCreateService(coursereview).then((resp) =>{
                      if (toastAlert(resp)) {
                        setTimeout(function(){
                                navigate(-1);
                            },2500);    
                        }
                    });
                }
      
        return (
            <>
            <ToastContainer/>
          
            <WrapperTitle>
                Crear Revisión
            </WrapperTitle>
            
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_CORECOURSESREVIEW}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={CoreCoursesReviewValidationSchema}
                    >
                    {
                        (formmik) => (
                            <CoreCoursesReviewFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </>
        )
    }