import React, { useContext, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Auth/Infrastructure/context/AuthContext';
import { useState } from 'react';

export const PrivateRoute = ({ children }:any) => {

  const { authState } = useContext( AuthContext );
  const { pathname, search } = useLocation();

  
  const lastPath = pathname + search;
  localStorage.setItem('lastPath', lastPath );
  // useEffect(() => {
  
  // }, [pathname])
  
  

  return (authState.logged)
  ? children
  : <Navigate to="/" />
}
