import { Field, Form } from "formik"
import { useNavigate} from "react-router-dom";
import { InputField } from "../../../../Shared/React/components/field"
import { TextareaField } from "../../../../Shared/React/components/field/TextareaField";
import {ButtonPrimary} from "../../../../Shared/React/components/styles/Buttons";


export const CoreCoursesReviewFieldsForm = () => {
    

    const navigate = useNavigate();
    const handleReturn = () => {
        navigate( -1 );
    }

    return (
            
            <Form>

                <div className="mb-1">
                    <TextareaField  name='description' placeholder='Escribe un texto aqui' label={"Revisión"} />
                </div>

                <div className='text-right mt-2'>
                    <ButtonPrimary type='submit'>Guardar</ButtonPrimary>
                    <ButtonPrimary type='button' onClick={handleReturn}>Atrás</ButtonPrimary>
                </div>
               
            </Form>
    )
}