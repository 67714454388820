import { useEffect, useState } from 'react'
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from '../../../../../Shared/React/components/styles/Wrappers';
import { DataTableStyle } from '../../../../../Shared/React/components/styles/DataTableStyle';
import { TableFilter } from '../../../../../Shared/React/components/styles/Tables';
import { DependencyListService } from '../../../../Dependency/Domain/Services/DependencyApplicationServices';
import { Dependency } from '../../../../Dependency/Domain/Interfaces';
import { ButtonPrimary } from '../../../../../Shared/React/components/styles/Buttons';



export const DependencyModalList = () => {
    
    const [dependencies, setDependency] = useState<Dependency[]>([]);
    
    useEffect(() => {
        DependencyListService().then(setDependency)
    }, [])
  

  const DependencyItem =
  [
    {
      name: 'Nombre',
      selector: (row:any) => row.name,
      cell: (row:any) => row.name,
      sortable: true,
      marginBottom: '10px',
    },
     {
      name: 'Código del Sistema',
      selector: (row:any) => row.system_code,
      cell: (row:any) => row.system_code,
      sortable: true,
      marginBottom: '10px',
    }
  ] 
    
  return (
    <>
    <ModalExtructure className="modal" id="listDependency" aria-labelledby="list-Label-Dependency" aria-hidden="true">
            <ModalDialog className="modal-dialog">
                <ModalContent className="modal-content">
                    <ModalHeader className="modal-header">
                        <ModalTitle id="list-Label-Dependency">Dependencias</ModalTitle>
                    </ModalHeader>
                        <ModalBody className="modal-body">
    <WrapperContent>
      <div className='p-2 w-full rounded-lg  text-left'>
        <TableFilter 
              columns={DependencyItem}
              data={dependencies}
              customStyles={DataTableStyle}
              responsive
              striped
          />
      </div>
    </WrapperContent>
    </ModalBody>
    <div className="p-2">
                <ButtonPrimary data-bs-dismiss="modal" type="button">Cerrar</ButtonPrimary>
                </div>
                    </ModalContent>
                    </ModalDialog>
                    </ModalExtructure>
    </>
        
    )
}