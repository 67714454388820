import { Form } from "formik";
import { InputField } from "../../../../Shared/React/components/field";
import {ButtonPrimary, LinkPrimary} from "../../../../Shared/React/components/styles/Buttons";
import { SelectField } from '../../../../Shared/React/components/field/SelectField';
import { useState, useEffect } from 'react';
import { Term } from '../../Domain/Interfaces';
import { getAllTermsService } from "../../Domain/Services/SyncApplicationServices";
import { WrapperContent, WrapperSubContent } from "../../../../Shared/React/components/styles/Wrappers";
import { HalfInputField } from "../../../../Shared/React/components/field/HalfInputField";
import { HalfSelectField } from "../../../../Shared/React/components/field/HalfSelectField";

export const SyncFields = ({fatherEvent, showTerm}: any) => {

    const [terms, setTerms] = useState<Term[]>([]);

    useEffect(() => {
        if(showTerm){
            getAllTermsService().then((terms) => setTerms(terms));
        }
    }, []);

    const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);


    return(
        <>
            <Form>
            <div className="grid grid-cols-1 gap-2 place-content-stretch">
                {terms[0] === void[] 
             ? 
              (showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No es posible conectar con Canvas<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-56" src="/images/loading.svg" alt=""/></div>
              )   )
            :
            <>
                
                    <HalfSelectField className="flex-col w-fit flex-auto"
                    name="term"
                    label='Periodo'
                    as="select"
                    >
                        <option value="" >SELECCIONE UNA OPCIÓN</option>
                        { 
                        
                            terms.map((term) => {
                                return(
                                    <option key={term.id} value={`${term.id}`}>
                                        {term.name}
                                    </option>
                                )
                            })
                            
                        }
                    </HalfSelectField>

                
                <HalfInputField className="flex-col w-fit "
                    label="Archivo de cursos"
                    name='document'
                    id='document'
                    type='file'
                    accept='xls'
                    onChange={(e:any) => {fatherEvent(e)}}
                />
                                    </>
                }
                </div>
                
                <div className='mt-2'>
                        <LinkPrimary to={'../'}>Volver</LinkPrimary>
                        <ButtonPrimary type='submit'>Enviar</ButtonPrimary>
                </div>
              
            </Form>
        </>
    );
}