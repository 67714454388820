import { Form, Formik } from "formik";
import { ModalBody, ModalContent, ModalDialog, ModalExtructure, ModalHeader, ModalTitle, WrapperContent } from "../../../../Shared/React/components/styles/Wrappers";
import { useNavigate } from 'react-router-dom';
import { User } from "../../Domain/Interfaces";
import { v4 as uuidv4 } from 'uuid';
import { UserCreateService } from "../../Domain/Services/UserApplicationServices";
import { toastAlert } from "../../../../Shared/Toast/Alert";
import { ToastContainer } from "react-toastify";
import { UserFieldsForm } from "./UserFieldsForm";
import { INITIAL_VALUE_USERS } from "../../Domain/Types";
import { UserValidationSchema } from "../Validation/UserValidationSchema";
import { ButtonPrimary } from "../../../../Shared/React/components/styles/Buttons";

interface Props{
    service: any
}

export const UserCreate = ({...row}:Props) => {
    
    const navigate = useNavigate();
        const onSubmitEventElement = (user:User) => {
            user.uuid = uuidv4();
            UserCreateService(user).then((resp) =>{
                if (toastAlert(resp)) {
                    row.service()   
                }
            });
        }
    
    return(
        <>
        <ModalExtructure className="modal" id="createUser" aria-labelledby="create-Label-User" aria-hidden="true">
                    <ModalDialog className="modal-dialog">
                        <ModalContent className="modal-content">
                            <ModalHeader className="modal-header">
                                <ModalTitle id="create-Label-User">Crear Usuario</ModalTitle>
                            </ModalHeader>
                            <ModalBody className="modal-body">
            <WrapperContent>
                <Formik
                    initialValues={INITIAL_VALUE_USERS}
                    onSubmit={ (values) => { onSubmitEventElement(values) }}
                    validationSchema={UserValidationSchema}
                    >
                    {
                        (formmik) => (
                            <UserFieldsForm />
                        )
                    }
                </Formik>
            </WrapperContent>
            </ModalBody>
                        </ModalContent>
                    </ModalDialog>
            </ModalExtructure>
            </>
        )
}